import useEvent from 'hooks/useEvent'
import React from 'react'
import Toast from 'react-bootstrap/Toast'
import s from './Toaster.module.sass'

export type ToastInfo = {
	raw: string
	title?: React.ReactNode
	message?: React.ReactNode
	timestamp?: Date
}

function ensuredKey<T>(original: T & { __key?: string }): string {
	if (typeof original === 'object' && typeof original.__key === 'undefined') {
		original.__key = 'ensuredKey__' + Math.random()
	}
	return (original as T & { __key: string }).__key
}

export const SHOW_TOAST_EVENT_NAME = 'showToast'

export function showToast(toast: ToastInfo | string | undefined) {
	if (!toast || typeof toast === 'string' || typeof toast === 'undefined') {
		toast = { message: toast ?? '?', raw: toast ?? '?' }
	}
	console.log('toasting?', String(toast.message ?? '').toLowerCase())

	// switch (String(toast.message ?? '').toLowerCase()) {
	// 	case 'failed to fetch':
	// 	case 'internal server error':
	// 	case '500:':
	// 		return
	// 	default:
	// 		return dispatchCustomEvent<ToastInfo>(SHOW_TOAST_EVENT_NAME, toast)
	// }
}

export function Toaster() {
	const [toasts, setToasts] = React.useState<ToastInfo[]>([])

	useEvent<{ result: ToastInfo }>(process.browser ? window : null, SHOW_TOAST_EVENT_NAME, (e) => {
		setToasts((toasts) => [...toasts, e.result])
	})

	const reversed = React.useMemo(() => {
		return [...toasts].reverse()
	}, [toasts])

	const withoutFailedToFetch = React.useMemo(() => {
		return reversed.filter((toast) => {
			return (
				toast.raw.toLowerCase() !== 'failed to fetch' &&
				toast.raw.toLowerCase() !== 'internal server error'
			)
		})
	}, [reversed])

	return (
		<div className={s.Toaster}>
			{withoutFailedToFetch.map((toast, i) => (
				<Toast
					key={ensuredKey(toast)}
					onClose={() =>
						setToasts((toasts) => {
							const newToasts = [...toasts]
							newToasts.splice(toasts.length - i - 1, 1)
							return newToasts
						})
					}>
					<Toast.Header>
						<strong className="mr-auto">{toast.title ?? '?'}</strong>
						{toast.timestamp && <small>{toast.timestamp.toLocaleString()}</small>}
					</Toast.Header>
					<Toast.Body>{toast.message}</Toast.Body>
				</Toast>
			))}
		</div>
	)
}
