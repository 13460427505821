import { cn } from 'utils/cn'
import LogoShape from '../shapes/ts/DesigncoLogo'
import s from './Logo.module.sass'
import { Spinner } from './Spinner'

export function Logo({ loading }: { loading: boolean | null }) {
	return (
		<div className={cn(s.Logo, loading && s.isLoading)}>
			<LogoShape viewBox="0 0 125 24" />
			<span className={s.LoadingIndicator}>
				<Spinner active={loading ?? false} />
			</span>
		</div>
	)
}
