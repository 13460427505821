import fetch from 'isomorphic-fetch'

export class GraphqlError extends Error {
	errors: { message: string; code: number }[] = []
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function findErrorInResponse(response: { data: any }) {
	const keys = Object.keys(response.data)

	for (const key of keys) {
		if (response.data[key]) {
			const kkeys = Object.keys(response.data[key])
			for (const kkey of kkeys) {
				if (kkey === 'errorMessage' && response.data[key][kkey]) {
					const e = new GraphqlError()
					e.errors = [{ message: response.data[key][kkey], code: 1 }]
					return e
				}
			}
		}
	}
}

export async function fetchGraphql(
	url: string,
	token: string | null,
	query: string,
	variables?: Record<string, unknown>
) {
	const headers: HeadersInit = {
		'Content-Type': 'application/json',
	}
	if (token) {
		headers.Authorization = `Bearer ${token}`
	}
	const response = await fetch(
		`${url}?${query.substr(0, 150).replace(/[^a-zA-Z0-9-]+/gm, '_')}`,
		{
			body: JSON.stringify({ query, variables }),
			method: 'POST',
			headers,
		}
	)
	if (!response.ok) {
		return new Promise((resolve, reject) => {
			response
				.text()
				.then((text) => {
					try {
						const errors = JSON.parse(text) as {
							errors: { message: string; code: number }[]
						}
						const e = new GraphqlError()
						e.errors = errors.errors
						reject(e)
					} catch (err) {
						reject(new Error(text))
					}
				})
				.catch(reject)
		})
	}
	const json = await response.json()

	if (process.browser) {
		const error = findErrorInResponse(json)

		if (error) {
			return Promise.reject(error)
		}
	}

	return json
}
