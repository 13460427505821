import React from 'react'
import { FiLoader } from 'react-icons/fi'
import { cn } from 'utils/cn'
import s from './Spinner.module.sass'

export const Spinner = React.memo(function Spinner({ active }: { active?: boolean }) {
	return (
		<div className={cn(s.Spinner, active && s.active)}>
			<FiLoader />
		</div>
	)
})
