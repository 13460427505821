function normalizeRoute(href: string) {
	href = href.replace(/\/$/, '')
	href = href.replace(/\/index$/, '')
	return href
}

function interpolateUrlParts(original: string, vars: Record<string, string | number>) {
	return original.replace(/\[([^\]]+)\]/g, (original: string, key: string) => {
		if (typeof vars[key] === 'undefined') {
			return original
		}
		return String(vars[key])
	})
}

export function createLinkProps(
	href: string,
	vars: Record<string, string | number> = {},
	interpolateHrefVars?: string[]
) {
	href = href
	const as = interpolateUrlParts(href, vars)

	if (interpolateHrefVars && interpolateHrefVars.length) {
		const hrefVars: Record<string, string | number> = {}
		interpolateHrefVars.forEach((v) => {
			hrefVars[v] = vars[v]
		})
		href = interpolateUrlParts(href, hrefVars)
	}

	return { as: normalizeRoute(as), href: normalizeRoute(href) }
}
