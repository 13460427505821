import { BiCaretDown } from 'react-icons/bi'
import { BsExclamationCircleFill, BsFillStarFill, BsStar } from 'react-icons/bs'
import {
	FaCheckCircle,
	FaQuestionCircle,
	FaRegFolder,
	FaRegFolderOpen,
	FaTimesCircle,
} from 'react-icons/fa'
import {
	FiArrowDown,
	FiArrowLeft,
	FiArrowRight,
	FiArrowUp,
	FiBox,
	FiCheck,
	FiCheckSquare,
	FiCopy,
	FiDownload,
	FiEye,
	FiFile,
	FiFilePlus,
	FiFolderPlus,
	FiHome,
	FiLink,
	FiLogOut,
	FiMail,
	FiMap,
	FiMessageSquare,
	FiPlusCircle,
	FiSearch,
	FiSettings,
	FiSquare,
	FiTrash,
	FiUploadCloud,
	FiUser,
	FiX,
} from 'react-icons/fi'
import { GrDuplicate } from 'react-icons/gr'
import { MdZoomOutMap } from 'react-icons/md'
import { cn } from 'utils/cn'
import s from './Icon.module.sass'

const icons = {
	settings: FiSettings,
	delete: FiTrash,
	folder: FaRegFolder,
	folderOpen: FaRegFolderOpen,
	user: FiUser,
	document: FiFile,
	checked: FiCheckSquare,
	unchecked: FiSquare,
	anchor: FiLink,
	createFolder: FiFolderPlus,
	createDocument: FiFilePlus,
	upload: FiUploadCloud,
	download: FiDownload,
	home: FiHome,
	project: FiMap,
	building: FiHome,
	housingUnit: FiBox,
	messages: FiMessageSquare,
	email: FiMail,
	signOut: FiLogOut,
	statusUnknown: FaQuestionCircle,
	statusNone: FaQuestionCircle,
	statusSuccess: FaCheckCircle,
	statusError: FaTimesCircle,
	statusWarning: BsExclamationCircleFill,
	cross: FiX,
	inherit: FiCopy,
	tick: FiCheck,
	preview: FiEye,
	waitingApproved: FaQuestionCircle,
	approved: FaCheckCircle,
	disapproved: FaTimesCircle,
	search: FiSearch,
	plus: FiPlusCircle,
	arrowDown: FiArrowDown,
	arrowUp: FiArrowUp,
	arrowLeft: FiArrowLeft,
	arrowRight: FiArrowRight,
	zoom: MdZoomOutMap,
	caret: BiCaretDown,
	duplicate: GrDuplicate,
	favorite: BsStar,
	favoriteOn: BsFillStarFill,
} as const

export type IconName = keyof typeof icons

export function Icon({ name, className }: { name: IconName | null; className?: string }) {
	if (!name) {
		return null
	}
	const Component = icons[name]
	return (
		<span className={cn(s.Icon, className)}>
			<Component />
		</span>
	)
}
