import { useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useEvent<Extradata = { result: any }>(
	target: Window | null,
	event: string,
	handler: (evt: Event & Extradata) => void,
	passive = false
) {
	useEffect(() => {
		if (target) {
			target.addEventListener(event, handler as EventListener, passive)

			return function cleanup() {
				target.removeEventListener(event, handler as EventListener)
			}
		}
	}, [target, event, handler, passive])
}
