import React from 'react'
import s from './Username.module.sass'

export function Username({ name }: { name: string; avatar?: string }) {
	return (
		<span className={s.Username}>
			<span className={s.Name}>{name || '?'}</span>
		</span>
	)
}
