import { SWITCH_ROLE_KEY } from 'components/Header'
import React from 'react'
import { Role } from 'src/constants'

export type Identity = { name: string; id: string; email: string; roles: string[] }
export type Token = string

export interface AuthorizedAuthContextType {
	identity: Identity
	token: Token
	prevToken?: Token
}

export type AuthContextType = null | false | AuthorizedAuthContextType

export const AuthContext = React.createContext<AuthContextType>(null)

export const useAuthContext = () => React.useContext(AuthContext)
const privilegedRoles = [Role.ADMIN, Role.CONTRACTOR, Role.DEVELOPER, Role.SALESPERSON]

export const useGetRole = () => {
	const auth = useAuthContext()

	if (auth) {
		return auth.identity.roles[0] ?? false
	}

	return false
}

export const useIsInRole = (role: Role | Role[]) => {
	const auth = useAuthContext()

	const roles = typeof role === 'string' ? [role] : role

	if (auth) {
		for (let i = 0; i < roles.length; i++) {
			const result = auth.identity.roles.indexOf(roles[i]) > -1
			if (result) {
				return true
			}
		}
	}

	return false
}

export const useIsInOverridableRole = (role: Role) => {
	const isAdmin = useIsAdmin()
	const isInRole = useIsInRole(role)

	if (!isAdmin) {
		return isInRole
	}

	if (process.browser) {
		const overrided = window.localStorage.getItem(SWITCH_ROLE_KEY) as Role | null
		if (overrided) {
			return overrided === role
		}
	}

	return isInRole
}

export const useIsPrivileged = () => {
	return useIsInRole(privilegedRoles)
}

export const useIsContractor = () => {
	return useIsInRole(Role.CONTRACTOR)
}

export const useIsAdmin = () => {
	return useIsInRole(Role.ADMIN)
}

export const useIsOverridableAdmin = () => {
	return useIsInOverridableRole(Role.ADMIN)
}

export const useIsOverridablePrivileged = () => {
	const isAdmin = useIsInOverridableRole(Role.ADMIN)
	const isContractor = useIsInOverridableRole(Role.CONTRACTOR)
	const isSalesperson = useIsInOverridableRole(Role.SALESPERSON)
	const isDeveloper = useIsInOverridableRole(Role.DEVELOPER)
	return isAdmin || isContractor || isSalesperson || isDeveloper
}

export const useIsOverridableHousingUnitOwner = () => {
	return useIsInOverridableRole(Role.HOUSING_UNIT_OWNER)
}

export const useIsOverridableContractor = () => {
	return useIsInOverridableRole(Role.CONTRACTOR)
}

export const useIsOverridableSalesperson = () => {
	return useIsInOverridableRole(Role.SALESPERSON)
}

export const useIsDeveloper = () => {
	const isDeveloper = useIsInRole(Role.DEVELOPER)

	return isDeveloper
}

export const useIsOverridableDeveloper = () => {
	const isInOverridableDeveloperRole = useIsInOverridableRole(Role.DEVELOPER)

	return isInOverridableDeveloperRole
}
