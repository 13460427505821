import { BuildingPageView } from './pages/building/[id]'
import { HousingUnitPageView } from './pages/housingUnit/[id]'
import { HousingUnitTemplatePageView } from './pages/housingUnitTemplate/[id]'
import { ProjectPageView } from './pages/project/[id]/add-building'
import { createLinkProps } from './utils/normalizeRoute'

export const CONTEMBER_PROJECT_SLUG = 'myDesignCo'

export const EVENT_SIGNIN_SUCCESS = 'designco-signin-success'
export const EVENT_SIGNOUT_REQUEST = 'designco-signout-request'
export const LOCALSTORAGE_AUTH_KEY = 'designco-authValue'

export const DEFAULT_HOUSING_OWNER_FOLDER_PERMISSIONS = {
	canRead: true,
	canRename: false,
	canCreateSubfolders: false,
	canDeleteSubfolders: false,
	canCreateDocuments: true,
	canDeleteDocuments: false, // TODO: jen svoje
	canCreateNewVersions: false,
} as const

export const NULL_HOUSING_OWNER_DOCUMENT_PERMISSIONS = {} as const

export const DEFAULT_HOUSING_OWNER_DOCUMENT_PERMISSIONS = {
	canRead: true,
	canRename: false, // TODO: jen svoje
	canCreateNewVersions: false, // TODO: jen svoje
} as const

export const DEFAULT_INHERITED_FOLDER_PERMISSIONS = {
	canRead: null,
	canRename: null,
	canCreateSubfolders: null,
	canDeleteSubfolders: null,
	canCreateDocuments: null,
	canDeleteDocuments: null,
	canCreateNewVersions: null,
} as const

export const DEFAULT_INHERITED_DOCUMENT_PERMISSIONS = {
	canRead: null,
	canRename: null,
	canCreateNewVersions: null,
} as const

export const DEFAULT_CONTRACTOR_FOLDER_PERMISSIONS = {
	canRead: true,
	canRename: false,
	canCreateSubfolders: true,
	canDeleteSubfolders: true, // TODO: jen svoje
	canCreateDocuments: true,
	canDeleteDocuments: true, // TODO: jen svoje
	canCreateNewVersions: true,
} as const

export const DEFAULT_CONTRACTOR_DOCUMENT_PERMISSIONS = {
	canRead: true,
	canRename: true,
	canCreateNewVersions: true,
} as const

export enum Role {
	HOUSING_UNIT_OWNER = 'housingUnitOwner',
	ADMIN = 'admin',
	CONTRACTOR = 'contractor',
	DEVELOPER = 'developer',
	SALESPERSON = 'salesperson',
}

export const AllRoles = [
	Role.ADMIN,
	Role.CONTRACTOR,
	Role.HOUSING_UNIT_OWNER,
	Role.DEVELOPER,
	Role.SALESPERSON,
]

export enum EntityTypename {
	PROJECT = 'project',
	BUILDING = 'building',
	HOUSING_UNIT = 'housingUnit',
	HOUSING_UNIT_TEMPLATE = 'housingUnitTemplate',
	USER = 'user',
}

export const roleNamesSingular: Record<Role, string> = {
	[Role.ADMIN]: 'Administrátor',
	[Role.CONTRACTOR]: 'Dodavatel',
	[Role.HOUSING_UNIT_OWNER]: 'Majitel',
	[Role.DEVELOPER]: 'Developer',
	[Role.SALESPERSON]: 'Sales',
}

export const roleNamesPlural: Record<Role, string> = {
	[Role.ADMIN]: 'Administrátoři',
	[Role.CONTRACTOR]: 'Dodavatelé',
	[Role.HOUSING_UNIT_OWNER]: 'Majitelé',
	[Role.DEVELOPER]: 'Developeři',
	[Role.SALESPERSON]: 'Sales',
}

export const ROUTES = {
	HOMEPAGE: '/',
	SIGN_IN: '/sign/in',
	SIGN_OUT: '/sign/out',
	FORGOTTEN_PASSWORD: '/sign/forgotten-password',
	MY_PROFILE: '/me',
	MY_SETTINGS: '/me/settings',
	ADD_PROJECT: '/admin/add-project',
	USERS: '/admin/users',
	USERS_ACL: '/admin/acl',
	INVITE: '/admin/invite',
	ADD_CONFIGURATOR: '/admin/add-configurator',
	ADD_HOUSING_UNIT_TEMPLATE: '/admin/add-housingUnitTemplate',
	SETTINGS: '/admin/settings',
	LOGS: '/admin/logs',
	PROJECT(id: string, view: ProjectPageView = 'index') {
		return createLinkProps('/project/[id]/[view]', { id, view }, ['view'])
	},
	BUILDING(id: string, view: BuildingPageView = 'index') {
		return createLinkProps('/building/[id]/[view]', { id, view }, ['view'])
	},
	HOUSING_UNIT(id: string, view: HousingUnitPageView = 'index') {
		return createLinkProps('/housingUnit/[id]/[view]', { id, view }, ['view'])
	},
	HOUSING_UNIT_TEMPLATE(id: string, view: HousingUnitTemplatePageView = 'index') {
		return createLinkProps('/housingUnitTemplate/[id]/[view]', { id, view }, ['view'])
	},
	USER(id: string, view: 'index' = 'index') {
		return createLinkProps('/user/[id]/[view]', { id, view }, ['view'])
	},
} as const
