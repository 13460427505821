/* tslint:disable */
/* eslint-disable */

export type ValueTypes = {
    ["CreateEvent"]: AliasType<{
	id?:true,
	transactionId?:true,
	identityId?:true,
	identityDescription?:true,
	description?:true,
	createdAt?:true,
	appliedAt?:true,
	type?:true,
	tableName?:true,
	primaryKey?:true,
	newValues?:true,
		__typename?: true
}>;
	/** DateTime custom scalar type */
["DateTime"]:unknown;
	["DeleteEvent"]: AliasType<{
	id?:true,
	transactionId?:true,
	identityId?:true,
	identityDescription?:true,
	description?:true,
	createdAt?:true,
	appliedAt?:true,
	type?:true,
	tableName?:true,
	primaryKey?:true,
	oldValues?:true,
		__typename?: true
}>;
	["Event"]:AliasType<{
		id?:true,
	transactionId?:true,
	identityDescription?:true,
	identityId?:true,
	description?:true,
	createdAt?:true,
	appliedAt?:true,
	type?:true,
	tableName?:true,
	primaryKey?:true;
		['...on CreateEvent']?: Omit<ValueTypes["CreateEvent"],keyof ValueTypes["Event"]>;
		['...on DeleteEvent']?: Omit<ValueTypes["DeleteEvent"],keyof ValueTypes["Event"]>;
		['...on UpdateEvent']?: Omit<ValueTypes["UpdateEvent"],keyof ValueTypes["Event"]>;
		__typename?: true
}>;
	["EventFilterRow"]: {
	tableName:string,
	primaryKey:ValueTypes["PrimaryKey"][]
};
	["EventsArgs"]: {
	stage?:string,
	filter?:ValueTypes["EventsFilter"],
	order?:ValueTypes["EventsOrder"],
	offset?:number,
	/** Max 10000 */
	limit?:number
};
	["EventsFilter"]: {
	types?:ValueTypes["EventType"][],
	rows?:ValueTypes["EventFilterRow"][],
	tables?:string[],
	transactions?:string[],
	identities?:string[],
	createdAt?:ValueTypes["EventsFilterDate"],
	appliedAt?:ValueTypes["EventsFilterDate"]
};
	["EventsFilterDate"]: {
	from?:ValueTypes["DateTime"],
	to?:ValueTypes["DateTime"]
};
	["EventsOrder"]:EventsOrder;
	["EventType"]:EventType;
	["ExecutedMigration"]: AliasType<{
	version?:true,
	name?:true,
	executedAt?:true,
	checksum?:true,
	formatVersion?:true,
	modifications?:true,
		__typename?: true
}>;
	/** Json custom scalar type */
["Json"]:unknown;
	["MigrateError"]: AliasType<{
	code?:true,
	migration?:true,
	developerMessage?:true,
		__typename?: true
}>;
	["MigrateErrorCode"]:MigrateErrorCode;
	["MigrateResponse"]: AliasType<{
	ok?:true,
	errors?:ValueTypes["MigrateError"],
	error?:ValueTypes["MigrateError"],
	result?:ValueTypes["MigrateResult"],
		__typename?: true
}>;
	["MigrateResult"]: AliasType<{
	message?:true,
		__typename?: true
}>;
	["Migration"]: {
	version:string,
	name:string,
	formatVersion:number,
	modifications:ValueTypes["Json"][]
};
	["MigrationDeleteError"]: AliasType<{
	code?:true,
	developerMessage?:true,
		__typename?: true
}>;
	["MigrationDeleteErrorCode"]:MigrationDeleteErrorCode;
	["MigrationDeleteResponse"]: AliasType<{
	ok?:true,
	error?:ValueTypes["MigrationDeleteError"],
		__typename?: true
}>;
	["MigrationModification"]: {
	version?:string,
	name?:string,
	formatVersion?:number,
	modifications?:ValueTypes["Json"][]
};
	["MigrationModifyError"]: AliasType<{
	code?:true,
	developerMessage?:true,
		__typename?: true
}>;
	["MigrationModifyErrorCode"]:MigrationModifyErrorCode;
	["MigrationModifyResponse"]: AliasType<{
	ok?:true,
	error?:ValueTypes["MigrationModifyError"],
		__typename?: true
}>;
	["Mutation"]: AliasType<{
migrate?: [{	migrations:ValueTypes["Migration"][]},ValueTypes["MigrateResponse"]],
	truncate?:ValueTypes["TruncateResponse"],
forceMigrate?: [{	migrations:ValueTypes["Migration"][]},ValueTypes["MigrateResponse"]],
migrationModify?: [{	migration:string,	modification:ValueTypes["MigrationModification"]},ValueTypes["MigrationModifyResponse"]],
migrationDelete?: [{	migration:string},ValueTypes["MigrationDeleteResponse"]],
		__typename?: true
}>;
	/** PrimaryKey custom scalar type. Can be Int or String */
["PrimaryKey"]:unknown;
	["Query"]: AliasType<{
	stages?:ValueTypes["Stage"],
executedMigrations?: [{	version?:string},ValueTypes["ExecutedMigration"]],
events?: [{	args?:ValueTypes["EventsArgs"]},ValueTypes["Event"]],
		__typename?: true
}>;
	["Stage"]: AliasType<{
	id?:true,
	name?:true,
	slug?:true,
		__typename?: true
}>;
	["TruncateResponse"]: AliasType<{
	ok?:true,
		__typename?: true
}>;
	["UpdateEvent"]: AliasType<{
	id?:true,
	transactionId?:true,
	identityId?:true,
	identityDescription?:true,
	description?:true,
	createdAt?:true,
	appliedAt?:true,
	type?:true,
	tableName?:true,
	primaryKey?:true,
	oldValues?:true,
	diffValues?:true,
		__typename?: true
}>
  }

export type PartialObjects = {
    ["CreateEvent"]: {
		__typename?: "CreateEvent";
			id?:string,
			transactionId?:string,
			identityId?:string,
			identityDescription?:string,
			description?:string,
			createdAt?:PartialObjects["DateTime"],
			appliedAt?:PartialObjects["DateTime"],
			type?:PartialObjects["EventType"],
			tableName?:string,
			primaryKey?:PartialObjects["PrimaryKey"][],
			newValues?:PartialObjects["Json"]
	},
	/** DateTime custom scalar type */
["DateTime"]:any,
	["DeleteEvent"]: {
		__typename?: "DeleteEvent";
			id?:string,
			transactionId?:string,
			identityId?:string,
			identityDescription?:string,
			description?:string,
			createdAt?:PartialObjects["DateTime"],
			appliedAt?:PartialObjects["DateTime"],
			type?:PartialObjects["EventType"],
			tableName?:string,
			primaryKey?:PartialObjects["PrimaryKey"][],
			oldValues?:PartialObjects["Json"]
	},
	["Event"]:{
		id?:string;
	transactionId?:string;
	identityDescription?:string;
	identityId?:string;
	description?:string;
	createdAt?:PartialObjects["DateTime"];
	appliedAt?:PartialObjects["DateTime"];
	type?:PartialObjects["EventType"];
	tableName?:string;
	primaryKey?:PartialObjects["PrimaryKey"][]
} & (PartialObjects["CreateEvent"] | PartialObjects["DeleteEvent"] | PartialObjects["UpdateEvent"]),
	["EventFilterRow"]: {
	tableName:string,
	primaryKey:PartialObjects["PrimaryKey"][]
},
	["EventsArgs"]: {
	stage?:string,
	filter?:PartialObjects["EventsFilter"],
	order?:PartialObjects["EventsOrder"],
	offset?:number,
	/** Max 10000 */
	limit?:number
},
	["EventsFilter"]: {
	types?:PartialObjects["EventType"][],
	rows?:PartialObjects["EventFilterRow"][],
	tables?:string[],
	transactions?:string[],
	identities?:string[],
	createdAt?:PartialObjects["EventsFilterDate"],
	appliedAt?:PartialObjects["EventsFilterDate"]
},
	["EventsFilterDate"]: {
	from?:PartialObjects["DateTime"],
	to?:PartialObjects["DateTime"]
},
	["EventsOrder"]:EventsOrder,
	["EventType"]:EventType,
	["ExecutedMigration"]: {
		__typename?: "ExecutedMigration";
			version?:string,
			name?:string,
			executedAt?:PartialObjects["DateTime"],
			checksum?:string,
			formatVersion?:number,
			modifications?:PartialObjects["Json"][]
	},
	/** Json custom scalar type */
["Json"]:any,
	["MigrateError"]: {
		__typename?: "MigrateError";
			code?:PartialObjects["MigrateErrorCode"],
			migration?:string,
			developerMessage?:string
	},
	["MigrateErrorCode"]:MigrateErrorCode,
	["MigrateResponse"]: {
		__typename?: "MigrateResponse";
			ok?:boolean,
			errors?:PartialObjects["MigrateError"][],
			error?:PartialObjects["MigrateError"],
			result?:PartialObjects["MigrateResult"]
	},
	["MigrateResult"]: {
		__typename?: "MigrateResult";
			message?:string
	},
	["Migration"]: {
	version:string,
	name:string,
	formatVersion:number,
	modifications:PartialObjects["Json"][]
},
	["MigrationDeleteError"]: {
		__typename?: "MigrationDeleteError";
			code?:PartialObjects["MigrationDeleteErrorCode"],
			developerMessage?:string
	},
	["MigrationDeleteErrorCode"]:MigrationDeleteErrorCode,
	["MigrationDeleteResponse"]: {
		__typename?: "MigrationDeleteResponse";
			ok?:boolean,
			error?:PartialObjects["MigrationDeleteError"]
	},
	["MigrationModification"]: {
	version?:string,
	name?:string,
	formatVersion?:number,
	modifications?:PartialObjects["Json"][]
},
	["MigrationModifyError"]: {
		__typename?: "MigrationModifyError";
			code?:PartialObjects["MigrationModifyErrorCode"],
			developerMessage?:string
	},
	["MigrationModifyErrorCode"]:MigrationModifyErrorCode,
	["MigrationModifyResponse"]: {
		__typename?: "MigrationModifyResponse";
			ok?:boolean,
			error?:PartialObjects["MigrationModifyError"]
	},
	["Mutation"]: {
		__typename?: "Mutation";
			migrate?:PartialObjects["MigrateResponse"],
			truncate?:PartialObjects["TruncateResponse"],
			forceMigrate?:PartialObjects["MigrateResponse"],
			migrationModify?:PartialObjects["MigrationModifyResponse"],
			migrationDelete?:PartialObjects["MigrationDeleteResponse"]
	},
	/** PrimaryKey custom scalar type. Can be Int or String */
["PrimaryKey"]:any,
	["Query"]: {
		__typename?: "Query";
			stages?:PartialObjects["Stage"][],
			executedMigrations?:PartialObjects["ExecutedMigration"][],
			events?:PartialObjects["Event"][]
	},
	["Stage"]: {
		__typename?: "Stage";
			id?:string,
			name?:string,
			slug?:string
	},
	["TruncateResponse"]: {
		__typename?: "TruncateResponse";
			ok?:boolean
	},
	["UpdateEvent"]: {
		__typename?: "UpdateEvent";
			id?:string,
			transactionId?:string,
			identityId?:string,
			identityDescription?:string,
			description?:string,
			createdAt?:PartialObjects["DateTime"],
			appliedAt?:PartialObjects["DateTime"],
			type?:PartialObjects["EventType"],
			tableName?:string,
			primaryKey?:PartialObjects["PrimaryKey"][],
			oldValues?:PartialObjects["Json"],
			diffValues?:PartialObjects["Json"]
	}
  }



export type CreateEvent = {
	__typename?: "CreateEvent",
	id:string,
	transactionId:string,
	identityId:string,
	identityDescription:string,
	description:string,
	createdAt:DateTime,
	appliedAt:DateTime,
	type:EventType,
	tableName:string,
	primaryKey:PrimaryKey[],
	newValues:Json
}

/** DateTime custom scalar type */
export type DateTime = any

export type DeleteEvent = {
	__typename?: "DeleteEvent",
	id:string,
	transactionId:string,
	identityId:string,
	identityDescription:string,
	description:string,
	createdAt:DateTime,
	appliedAt:DateTime,
	type:EventType,
	tableName:string,
	primaryKey:PrimaryKey[],
	oldValues:Json
}

export type Event = {
	__interface:{
			id:string,
	transactionId:string,
	identityDescription:string,
	identityId:string,
	description:string,
	createdAt:DateTime,
	appliedAt:DateTime,
	type:EventType,
	tableName:string,
	primaryKey:PrimaryKey[]
	};
	__resolve:{
		['...on CreateEvent']: CreateEvent;
		['...on DeleteEvent']: DeleteEvent;
		['...on UpdateEvent']: UpdateEvent;
	}
}

export type EventFilterRow = {
		tableName:string,
	primaryKey:PrimaryKey[]
}

export type EventsArgs = {
		stage?:string,
	filter?:EventsFilter,
	order?:EventsOrder,
	offset?:number,
	/** Max 10000 */
	limit?:number
}

export type EventsFilter = {
		types?:EventType[],
	rows?:EventFilterRow[],
	tables?:string[],
	transactions?:string[],
	identities?:string[],
	createdAt?:EventsFilterDate,
	appliedAt?:EventsFilterDate
}

export type EventsFilterDate = {
		from?:DateTime,
	to?:DateTime
}

export enum EventsOrder {
	CREATED_AT_ASC = "CREATED_AT_ASC",
	CREATED_AT_DESC = "CREATED_AT_DESC",
	APPLIED_AT_ASC = "APPLIED_AT_ASC",
	APPLIED_AT_DESC = "APPLIED_AT_DESC"
}

export enum EventType {
	UPDATE = "UPDATE",
	DELETE = "DELETE",
	CREATE = "CREATE"
}

export type ExecutedMigration = {
	__typename?: "ExecutedMigration",
	version:string,
	name:string,
	executedAt:DateTime,
	checksum:string,
	formatVersion:number,
	modifications:Json[]
}

/** Json custom scalar type */
export type Json = any

export type MigrateError = {
	__typename?: "MigrateError",
	code:MigrateErrorCode,
	migration:string,
	developerMessage:string
}

export enum MigrateErrorCode {
	MUST_FOLLOW_LATEST = "MUST_FOLLOW_LATEST",
	ALREADY_EXECUTED = "ALREADY_EXECUTED",
	INVALID_FORMAT = "INVALID_FORMAT",
	INVALID_SCHEMA = "INVALID_SCHEMA",
	MIGRATION_FAILED = "MIGRATION_FAILED"
}

export type MigrateResponse = {
	__typename?: "MigrateResponse",
	ok:boolean,
	errors:MigrateError[],
	error?:MigrateError,
	result?:MigrateResult
}

export type MigrateResult = {
	__typename?: "MigrateResult",
	message:string
}

export type Migration = {
		version:string,
	name:string,
	formatVersion:number,
	modifications:Json[]
}

export type MigrationDeleteError = {
	__typename?: "MigrationDeleteError",
	code:MigrationDeleteErrorCode,
	developerMessage:string
}

export enum MigrationDeleteErrorCode {
	NOT_FOUND = "NOT_FOUND",
	INVALID_FORMAT = "INVALID_FORMAT"
}

export type MigrationDeleteResponse = {
	__typename?: "MigrationDeleteResponse",
	ok:boolean,
	error?:MigrationDeleteError
}

export type MigrationModification = {
		version?:string,
	name?:string,
	formatVersion?:number,
	modifications?:Json[]
}

export type MigrationModifyError = {
	__typename?: "MigrationModifyError",
	code:MigrationModifyErrorCode,
	developerMessage:string
}

export enum MigrationModifyErrorCode {
	NOT_FOUND = "NOT_FOUND"
}

export type MigrationModifyResponse = {
	__typename?: "MigrationModifyResponse",
	ok:boolean,
	error?:MigrationModifyError
}

export type Mutation = {
	__typename?: "Mutation",
	migrate:MigrateResponse,
	truncate:TruncateResponse,
	forceMigrate:MigrateResponse,
	migrationModify:MigrationModifyResponse,
	migrationDelete:MigrationDeleteResponse
}

/** PrimaryKey custom scalar type. Can be Int or String */
export type PrimaryKey = any

export type Query = {
	__typename?: "Query",
	stages:Stage[],
	executedMigrations:ExecutedMigration[],
	events:Event[]
}

export type Stage = {
	__typename?: "Stage",
	id:string,
	name:string,
	slug:string
}

export type TruncateResponse = {
	__typename?: "TruncateResponse",
	ok:boolean
}

export type UpdateEvent = {
	__typename?: "UpdateEvent",
	id:string,
	transactionId:string,
	identityId:string,
	identityDescription:string,
	description:string,
	createdAt:DateTime,
	appliedAt:DateTime,
	type:EventType,
	tableName:string,
	primaryKey:PrimaryKey[],
	oldValues:Json,
	diffValues:Json
}

export const AllTypesProps: Record<string,any> = {
	DateTime: "String",
	EventFilterRow:{
		tableName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		primaryKey:{
			type:"PrimaryKey",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	EventsArgs:{
		stage:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		filter:{
			type:"EventsFilter",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"EventsOrder",
			array:false,
			arrayRequired:false,
			required:false
		},
		offset:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		limit:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	EventsFilter:{
		types:{
			type:"EventType",
			array:true,
			arrayRequired:false,
			required:true
		},
		rows:{
			type:"EventFilterRow",
			array:true,
			arrayRequired:false,
			required:true
		},
		tables:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		transactions:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		identities:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		createdAt:{
			type:"EventsFilterDate",
			array:false,
			arrayRequired:false,
			required:false
		},
		appliedAt:{
			type:"EventsFilterDate",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	EventsFilterDate:{
		from:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		to:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	EventsOrder: "enum",
	EventType: "enum",
	Json: "String",
	MigrateErrorCode: "enum",
	Migration:{
		version:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		formatVersion:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		modifications:{
			type:"Json",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	MigrationDeleteErrorCode: "enum",
	MigrationModification:{
		version:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		formatVersion:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		modifications:{
			type:"Json",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	MigrationModifyErrorCode: "enum",
	Mutation:{
		migrate:{
			migrations:{
				type:"Migration",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		forceMigrate:{
			migrations:{
				type:"Migration",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		migrationModify:{
			migration:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			modification:{
				type:"MigrationModification",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		migrationDelete:{
			migration:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	PrimaryKey: "String",
	Query:{
		executedMigrations:{
			version:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		events:{
			args:{
				type:"EventsArgs",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	}
}

export const ReturnTypes: Record<string,any> = {
	CreateEvent:{
		id:"String",
		transactionId:"String",
		identityId:"String",
		identityDescription:"String",
		description:"String",
		createdAt:"DateTime",
		appliedAt:"DateTime",
		type:"EventType",
		tableName:"String",
		primaryKey:"PrimaryKey",
		newValues:"Json"
	},
	DeleteEvent:{
		id:"String",
		transactionId:"String",
		identityId:"String",
		identityDescription:"String",
		description:"String",
		createdAt:"DateTime",
		appliedAt:"DateTime",
		type:"EventType",
		tableName:"String",
		primaryKey:"PrimaryKey",
		oldValues:"Json"
	},
	Event:{
		"...on CreateEvent": "CreateEvent",
		"...on DeleteEvent": "DeleteEvent",
		"...on UpdateEvent": "UpdateEvent",
		id:"String",
		transactionId:"String",
		identityDescription:"String",
		identityId:"String",
		description:"String",
		createdAt:"DateTime",
		appliedAt:"DateTime",
		type:"EventType",
		tableName:"String",
		primaryKey:"PrimaryKey"
	},
	ExecutedMigration:{
		version:"String",
		name:"String",
		executedAt:"DateTime",
		checksum:"String",
		formatVersion:"Int",
		modifications:"Json"
	},
	MigrateError:{
		code:"MigrateErrorCode",
		migration:"String",
		developerMessage:"String"
	},
	MigrateResponse:{
		ok:"Boolean",
		errors:"MigrateError",
		error:"MigrateError",
		result:"MigrateResult"
	},
	MigrateResult:{
		message:"String"
	},
	MigrationDeleteError:{
		code:"MigrationDeleteErrorCode",
		developerMessage:"String"
	},
	MigrationDeleteResponse:{
		ok:"Boolean",
		error:"MigrationDeleteError"
	},
	MigrationModifyError:{
		code:"MigrationModifyErrorCode",
		developerMessage:"String"
	},
	MigrationModifyResponse:{
		ok:"Boolean",
		error:"MigrationModifyError"
	},
	Mutation:{
		migrate:"MigrateResponse",
		truncate:"TruncateResponse",
		forceMigrate:"MigrateResponse",
		migrationModify:"MigrationModifyResponse",
		migrationDelete:"MigrationDeleteResponse"
	},
	Query:{
		stages:"Stage",
		executedMigrations:"ExecutedMigration",
		events:"Event"
	},
	Stage:{
		id:"String",
		name:"String",
		slug:"String"
	},
	TruncateResponse:{
		ok:"Boolean"
	},
	UpdateEvent:{
		id:"String",
		transactionId:"String",
		identityId:"String",
		identityDescription:"String",
		description:"String",
		createdAt:"DateTime",
		appliedAt:"DateTime",
		type:"EventType",
		tableName:"String",
		primaryKey:"PrimaryKey",
		oldValues:"Json",
		diffValues:"Json"
	}
}

export class GraphQLError extends Error {
    constructor(public response: GraphQLResponse) {
      super("");
      console.error(response);
    }
    toString() {
      return "GraphQL Response Error";
    }
  }



export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<
  UnwrapPromise<ReturnType<T>>
>;
export type ZeusHook<
  T extends (
    ...args: any[]
  ) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

type Func<P extends any[], R> = (...args: P) => R;
type AnyFunc = Func<any, any>;

type WithTypeNameValue<T> = T & {
  __typename?: true;
};

type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};

type NotUndefined<T> = T extends undefined ? never : T;

export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type ArgsType<F extends AnyFunc> = F extends Func<infer P, any> ? P : never;

interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}

export type ValuesOf<T> = T[keyof T];

export type MapResolve<SRC, DST> = SRC extends {
    __interface: infer INTERFACE;
    __resolve: Record<string, { __typename?: string }> & infer IMPLEMENTORS;
  }
  ?
  ValuesOf<{
    [k in (keyof SRC['__resolve'] & keyof DST)]: ({
      [rk in (keyof SRC['__resolve'][k] & keyof DST[k])]: LastMapTypeSRCResolver<SRC['__resolve'][k][rk], DST[k][rk]>
    } & {
      __typename: SRC['__resolve'][k]['__typename']
    })
  }>
  :
  never;

export type MapInterface<SRC, DST> = SRC extends {
    __interface: infer INTERFACE;
    __resolve: Record<string, { __typename?: string }> & infer IMPLEMENTORS;
  }
  ?
  (MapResolve<SRC, DST> extends never ? {} : MapResolve<SRC, DST>) & {
  [k in (keyof SRC['__interface'] & keyof DST)]: LastMapTypeSRCResolver<SRC['__interface'][k], DST[k]>
} : never;

export type ValueToUnion<T> = T extends {
  __typename: infer R;
}
  ? {
      [P in keyof Omit<T, '__typename'>]: T[P] & {
        __typename: R;
      };
    }
  : T;

export type ObjectToUnion<T> = {
  [P in keyof T]: T[P];
}[keyof T];

type Anify<T> = { [P in keyof T]?: any };


type LastMapTypeSRCResolver<SRC, DST> = SRC extends undefined
  ? undefined
  : SRC extends Array<infer AR>
  ? LastMapTypeSRCResolver<AR, DST>[]
  : SRC extends { __interface: any; __resolve: any }
  ? MapInterface<SRC, DST>
  : SRC extends { __union: any; __resolve: infer RESOLVE }
  ? ObjectToUnion<MapType<RESOLVE, ValueToUnion<DST>>>
  : DST extends boolean
  ? SRC
  : MapType<SRC, DST>;

export type MapType<SRC extends Anify<DST>, DST> = DST extends boolean
  ? SRC
  : DST extends {
      __alias: any;
    }
  ? {
      [A in keyof DST["__alias"]]: Required<SRC> extends Anify<
        DST["__alias"][A]
      >
        ? MapType<Required<SRC>, DST["__alias"][A]>
        : never;
    } &
      {
        [Key in keyof Omit<DST, "__alias">]: DST[Key] extends [
          any,
          infer PAYLOAD
        ]
          ? LastMapTypeSRCResolver<SRC[Key], PAYLOAD>
          : LastMapTypeSRCResolver<SRC[Key], DST[Key]>;
      }
  : {
      [Key in keyof DST]: DST[Key] extends [any, infer PAYLOAD]
        ? LastMapTypeSRCResolver<SRC[Key], PAYLOAD>
        : LastMapTypeSRCResolver<SRC[Key], DST[Key]>;
    };

type OperationToGraphQL<V, T> = <Z extends V>(o: Z | V, variables?: Record<string, any>) => Promise<MapType<T, Z>>;

type CastToGraphQL<V, T> = (
  resultOfYourQuery: any
) => <Z extends V>(o: Z | V) => MapType<T, Z>;

type fetchOptions = ArgsType<typeof fetch>;

export type SelectionFunction<V> = <T>(t: T | V) => T;
type FetchFunction = (query: string, variables?: Record<string, any>) => Promise<any>;



export const ZeusSelect = <T>() => ((t: any) => t) as SelectionFunction<T>;

export const ScalarResolver = (scalar: string, value: any) => {
  switch (scalar) {
    case 'String':
      return  `${JSON.stringify(value)}`;
    case 'Int':
      return `${value}`;
    case 'Float':
      return `${value}`;
    case 'Boolean':
      return `${value}`;
    case 'ID':
      return `"${value}"`;
    case 'enum':
      return `${value}`;
    case 'scalar':
      return `${value}`;
    default:
      return false;
  }
};


export const TypesPropsResolver = ({
    value,
    type,
    name,
    key,
    blockArrays
}: {
    value: any;
    type: string;
    name: string;
    key?: string;
    blockArrays?: boolean;
}): string => {
    if (value === null) {
        return `null`;
    }
    let resolvedValue = AllTypesProps[type][name];
    if (key) {
        resolvedValue = resolvedValue[key];
    }
    if (!resolvedValue) {
        throw new Error(`Cannot resolve ${type} ${name}${key ? ` ${key}` : ''}`)
    }
    const typeResolved = resolvedValue.type;
    const isArray = resolvedValue.array;
    const isArrayRequired = resolvedValue.arrayRequired;
    if (typeof value === 'string' && value.startsWith(`ZEUS_VAR$`)) {
        const isRequired = resolvedValue.required ? '!' : '';
        let t = `${typeResolved}`;
        if (isArray) {
          if (isRequired) {
              t = `${t}!`;
          }
          t = `[${t}]`;
          if(isArrayRequired){
            t = `${t}!`;
          }
        }else{
          if (isRequired) {
                t = `${t}!`;
          }
        }
        return `\$${value.split(`ZEUS_VAR$`)[1]}__ZEUS_VAR__${t}`;
    }
    if (isArray && !blockArrays) {
        return `[${value
        .map((v: any) => TypesPropsResolver({ value: v, type, name, key, blockArrays: true }))
        .join(',')}]`;
    }
    const reslovedScalar = ScalarResolver(typeResolved, value);
    if (!reslovedScalar) {
        const resolvedType = AllTypesProps[typeResolved];
        if (typeof resolvedType === 'object') {
        const argsKeys = Object.keys(resolvedType);
        return `{${argsKeys
            .filter((ak) => value[ak] !== undefined)
            .map(
            (ak) => `${ak}:${TypesPropsResolver({ value: value[ak], type: typeResolved, name: ak })}`
            )}}`;
        }
        return ScalarResolver(AllTypesProps[typeResolved], value) as string;
    }
    return reslovedScalar;
};


const isArrayFunction = (
  parent: string[],
  a: any[]
) => {
  const [values, r] = a;
  const [mainKey, key, ...keys] = parent;
  const keyValues = Object.keys(values).filter((k) => typeof values[k] !== 'undefined');

  if (!keys.length) {
      return keyValues.length > 0
        ? `(${keyValues
            .map(
              (v) =>
                `${v}:${TypesPropsResolver({
                  value: values[v],
                  type: mainKey,
                  name: key,
                  key: v
                })}`
            )
            .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
        : traverseToSeekArrays(parent, r);
    }

  const [typeResolverKey] = keys.splice(keys.length - 1, 1);
  let valueToResolve = ReturnTypes[mainKey][key];
  for (const k of keys) {
    valueToResolve = ReturnTypes[valueToResolve][k];
  }

  const argumentString =
    keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: valueToResolve,
                name: typeResolverKey,
                key: v
              })}`
          )
          .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
      : traverseToSeekArrays(parent, r);
  return argumentString;
};


const resolveKV = (k: string, v: boolean | string | { [x: string]: boolean | string }) =>
  typeof v === 'boolean' ? k : typeof v === 'object' ? `${k}{${objectToTree(v)}}` : `${k}${v}`;


const objectToTree = (o: { [x: string]: boolean | string }): string =>
  `{${Object.keys(o).map((k) => `${resolveKV(k, o[k])}`).join(' ')}}`;


const traverseToSeekArrays = (parent: string[], a?: any): string => {
  if (!a) return '';
  if (Object.keys(a).length === 0) {
    return '';
  }
  let b: Record<string, any> = {};
  if (Array.isArray(a)) {
    return isArrayFunction([...parent], a);
  } else {
    if (typeof a === 'object') {
      Object.keys(a)
        .filter((k) => typeof a[k] !== 'undefined')
        .map((k) => {
        if (k === '__alias') {
          Object.keys(a[k]).map((aliasKey) => {
            const aliasOperations = a[k][aliasKey];
            const aliasOperationName = Object.keys(aliasOperations)[0];
            const aliasOperation = aliasOperations[aliasOperationName];
            b[
              `${aliasOperationName}__alias__${aliasKey}: ${aliasOperationName}`
            ] = traverseToSeekArrays([...parent, aliasOperationName], aliasOperation);
          });
        } else {
          b[k] = traverseToSeekArrays([...parent, k], a[k]);
        }
      });
    } else {
      return '';
    }
  }
  return objectToTree(b);
};  


const buildQuery = (type: string, a?: Record<any, any>) => 
  traverseToSeekArrays([type], a);


const inspectVariables = (query: string) => {
  const regex = /\$\b\w*__ZEUS_VAR__\[?[^!^\]^\s^,^\)^\}]*[!]?[\]]?[!]?/g;
  let result;
  const AllVariables: string[] = [];
  while ((result = regex.exec(query))) {
    if (AllVariables.includes(result[0])) {
      continue;
    }
    AllVariables.push(result[0]);
  }
  if (!AllVariables.length) {
    return query;
  }
  let filteredQuery = query;
  AllVariables.forEach((variable) => {
    while (filteredQuery.includes(variable)) {
      filteredQuery = filteredQuery.replace(variable, variable.split('__ZEUS_VAR__')[0]);
    }
  });
  return `(${AllVariables.map((a) => a.split('__ZEUS_VAR__'))
    .map(([variableName, variableType]) => `${variableName}:${variableType}`)
    .join(', ')})${filteredQuery}`;
};


const queryConstruct = (t: 'query' | 'mutation' | 'subscription', tName: string) => (o: Record<any, any>) =>
  `${t.toLowerCase()}${inspectVariables(buildQuery(tName, o))}`;
  

const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription', tName: string) => (
  o: Record<any, any>,
  variables?: Record<string, any>,
) => fn(queryConstruct(t, tName)(o), variables).then((r:any) => { 
  seekForAliases(r)
  return r
});


const seekForAliases = (response: any) => {
  const traverseAlias = (value: any) => {
    if (Array.isArray(value)) {
      value.forEach(seekForAliases);
    } else {
      if (typeof value === 'object') {
        seekForAliases(value);
      }
    }
  };
  if (typeof response === 'object' && response) {
    const keys = Object.keys(response);
    if (keys.length < 1) {
      return;
    }
    keys.forEach((k) => {
      const value = response[k];
      if (k.indexOf('__alias__') !== -1) {
        const [operation, alias] = k.split('__alias__');
        response[alias] = {
          [operation]: value,
        };
        delete response[k];
      }
      traverseAlias(value);
    });
  }
};


export const $ = (t: TemplateStringsArray): any => `ZEUS_VAR$${t.join('')}`;


const handleFetchResponse = (
  response: Parameters<Extract<Parameters<ReturnType<typeof fetch>['then']>[0], Function>>[0]
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response.text().then(text => {
        try { reject(JSON.parse(text)); }
        catch (err) { reject(text); }
      }).catch(reject);
    });
  }
  return response.json();
};

const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, any> = {}) => {
    let fetchFunction = fetch;
    let queryString = query;
    let fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      queryString = encodeURIComponent(query);
      return fetchFunction(`${options[0]}?query=${queryString}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetchFunction(`${options[0]}`, {
      body: JSON.stringify({ query: queryString, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      ...fetchOptions
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };
  


export const Thunder = (fn: FetchFunction) => ({
  query: ((o: any, variables) =>
    fullChainConstruct(fn)('query', 'Query')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Query"],Query>,
mutation: ((o: any, variables) =>
    fullChainConstruct(fn)('mutation', 'Mutation')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Mutation"],Mutation>
});

export const Chain = (...options: fetchOptions) => ({
  query: ((o: any, variables) =>
    fullChainConstruct(apiFetch(options))('query', 'Query')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Query"],Query>,
mutation: ((o: any, variables) =>
    fullChainConstruct(apiFetch(options))('mutation', 'Mutation')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Mutation"],Mutation>
});
export const Zeus = {
  query: (o:ValueTypes["Query"]) => queryConstruct('query', 'Query')(o),
mutation: (o:ValueTypes["Mutation"]) => queryConstruct('mutation', 'Mutation')(o)
};
export const Cast = {
  query: ((o: any) => (_: any) => o) as CastToGraphQL<
  ValueTypes["Query"],
  Query
>,
mutation: ((o: any) => (_: any) => o) as CastToGraphQL<
  ValueTypes["Mutation"],
  Mutation
>
};
export const Selectors = {
  query: ZeusSelect<ValueTypes["Query"]>(),
mutation: ZeusSelect<ValueTypes["Mutation"]>()
};
  