import { Header } from 'components/Header'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { ROUTES } from 'src/constants'
import { useAuthContext } from 'src/contexts/authContext'
import { LayoutProps } from '../pages/_app'
import { cn } from '../utils/cn'
import s from './Layout.module.sass'

export function AuthorizedLayout({ children }: LayoutProps) {
	const auth = useAuthContext()
	const router = useRouter()

	React.useEffect(() => {
		if (auth === false) {
			console.log('Not authorized', window.location.href)
			router.push({
				pathname: ROUTES.SIGN_IN,
				query: {
					backlink: window.location.href,
				},
			})
		}
	}, [auth, router])

	if (auth === null) {
		return null
	}

	return (
		<div className={cn(s.Container, s.viewAuthorized)}>
			<Header />
			<div className={s.BodyContainer}>
				{auth ? (
					children
				) : (
					<div role="alert" className={cn(s.ErrorListItem, 'alert alert-primary')}>
						You are not autorized.{' '}
						<Link href={ROUTES.SIGN_IN}>
							<a className="alert-link">Sign in</a>
						</Link>
					</div>
				)}
			</div>
		</div>
	)
}
