import { fetchGraphql, GraphqlError } from 'server/utils/fetchGraphql'
import { Thunder } from '../../../generated/gateway'

export function createContemberGatewayClient(
	url: string,
	token: string | null = null,
	onError?: (err: GraphqlError | Error) => void
) {
	const service = Thunder(async (query, variables) => {
		let promise = fetchGraphql(url, token, query, variables)
		if (onError) {
			promise = promise.catch(onError)
		}
		const result = await promise
		return result.data
	})

	return service
}
