/* tslint:disable */
/* eslint-disable */

export type ValueTypes = {
    ["AddIDPError"]: AliasType<{
	code?:true,
	developerMessage?:true,
		__typename?: true
}>;
	["AddIDPErrorCode"]:AddIDPErrorCode;
	["AddIDPResponse"]: AliasType<{
	error?:ValueTypes["AddIDPError"],
	ok?:true,
		__typename?: true
}>;
	["AddMailTemplateError"]: AliasType<{
	code?:true,
	developerMessage?:true,
	endUserMessage?:true,
		__typename?: true
}>;
	["AddMailTemplateErrorCode"]:AddMailTemplateErrorCode;
	["AddMailTemplateResponse"]: AliasType<{
	ok?:true,
	errors?:ValueTypes["AddMailTemplateError"],
	error?:ValueTypes["AddMailTemplateError"],
		__typename?: true
}>;
	["AddProjectMemberError"]: AliasType<{
	code?:true,
	membershipValidation?:ValueTypes["MembershipValidationError"],
	developerMessage?:true,
	endUserMessage?:true,
		__typename?: true
}>;
	["AddProjectMemberErrorCode"]:AddProjectMemberErrorCode;
	["AddProjectMemberResponse"]: AliasType<{
	ok?:true,
	errors?:ValueTypes["AddProjectMemberError"],
	error?:ValueTypes["AddProjectMemberError"],
		__typename?: true
}>;
	["ApiKey"]: AliasType<{
	id?:true,
	identity?:ValueTypes["Identity"],
		__typename?: true
}>;
	["ApiKeyWithToken"]: AliasType<{
	id?:true,
	token?:true,
	identity?:ValueTypes["Identity"],
		__typename?: true
}>;
	["ChangeMyPasswordError"]: AliasType<{
	code?:true,
	developerMessage?:true,
		__typename?: true
}>;
	["ChangeMyPasswordErrorCode"]:ChangeMyPasswordErrorCode;
	["ChangeMyPasswordResponse"]: AliasType<{
	ok?:true,
	error?:ValueTypes["ChangeMyPasswordError"],
		__typename?: true
}>;
	["ChangePasswordError"]: AliasType<{
	code?:true,
	developerMessage?:true,
	endUserMessage?:true,
		__typename?: true
}>;
	["ChangePasswordErrorCode"]:ChangePasswordErrorCode;
	["ChangePasswordResponse"]: AliasType<{
	ok?:true,
	errors?:ValueTypes["ChangePasswordError"],
	error?:ValueTypes["ChangePasswordError"],
		__typename?: true
}>;
	["CheckResetPasswordTokenCode"]:CheckResetPasswordTokenCode;
	["CheckResetPasswordTokenResult"]: AliasType<{
	code?:true,
		__typename?: true
}>;
	["CommonSignInResult"]:AliasType<{
		token?:true,
	person?:ValueTypes["Person"];
		['...on CreateSessionTokenResult']?: Omit<ValueTypes["CreateSessionTokenResult"],keyof ValueTypes["CommonSignInResult"]>;
		['...on SignInIDPResult']?: Omit<ValueTypes["SignInIDPResult"],keyof ValueTypes["CommonSignInResult"]>;
		['...on SignInResult']?: Omit<ValueTypes["SignInResult"],keyof ValueTypes["CommonSignInResult"]>;
		__typename?: true
}>;
	["ConfirmOtpError"]: AliasType<{
	code?:true,
	developerMessage?:true,
	endUserMessage?:true,
		__typename?: true
}>;
	["ConfirmOtpErrorCode"]:ConfirmOtpErrorCode;
	["ConfirmOtpResponse"]: AliasType<{
	ok?:true,
	errors?:ValueTypes["ConfirmOtpError"],
	error?:ValueTypes["ConfirmOtpError"],
		__typename?: true
}>;
	["CreateApiKeyError"]: AliasType<{
	code?:true,
	developerMessage?:true,
	membershipValidation?:ValueTypes["MembershipValidationError"],
	endUserMessage?:true,
		__typename?: true
}>;
	["CreateApiKeyErrorCode"]:CreateApiKeyErrorCode;
	["CreateApiKeyResponse"]: AliasType<{
	ok?:true,
	errors?:ValueTypes["CreateApiKeyError"],
	error?:ValueTypes["CreateApiKeyError"],
	result?:ValueTypes["CreateApiKeyResult"],
		__typename?: true
}>;
	["CreateApiKeyResult"]: AliasType<{
	apiKey?:ValueTypes["ApiKeyWithToken"],
		__typename?: true
}>;
	["CreatePasswordResetRequestError"]: AliasType<{
	code?:true,
	developerMessage?:true,
	endUserMessage?:true,
		__typename?: true
}>;
	["CreatePasswordResetRequestErrorCode"]:CreatePasswordResetRequestErrorCode;
	["CreatePasswordResetRequestResponse"]: AliasType<{
	ok?:true,
	errors?:ValueTypes["CreatePasswordResetRequestError"],
	error?:ValueTypes["CreatePasswordResetRequestError"],
		__typename?: true
}>;
	["CreateProjectOptions"]: {
	deployTokenHash?:string,
	noDeployToken?:boolean
};
	["CreateProjectResponse"]: AliasType<{
	ok?:true,
	error?:ValueTypes["CreateProjectResponseError"],
	result?:ValueTypes["CreateProjectResult"],
		__typename?: true
}>;
	["CreateProjectResponseError"]: AliasType<{
	code?:true,
	developerMessage?:true,
		__typename?: true
}>;
	["CreateProjectResponseErrorCode"]:CreateProjectResponseErrorCode;
	["CreateProjectResult"]: AliasType<{
	deployerApiKey?:ValueTypes["ApiKeyWithToken"],
		__typename?: true
}>;
	["CreateResetPasswordRequestOptions"]: {
	mailProject?:string,
	mailVariant?:string
};
	["CreateSessionTokenError"]: AliasType<{
	code?:true,
	developerMessage?:true,
		__typename?: true
}>;
	["CreateSessionTokenErrorCode"]:CreateSessionTokenErrorCode;
	["CreateSessionTokenResponse"]: AliasType<{
	ok?:true,
	error?:ValueTypes["CreateSessionTokenError"],
	result?:ValueTypes["CreateSessionTokenResult"],
		__typename?: true
}>;
	["CreateSessionTokenResult"]: AliasType<{
	token?:true,
	person?:ValueTypes["Person"],
		__typename?: true
}>;
	/** DateTime custom scalar type */
["DateTime"]:unknown;
	["DisableApiKeyError"]: AliasType<{
	code?:true,
	developerMessage?:true,
	endUserMessage?:true,
		__typename?: true
}>;
	["DisableApiKeyErrorCode"]:DisableApiKeyErrorCode;
	["DisableApiKeyResponse"]: AliasType<{
	ok?:true,
	errors?:ValueTypes["DisableApiKeyError"],
	error?:ValueTypes["DisableApiKeyError"],
		__typename?: true
}>;
	["DisableIDPError"]: AliasType<{
	code?:true,
	developerMessage?:true,
		__typename?: true
}>;
	["DisableIDPErrorCode"]:DisableIDPErrorCode;
	["DisableIDPResponse"]: AliasType<{
	error?:ValueTypes["DisableIDPError"],
	ok?:true,
		__typename?: true
}>;
	["DisableOtpError"]: AliasType<{
	code?:true,
	developerMessage?:true,
	endUserMessage?:true,
		__typename?: true
}>;
	["DisableOtpErrorCode"]:DisableOtpErrorCode;
	["DisableOtpResponse"]: AliasType<{
	ok?:true,
	errors?:ValueTypes["DisableOtpError"],
	error?:ValueTypes["DisableOtpError"],
		__typename?: true
}>;
	["EnableIDPError"]: AliasType<{
	code?:true,
	developerMessage?:true,
		__typename?: true
}>;
	["EnableIDPErrorCode"]:EnableIDPErrorCode;
	["EnableIDPResponse"]: AliasType<{
	error?:ValueTypes["EnableIDPError"],
	ok?:true,
		__typename?: true
}>;
	["Identity"]: AliasType<{
	id?:true,
	description?:true,
	person?:ValueTypes["Person"],
	apiKey?:ValueTypes["ApiKey"],
	projects?:ValueTypes["IdentityProjectRelation"],
	permissions?:ValueTypes["IdentityGlobalPermissions"],
	roles?:true,
		__typename?: true
}>;
	["IdentityGlobalPermissions"]: AliasType<{
	canCreateProject?:true,
		__typename?: true
}>;
	["IdentityProjectRelation"]: AliasType<{
	project?:ValueTypes["Project"],
	memberships?:ValueTypes["Membership"],
		__typename?: true
}>;
	["IdentityProvider"]: AliasType<{
	slug?:true,
	type?:true,
	configuration?:true,
	disabledAt?:true,
	options?:ValueTypes["IDPOptionsOutput"],
		__typename?: true
}>;
	["IDPOptions"]: {
	autoSignUp?:boolean,
	exclusive?:boolean
};
	["IDPOptionsOutput"]: AliasType<{
	autoSignUp?:true,
	exclusive?:true,
		__typename?: true
}>;
	["IDPResponseInput"]: {
	url:string
};
	["InitSignInIDPError"]: AliasType<{
	code?:true,
	developerMessage?:true,
	endUserMessage?:true,
		__typename?: true
}>;
	["InitSignInIDPErrorCode"]:InitSignInIDPErrorCode;
	["InitSignInIDPResponse"]: AliasType<{
	ok?:true,
	errors?:ValueTypes["InitSignInIDPError"],
	error?:ValueTypes["InitSignInIDPError"],
	result?:ValueTypes["InitSignInIDPResult"],
		__typename?: true
}>;
	["InitSignInIDPResult"]: AliasType<{
	authUrl?:true,
	sessionData?:true,
		__typename?: true
}>;
	["InviteError"]: AliasType<{
	code?:true,
	developerMessage?:true,
	membershipValidation?:ValueTypes["MembershipValidationError"],
	endUserMessage?:true,
		__typename?: true
}>;
	["InviteErrorCode"]:InviteErrorCode;
	["InviteMethod"]:InviteMethod;
	["InviteOptions"]: {
	method?:ValueTypes["InviteMethod"],
	mailVariant?:string
};
	["InviteResponse"]: AliasType<{
	ok?:true,
	errors?:ValueTypes["InviteError"],
	error?:ValueTypes["InviteError"],
	result?:ValueTypes["InviteResult"],
		__typename?: true
}>;
	["InviteResult"]: AliasType<{
	person?:ValueTypes["Person"],
	isNew?:true,
		__typename?: true
}>;
	/** Json custom scalar type */
["Json"]:unknown;
	["MailTemplate"]: {
	projectSlug?:string,
	type:ValueTypes["MailType"],
	/** Custom mail variant identifier, e.g. a locale. */
	variant?:string,
	subject:string,
	content:string,
	useLayout?:boolean
};
	["MailTemplateIdentifier"]: {
	projectSlug?:string,
	type:ValueTypes["MailType"],
	variant?:string
};
	["MailType"]:MailType;
	["Membership"]: AliasType<{
	role?:true,
	variables?:ValueTypes["VariableEntry"],
		__typename?: true
}>;
	["MembershipInput"]: {
	role:string,
	variables:ValueTypes["VariableEntryInput"][]
};
	["MembershipValidationError"]: AliasType<{
	code?:true,
	role?:true,
	variable?:true,
		__typename?: true
}>;
	["MembershipValidationErrorCode"]:MembershipValidationErrorCode;
	["MemberType"]:MemberType;
	["Mutation"]: AliasType<{
signUp?: [{	email:string,	password?:string,	passwordHash?:string,	roles?:string[],	name?:string},ValueTypes["SignUpResponse"]],
signIn?: [{	email:string,	password:string,	expiration?:number,	otpToken?:string},ValueTypes["SignInResponse"]],
createSessionToken?: [{	email:string,	expiration?:number},ValueTypes["CreateSessionTokenResponse"]],
signOut?: [{	all?:boolean},ValueTypes["SignOutResponse"]],
changePassword?: [{	personId:string,	password:string},ValueTypes["ChangePasswordResponse"]],
changeMyPassword?: [{	currentPassword:string,	newPassword:string},ValueTypes["ChangeMyPasswordResponse"]],
initSignInIDP?: [{	identityProvider:string,	redirectUrl:string},ValueTypes["InitSignInIDPResponse"]],
signInIDP?: [{	identityProvider:string,	idpResponse:ValueTypes["IDPResponseInput"],	redirectUrl:string,	sessionData:ValueTypes["Json"],	expiration?:number},ValueTypes["SignInIDPResponse"]],
addIDP?: [{	identityProvider:string,	type:string,	configuration:ValueTypes["Json"],	options?:ValueTypes["IDPOptions"]},ValueTypes["AddIDPResponse"]],
updateIDP?: [{	identityProvider:string,	configuration?:ValueTypes["Json"],	options?:ValueTypes["IDPOptions"]},ValueTypes["UpdateIDPResponse"]],
disableIDP?: [{	identityProvider:string},ValueTypes["DisableIDPResponse"]],
enableIDP?: [{	identityProvider:string},ValueTypes["EnableIDPResponse"]],
prepareOtp?: [{	label?:string},ValueTypes["PrepareOtpResponse"]],
confirmOtp?: [{	otpToken:string},ValueTypes["ConfirmOtpResponse"]],
	disableOtp?:ValueTypes["DisableOtpResponse"],
createResetPasswordRequest?: [{	email:string,	options?:ValueTypes["CreateResetPasswordRequestOptions"]},ValueTypes["CreatePasswordResetRequestResponse"]],
resetPassword?: [{	token:string,	password:string},ValueTypes["ResetPasswordResponse"]],
invite?: [{	email:string,	name?:string,	projectSlug:string,	memberships:ValueTypes["MembershipInput"][],	options?:ValueTypes["InviteOptions"]},ValueTypes["InviteResponse"]],
unmanagedInvite?: [{	email:string,	name?:string,	projectSlug:string,	memberships:ValueTypes["MembershipInput"][],	options?:ValueTypes["UnmanagedInviteOptions"]},ValueTypes["InviteResponse"]],
addProjectMember?: [{	projectSlug:string,	identityId:string,	memberships:ValueTypes["MembershipInput"][]},ValueTypes["AddProjectMemberResponse"]],
removeProjectMember?: [{	projectSlug:string,	identityId:string},ValueTypes["RemoveProjectMemberResponse"]],
updateProjectMember?: [{	projectSlug:string,	identityId:string,	memberships:ValueTypes["MembershipInput"][]},ValueTypes["UpdateProjectMemberResponse"]],
createApiKey?: [{	projectSlug:string,	memberships:ValueTypes["MembershipInput"][],	description:string,	tokenHash?:string},ValueTypes["CreateApiKeyResponse"]],
createGlobalApiKey?: [{	description:string,	roles?:string[],	tokenHash?:string},ValueTypes["CreateApiKeyResponse"]],
disableApiKey?: [{	id:string},ValueTypes["DisableApiKeyResponse"]],
addMailTemplate?: [{	template:ValueTypes["MailTemplate"]},ValueTypes["AddMailTemplateResponse"]],
removeMailTemplate?: [{	templateIdentifier:ValueTypes["MailTemplateIdentifier"]},ValueTypes["RemoveMailTemplateResponse"]],
createProject?: [{	projectSlug:string,	name?:string,	config?:ValueTypes["Json"],	secrets?:ValueTypes["ProjectSecret"][],	options?:ValueTypes["CreateProjectOptions"]},ValueTypes["CreateProjectResponse"]],
setProjectSecret?: [{	projectSlug:string,	key:string,	value:string},ValueTypes["SetProjectSecretResponse"]],
updateProject?: [{	projectSlug:string,	name?:string,	config?:ValueTypes["Json"],	mergeConfig?:boolean},ValueTypes["UpdateProjectResponse"]],
addProjectMailTemplate?: [{	template:ValueTypes["MailTemplate"]},ValueTypes["AddMailTemplateResponse"]],
removeProjectMailTemplate?: [{	templateIdentifier:ValueTypes["MailTemplateIdentifier"]},ValueTypes["RemoveMailTemplateResponse"]],
		__typename?: true
}>;
	["Person"]: AliasType<{
	id?:true,
	email?:true,
	name?:true,
	otpEnabled?:true,
	identity?:ValueTypes["Identity"],
		__typename?: true
}>;
	["PrepareOtpResponse"]: AliasType<{
	ok?:true,
	result?:ValueTypes["PrepareOtpResult"],
		__typename?: true
}>;
	["PrepareOtpResult"]: AliasType<{
	otpUri?:true,
	otpSecret?:true,
		__typename?: true
}>;
	["Project"]: AliasType<{
	id?:true,
	name?:true,
	slug?:true,
	config?:true,
	roles?:ValueTypes["RoleDefinition"],
members?: [{	memberType?:ValueTypes["MemberType"]},ValueTypes["ProjectIdentityRelation"]],
		__typename?: true
}>;
	["ProjectIdentityRelation"]: AliasType<{
	identity?:ValueTypes["Identity"],
	memberships?:ValueTypes["Membership"],
		__typename?: true
}>;
	["ProjectSecret"]: {
	key:string,
	value:string
};
	["Query"]: AliasType<{
	me?:ValueTypes["Identity"],
	projects?:ValueTypes["Project"],
projectBySlug?: [{	slug:string},ValueTypes["Project"]],
projectMemberships?: [{	projectSlug:string,	identityId:string},ValueTypes["Membership"]],
checkResetPasswordToken?: [{	requestId:string,	token:string},true],
	identityProviders?:ValueTypes["IdentityProvider"],
		__typename?: true
}>;
	["RemoveMailTemplateError"]: AliasType<{
	code?:true,
	developerMessage?:true,
	endUserMessage?:true,
		__typename?: true
}>;
	["RemoveMailTemplateErrorCode"]:RemoveMailTemplateErrorCode;
	["RemoveMailTemplateResponse"]: AliasType<{
	ok?:true,
	errors?:ValueTypes["RemoveMailTemplateError"],
	error?:ValueTypes["RemoveMailTemplateError"],
		__typename?: true
}>;
	["RemoveProjectMemberError"]: AliasType<{
	code?:true,
	developerMessage?:true,
	endUserMessage?:true,
		__typename?: true
}>;
	["RemoveProjectMemberErrorCode"]:RemoveProjectMemberErrorCode;
	["RemoveProjectMemberResponse"]: AliasType<{
	ok?:true,
	errors?:ValueTypes["RemoveProjectMemberError"],
	error?:ValueTypes["RemoveProjectMemberError"],
		__typename?: true
}>;
	["ResetPasswordError"]: AliasType<{
	code?:true,
	developerMessage?:true,
	endUserMessage?:true,
		__typename?: true
}>;
	["ResetPasswordErrorCode"]:ResetPasswordErrorCode;
	["ResetPasswordResponse"]: AliasType<{
	ok?:true,
	errors?:ValueTypes["ResetPasswordError"],
	error?:ValueTypes["ResetPasswordError"],
		__typename?: true
}>;
	["RoleConditionVariableDefinition"]: AliasType<{
	name?:true,
		__typename?: true
}>;
	["RoleDefinition"]: AliasType<{
	name?:true,
	variables?:ValueTypes["RoleVariableDefinition"],
		__typename?: true
}>;
	["RoleEntityVariableDefinition"]: AliasType<{
	name?:true,
	entityName?:true,
		__typename?: true
}>;
	["RolePredefinedVariableDefinition"]: AliasType<{
	name?:true,
	value?:true,
		__typename?: true
}>;
	["RoleVariableDefinition"]:AliasType<{
		name?:true;
		['...on RoleConditionVariableDefinition']?: Omit<ValueTypes["RoleConditionVariableDefinition"],keyof ValueTypes["RoleVariableDefinition"]>;
		['...on RoleEntityVariableDefinition']?: Omit<ValueTypes["RoleEntityVariableDefinition"],keyof ValueTypes["RoleVariableDefinition"]>;
		['...on RolePredefinedVariableDefinition']?: Omit<ValueTypes["RolePredefinedVariableDefinition"],keyof ValueTypes["RoleVariableDefinition"]>;
		__typename?: true
}>;
	["SetProjectSecretResponse"]: AliasType<{
	ok?:true,
		__typename?: true
}>;
	["SignInError"]: AliasType<{
	code?:true,
	developerMessage?:true,
	endUserMessage?:true,
		__typename?: true
}>;
	["SignInErrorCode"]:SignInErrorCode;
	["SignInIDPError"]: AliasType<{
	code?:true,
	developerMessage?:true,
	endUserMessage?:true,
		__typename?: true
}>;
	["SignInIDPErrorCode"]:SignInIDPErrorCode;
	["SignInIDPResponse"]: AliasType<{
	ok?:true,
	errors?:ValueTypes["SignInIDPError"],
	error?:ValueTypes["SignInIDPError"],
	result?:ValueTypes["SignInIDPResult"],
		__typename?: true
}>;
	["SignInIDPResult"]: AliasType<{
	token?:true,
	person?:ValueTypes["Person"],
		__typename?: true
}>;
	["SignInResponse"]: AliasType<{
	ok?:true,
	errors?:ValueTypes["SignInError"],
	error?:ValueTypes["SignInError"],
	result?:ValueTypes["SignInResult"],
		__typename?: true
}>;
	["SignInResult"]: AliasType<{
	token?:true,
	person?:ValueTypes["Person"],
		__typename?: true
}>;
	["SignOutError"]: AliasType<{
	code?:true,
	developerMessage?:true,
	endUserMessage?:true,
		__typename?: true
}>;
	["SignOutErrorCode"]:SignOutErrorCode;
	["SignOutResponse"]: AliasType<{
	ok?:true,
	errors?:ValueTypes["SignOutError"],
	error?:ValueTypes["SignOutError"],
		__typename?: true
}>;
	["SignUpError"]: AliasType<{
	code?:true,
	developerMessage?:true,
	endPersonMessage?:true,
		__typename?: true
}>;
	["SignUpErrorCode"]:SignUpErrorCode;
	["SignUpResponse"]: AliasType<{
	ok?:true,
	errors?:ValueTypes["SignUpError"],
	error?:ValueTypes["SignUpError"],
	result?:ValueTypes["SignUpResult"],
		__typename?: true
}>;
	["SignUpResult"]: AliasType<{
	person?:ValueTypes["Person"],
		__typename?: true
}>;
	["UnmanagedInviteOptions"]: {
	password?:string,
	resetTokenHash?:string
};
	["UpdateIDPError"]: AliasType<{
	code?:true,
	developerMessage?:true,
		__typename?: true
}>;
	["UpdateIDPErrorCode"]:UpdateIDPErrorCode;
	["UpdateIDPResponse"]: AliasType<{
	error?:ValueTypes["UpdateIDPError"],
	ok?:true,
		__typename?: true
}>;
	["UpdateProjectMemberError"]: AliasType<{
	code?:true,
	developerMessage?:true,
	membershipValidation?:ValueTypes["MembershipValidationError"],
	endUserMessage?:true,
		__typename?: true
}>;
	["UpdateProjectMemberErrorCode"]:UpdateProjectMemberErrorCode;
	["UpdateProjectMemberResponse"]: AliasType<{
	ok?:true,
	errors?:ValueTypes["UpdateProjectMemberError"],
	error?:ValueTypes["UpdateProjectMemberError"],
		__typename?: true
}>;
	["UpdateProjectResponse"]: AliasType<{
	ok?:true,
		__typename?: true
}>;
	["VariableEntry"]: AliasType<{
	name?:true,
	values?:true,
		__typename?: true
}>;
	["VariableEntryInput"]: {
	name:string,
	values:string[]
}
  }

export type PartialObjects = {
    ["AddIDPError"]: {
		__typename?: "AddIDPError";
			code?:PartialObjects["AddIDPErrorCode"],
			developerMessage?:string
	},
	["AddIDPErrorCode"]:AddIDPErrorCode,
	["AddIDPResponse"]: {
		__typename?: "AddIDPResponse";
			error?:PartialObjects["AddIDPError"],
			ok?:boolean
	},
	["AddMailTemplateError"]: {
		__typename?: "AddMailTemplateError";
			code?:PartialObjects["AddMailTemplateErrorCode"],
			developerMessage?:string,
			endUserMessage?:string
	},
	["AddMailTemplateErrorCode"]:AddMailTemplateErrorCode,
	["AddMailTemplateResponse"]: {
		__typename?: "AddMailTemplateResponse";
			ok?:boolean,
			errors?:PartialObjects["AddMailTemplateError"][],
			error?:PartialObjects["AddMailTemplateError"]
	},
	["AddProjectMemberError"]: {
		__typename?: "AddProjectMemberError";
			code?:PartialObjects["AddProjectMemberErrorCode"],
			membershipValidation?:PartialObjects["MembershipValidationError"][],
			developerMessage?:string,
			endUserMessage?:string
	},
	["AddProjectMemberErrorCode"]:AddProjectMemberErrorCode,
	["AddProjectMemberResponse"]: {
		__typename?: "AddProjectMemberResponse";
			ok?:boolean,
			errors?:PartialObjects["AddProjectMemberError"][],
			error?:PartialObjects["AddProjectMemberError"]
	},
	["ApiKey"]: {
		__typename?: "ApiKey";
			id?:string,
			identity?:PartialObjects["Identity"]
	},
	["ApiKeyWithToken"]: {
		__typename?: "ApiKeyWithToken";
			id?:string,
			token?:string,
			identity?:PartialObjects["Identity"]
	},
	["ChangeMyPasswordError"]: {
		__typename?: "ChangeMyPasswordError";
			code?:PartialObjects["ChangeMyPasswordErrorCode"],
			developerMessage?:string
	},
	["ChangeMyPasswordErrorCode"]:ChangeMyPasswordErrorCode,
	["ChangeMyPasswordResponse"]: {
		__typename?: "ChangeMyPasswordResponse";
			ok?:boolean,
			error?:PartialObjects["ChangeMyPasswordError"]
	},
	["ChangePasswordError"]: {
		__typename?: "ChangePasswordError";
			code?:PartialObjects["ChangePasswordErrorCode"],
			developerMessage?:string,
			endUserMessage?:string
	},
	["ChangePasswordErrorCode"]:ChangePasswordErrorCode,
	["ChangePasswordResponse"]: {
		__typename?: "ChangePasswordResponse";
			ok?:boolean,
			errors?:PartialObjects["ChangePasswordError"][],
			error?:PartialObjects["ChangePasswordError"]
	},
	["CheckResetPasswordTokenCode"]:CheckResetPasswordTokenCode,
	["CheckResetPasswordTokenResult"]: {
		__typename?: "CheckResetPasswordTokenResult";
			code?:PartialObjects["CheckResetPasswordTokenCode"]
	},
	["CommonSignInResult"]:{
		token?:string;
	person?:PartialObjects["Person"]
} & (PartialObjects["CreateSessionTokenResult"] | PartialObjects["SignInIDPResult"] | PartialObjects["SignInResult"]),
	["ConfirmOtpError"]: {
		__typename?: "ConfirmOtpError";
			code?:PartialObjects["ConfirmOtpErrorCode"],
			developerMessage?:string,
			endUserMessage?:string
	},
	["ConfirmOtpErrorCode"]:ConfirmOtpErrorCode,
	["ConfirmOtpResponse"]: {
		__typename?: "ConfirmOtpResponse";
			ok?:boolean,
			errors?:PartialObjects["ConfirmOtpError"][],
			error?:PartialObjects["ConfirmOtpError"]
	},
	["CreateApiKeyError"]: {
		__typename?: "CreateApiKeyError";
			code?:PartialObjects["CreateApiKeyErrorCode"],
			developerMessage?:string,
			membershipValidation?:PartialObjects["MembershipValidationError"][],
			endUserMessage?:string
	},
	["CreateApiKeyErrorCode"]:CreateApiKeyErrorCode,
	["CreateApiKeyResponse"]: {
		__typename?: "CreateApiKeyResponse";
			ok?:boolean,
			errors?:PartialObjects["CreateApiKeyError"][],
			error?:PartialObjects["CreateApiKeyError"],
			result?:PartialObjects["CreateApiKeyResult"]
	},
	["CreateApiKeyResult"]: {
		__typename?: "CreateApiKeyResult";
			apiKey?:PartialObjects["ApiKeyWithToken"]
	},
	["CreatePasswordResetRequestError"]: {
		__typename?: "CreatePasswordResetRequestError";
			code?:PartialObjects["CreatePasswordResetRequestErrorCode"],
			developerMessage?:string,
			endUserMessage?:string
	},
	["CreatePasswordResetRequestErrorCode"]:CreatePasswordResetRequestErrorCode,
	["CreatePasswordResetRequestResponse"]: {
		__typename?: "CreatePasswordResetRequestResponse";
			ok?:boolean,
			errors?:PartialObjects["CreatePasswordResetRequestError"][],
			error?:PartialObjects["CreatePasswordResetRequestError"]
	},
	["CreateProjectOptions"]: {
	deployTokenHash?:string,
	noDeployToken?:boolean
},
	["CreateProjectResponse"]: {
		__typename?: "CreateProjectResponse";
			ok?:boolean,
			error?:PartialObjects["CreateProjectResponseError"],
			result?:PartialObjects["CreateProjectResult"]
	},
	["CreateProjectResponseError"]: {
		__typename?: "CreateProjectResponseError";
			code?:PartialObjects["CreateProjectResponseErrorCode"],
			developerMessage?:string
	},
	["CreateProjectResponseErrorCode"]:CreateProjectResponseErrorCode,
	["CreateProjectResult"]: {
		__typename?: "CreateProjectResult";
			deployerApiKey?:PartialObjects["ApiKeyWithToken"]
	},
	["CreateResetPasswordRequestOptions"]: {
	mailProject?:string,
	mailVariant?:string
},
	["CreateSessionTokenError"]: {
		__typename?: "CreateSessionTokenError";
			code?:PartialObjects["CreateSessionTokenErrorCode"],
			developerMessage?:string
	},
	["CreateSessionTokenErrorCode"]:CreateSessionTokenErrorCode,
	["CreateSessionTokenResponse"]: {
		__typename?: "CreateSessionTokenResponse";
			ok?:boolean,
			error?:PartialObjects["CreateSessionTokenError"],
			result?:PartialObjects["CreateSessionTokenResult"]
	},
	["CreateSessionTokenResult"]: {
		__typename?: "CreateSessionTokenResult";
			token?:string,
			person?:PartialObjects["Person"]
	},
	/** DateTime custom scalar type */
["DateTime"]:any,
	["DisableApiKeyError"]: {
		__typename?: "DisableApiKeyError";
			code?:PartialObjects["DisableApiKeyErrorCode"],
			developerMessage?:string,
			endUserMessage?:string
	},
	["DisableApiKeyErrorCode"]:DisableApiKeyErrorCode,
	["DisableApiKeyResponse"]: {
		__typename?: "DisableApiKeyResponse";
			ok?:boolean,
			errors?:PartialObjects["DisableApiKeyError"][],
			error?:PartialObjects["DisableApiKeyError"]
	},
	["DisableIDPError"]: {
		__typename?: "DisableIDPError";
			code?:PartialObjects["DisableIDPErrorCode"],
			developerMessage?:string
	},
	["DisableIDPErrorCode"]:DisableIDPErrorCode,
	["DisableIDPResponse"]: {
		__typename?: "DisableIDPResponse";
			error?:PartialObjects["DisableIDPError"],
			ok?:boolean
	},
	["DisableOtpError"]: {
		__typename?: "DisableOtpError";
			code?:PartialObjects["DisableOtpErrorCode"],
			developerMessage?:string,
			endUserMessage?:string
	},
	["DisableOtpErrorCode"]:DisableOtpErrorCode,
	["DisableOtpResponse"]: {
		__typename?: "DisableOtpResponse";
			ok?:boolean,
			errors?:PartialObjects["DisableOtpError"][],
			error?:PartialObjects["DisableOtpError"]
	},
	["EnableIDPError"]: {
		__typename?: "EnableIDPError";
			code?:PartialObjects["EnableIDPErrorCode"],
			developerMessage?:string
	},
	["EnableIDPErrorCode"]:EnableIDPErrorCode,
	["EnableIDPResponse"]: {
		__typename?: "EnableIDPResponse";
			error?:PartialObjects["EnableIDPError"],
			ok?:boolean
	},
	["Identity"]: {
		__typename?: "Identity";
			id?:string,
			description?:string,
			person?:PartialObjects["Person"],
			apiKey?:PartialObjects["ApiKey"],
			projects?:PartialObjects["IdentityProjectRelation"][],
			permissions?:PartialObjects["IdentityGlobalPermissions"],
			roles?:string[]
	},
	["IdentityGlobalPermissions"]: {
		__typename?: "IdentityGlobalPermissions";
			canCreateProject?:boolean
	},
	["IdentityProjectRelation"]: {
		__typename?: "IdentityProjectRelation";
			project?:PartialObjects["Project"],
			memberships?:PartialObjects["Membership"][]
	},
	["IdentityProvider"]: {
		__typename?: "IdentityProvider";
			slug?:string,
			type?:string,
			configuration?:PartialObjects["Json"],
			disabledAt?:PartialObjects["DateTime"],
			options?:PartialObjects["IDPOptionsOutput"]
	},
	["IDPOptions"]: {
	autoSignUp?:boolean,
	exclusive?:boolean
},
	["IDPOptionsOutput"]: {
		__typename?: "IDPOptionsOutput";
			autoSignUp?:boolean,
			exclusive?:boolean
	},
	["IDPResponseInput"]: {
	url:string
},
	["InitSignInIDPError"]: {
		__typename?: "InitSignInIDPError";
			code?:PartialObjects["InitSignInIDPErrorCode"],
			developerMessage?:string,
			endUserMessage?:string
	},
	["InitSignInIDPErrorCode"]:InitSignInIDPErrorCode,
	["InitSignInIDPResponse"]: {
		__typename?: "InitSignInIDPResponse";
			ok?:boolean,
			errors?:PartialObjects["InitSignInIDPError"][],
			error?:PartialObjects["InitSignInIDPError"],
			result?:PartialObjects["InitSignInIDPResult"]
	},
	["InitSignInIDPResult"]: {
		__typename?: "InitSignInIDPResult";
			authUrl?:string,
			sessionData?:PartialObjects["Json"]
	},
	["InviteError"]: {
		__typename?: "InviteError";
			code?:PartialObjects["InviteErrorCode"],
			developerMessage?:string,
			membershipValidation?:PartialObjects["MembershipValidationError"][],
			endUserMessage?:string
	},
	["InviteErrorCode"]:InviteErrorCode,
	["InviteMethod"]:InviteMethod,
	["InviteOptions"]: {
	method?:PartialObjects["InviteMethod"],
	mailVariant?:string
},
	["InviteResponse"]: {
		__typename?: "InviteResponse";
			ok?:boolean,
			errors?:PartialObjects["InviteError"][],
			error?:PartialObjects["InviteError"],
			result?:PartialObjects["InviteResult"]
	},
	["InviteResult"]: {
		__typename?: "InviteResult";
			person?:PartialObjects["Person"],
			isNew?:boolean
	},
	/** Json custom scalar type */
["Json"]:any,
	["MailTemplate"]: {
	projectSlug?:string,
	type:PartialObjects["MailType"],
	/** Custom mail variant identifier, e.g. a locale. */
	variant?:string,
	subject:string,
	content:string,
	useLayout?:boolean
},
	["MailTemplateIdentifier"]: {
	projectSlug?:string,
	type:PartialObjects["MailType"],
	variant?:string
},
	["MailType"]:MailType,
	["Membership"]: {
		__typename?: "Membership";
			role?:string,
			variables?:PartialObjects["VariableEntry"][]
	},
	["MembershipInput"]: {
	role:string,
	variables:PartialObjects["VariableEntryInput"][]
},
	["MembershipValidationError"]: {
		__typename?: "MembershipValidationError";
			code?:PartialObjects["MembershipValidationErrorCode"],
			role?:string,
			variable?:string
	},
	["MembershipValidationErrorCode"]:MembershipValidationErrorCode,
	["MemberType"]:MemberType,
	["Mutation"]: {
		__typename?: "Mutation";
			signUp?:PartialObjects["SignUpResponse"],
			signIn?:PartialObjects["SignInResponse"],
			createSessionToken?:PartialObjects["CreateSessionTokenResponse"],
			signOut?:PartialObjects["SignOutResponse"],
			changePassword?:PartialObjects["ChangePasswordResponse"],
			changeMyPassword?:PartialObjects["ChangeMyPasswordResponse"],
			initSignInIDP?:PartialObjects["InitSignInIDPResponse"],
			signInIDP?:PartialObjects["SignInIDPResponse"],
			addIDP?:PartialObjects["AddIDPResponse"],
			updateIDP?:PartialObjects["UpdateIDPResponse"],
			disableIDP?:PartialObjects["DisableIDPResponse"],
			enableIDP?:PartialObjects["EnableIDPResponse"],
			prepareOtp?:PartialObjects["PrepareOtpResponse"],
			confirmOtp?:PartialObjects["ConfirmOtpResponse"],
			disableOtp?:PartialObjects["DisableOtpResponse"],
			createResetPasswordRequest?:PartialObjects["CreatePasswordResetRequestResponse"],
			resetPassword?:PartialObjects["ResetPasswordResponse"],
			invite?:PartialObjects["InviteResponse"],
			unmanagedInvite?:PartialObjects["InviteResponse"],
			addProjectMember?:PartialObjects["AddProjectMemberResponse"],
			removeProjectMember?:PartialObjects["RemoveProjectMemberResponse"],
			updateProjectMember?:PartialObjects["UpdateProjectMemberResponse"],
			createApiKey?:PartialObjects["CreateApiKeyResponse"],
			createGlobalApiKey?:PartialObjects["CreateApiKeyResponse"],
			disableApiKey?:PartialObjects["DisableApiKeyResponse"],
			addMailTemplate?:PartialObjects["AddMailTemplateResponse"],
			removeMailTemplate?:PartialObjects["RemoveMailTemplateResponse"],
			createProject?:PartialObjects["CreateProjectResponse"],
			setProjectSecret?:PartialObjects["SetProjectSecretResponse"],
			updateProject?:PartialObjects["UpdateProjectResponse"],
			addProjectMailTemplate?:PartialObjects["AddMailTemplateResponse"],
			removeProjectMailTemplate?:PartialObjects["RemoveMailTemplateResponse"]
	},
	["Person"]: {
		__typename?: "Person";
			id?:string,
			email?:string,
			name?:string,
			otpEnabled?:boolean,
			identity?:PartialObjects["Identity"]
	},
	["PrepareOtpResponse"]: {
		__typename?: "PrepareOtpResponse";
			ok?:boolean,
			result?:PartialObjects["PrepareOtpResult"]
	},
	["PrepareOtpResult"]: {
		__typename?: "PrepareOtpResult";
			otpUri?:string,
			otpSecret?:string
	},
	["Project"]: {
		__typename?: "Project";
			id?:string,
			name?:string,
			slug?:string,
			config?:PartialObjects["Json"],
			roles?:PartialObjects["RoleDefinition"][],
			members?:PartialObjects["ProjectIdentityRelation"][]
	},
	["ProjectIdentityRelation"]: {
		__typename?: "ProjectIdentityRelation";
			identity?:PartialObjects["Identity"],
			memberships?:PartialObjects["Membership"][]
	},
	["ProjectSecret"]: {
	key:string,
	value:string
},
	["Query"]: {
		__typename?: "Query";
			me?:PartialObjects["Identity"],
			projects?:PartialObjects["Project"][],
			projectBySlug?:PartialObjects["Project"],
			projectMemberships?:PartialObjects["Membership"][],
			checkResetPasswordToken?:PartialObjects["CheckResetPasswordTokenCode"],
			identityProviders?:PartialObjects["IdentityProvider"][]
	},
	["RemoveMailTemplateError"]: {
		__typename?: "RemoveMailTemplateError";
			code?:PartialObjects["RemoveMailTemplateErrorCode"],
			developerMessage?:string,
			endUserMessage?:string
	},
	["RemoveMailTemplateErrorCode"]:RemoveMailTemplateErrorCode,
	["RemoveMailTemplateResponse"]: {
		__typename?: "RemoveMailTemplateResponse";
			ok?:boolean,
			errors?:PartialObjects["RemoveMailTemplateError"][],
			error?:PartialObjects["RemoveMailTemplateError"]
	},
	["RemoveProjectMemberError"]: {
		__typename?: "RemoveProjectMemberError";
			code?:PartialObjects["RemoveProjectMemberErrorCode"],
			developerMessage?:string,
			endUserMessage?:string
	},
	["RemoveProjectMemberErrorCode"]:RemoveProjectMemberErrorCode,
	["RemoveProjectMemberResponse"]: {
		__typename?: "RemoveProjectMemberResponse";
			ok?:boolean,
			errors?:PartialObjects["RemoveProjectMemberError"][],
			error?:PartialObjects["RemoveProjectMemberError"]
	},
	["ResetPasswordError"]: {
		__typename?: "ResetPasswordError";
			code?:PartialObjects["ResetPasswordErrorCode"],
			developerMessage?:string,
			endUserMessage?:string
	},
	["ResetPasswordErrorCode"]:ResetPasswordErrorCode,
	["ResetPasswordResponse"]: {
		__typename?: "ResetPasswordResponse";
			ok?:boolean,
			errors?:PartialObjects["ResetPasswordError"][],
			error?:PartialObjects["ResetPasswordError"]
	},
	["RoleConditionVariableDefinition"]: {
		__typename?: "RoleConditionVariableDefinition";
			name?:string
	},
	["RoleDefinition"]: {
		__typename?: "RoleDefinition";
			name?:string,
			variables?:PartialObjects["RoleVariableDefinition"][]
	},
	["RoleEntityVariableDefinition"]: {
		__typename?: "RoleEntityVariableDefinition";
			name?:string,
			entityName?:string
	},
	["RolePredefinedVariableDefinition"]: {
		__typename?: "RolePredefinedVariableDefinition";
			name?:string,
			value?:string
	},
	["RoleVariableDefinition"]:{
		name?:string
} & (PartialObjects["RoleConditionVariableDefinition"] | PartialObjects["RoleEntityVariableDefinition"] | PartialObjects["RolePredefinedVariableDefinition"]),
	["SetProjectSecretResponse"]: {
		__typename?: "SetProjectSecretResponse";
			ok?:boolean
	},
	["SignInError"]: {
		__typename?: "SignInError";
			code?:PartialObjects["SignInErrorCode"],
			developerMessage?:string,
			endUserMessage?:string
	},
	["SignInErrorCode"]:SignInErrorCode,
	["SignInIDPError"]: {
		__typename?: "SignInIDPError";
			code?:PartialObjects["SignInIDPErrorCode"],
			developerMessage?:string,
			endUserMessage?:string
	},
	["SignInIDPErrorCode"]:SignInIDPErrorCode,
	["SignInIDPResponse"]: {
		__typename?: "SignInIDPResponse";
			ok?:boolean,
			errors?:PartialObjects["SignInIDPError"][],
			error?:PartialObjects["SignInIDPError"],
			result?:PartialObjects["SignInIDPResult"]
	},
	["SignInIDPResult"]: {
		__typename?: "SignInIDPResult";
			token?:string,
			person?:PartialObjects["Person"]
	},
	["SignInResponse"]: {
		__typename?: "SignInResponse";
			ok?:boolean,
			errors?:PartialObjects["SignInError"][],
			error?:PartialObjects["SignInError"],
			result?:PartialObjects["SignInResult"]
	},
	["SignInResult"]: {
		__typename?: "SignInResult";
			token?:string,
			person?:PartialObjects["Person"]
	},
	["SignOutError"]: {
		__typename?: "SignOutError";
			code?:PartialObjects["SignOutErrorCode"],
			developerMessage?:string,
			endUserMessage?:string
	},
	["SignOutErrorCode"]:SignOutErrorCode,
	["SignOutResponse"]: {
		__typename?: "SignOutResponse";
			ok?:boolean,
			errors?:PartialObjects["SignOutError"][],
			error?:PartialObjects["SignOutError"]
	},
	["SignUpError"]: {
		__typename?: "SignUpError";
			code?:PartialObjects["SignUpErrorCode"],
			developerMessage?:string,
			endPersonMessage?:string
	},
	["SignUpErrorCode"]:SignUpErrorCode,
	["SignUpResponse"]: {
		__typename?: "SignUpResponse";
			ok?:boolean,
			errors?:PartialObjects["SignUpError"][],
			error?:PartialObjects["SignUpError"],
			result?:PartialObjects["SignUpResult"]
	},
	["SignUpResult"]: {
		__typename?: "SignUpResult";
			person?:PartialObjects["Person"]
	},
	["UnmanagedInviteOptions"]: {
	password?:string,
	resetTokenHash?:string
},
	["UpdateIDPError"]: {
		__typename?: "UpdateIDPError";
			code?:PartialObjects["UpdateIDPErrorCode"],
			developerMessage?:string
	},
	["UpdateIDPErrorCode"]:UpdateIDPErrorCode,
	["UpdateIDPResponse"]: {
		__typename?: "UpdateIDPResponse";
			error?:PartialObjects["UpdateIDPError"],
			ok?:boolean
	},
	["UpdateProjectMemberError"]: {
		__typename?: "UpdateProjectMemberError";
			code?:PartialObjects["UpdateProjectMemberErrorCode"],
			developerMessage?:string,
			membershipValidation?:PartialObjects["MembershipValidationError"][],
			endUserMessage?:string
	},
	["UpdateProjectMemberErrorCode"]:UpdateProjectMemberErrorCode,
	["UpdateProjectMemberResponse"]: {
		__typename?: "UpdateProjectMemberResponse";
			ok?:boolean,
			errors?:PartialObjects["UpdateProjectMemberError"][],
			error?:PartialObjects["UpdateProjectMemberError"]
	},
	["UpdateProjectResponse"]: {
		__typename?: "UpdateProjectResponse";
			ok?:boolean
	},
	["VariableEntry"]: {
		__typename?: "VariableEntry";
			name?:string,
			values?:string[]
	},
	["VariableEntryInput"]: {
	name:string,
	values:string[]
}
  }



export type AddIDPError = {
	__typename?: "AddIDPError",
	code:AddIDPErrorCode,
	developerMessage:string
}

export enum AddIDPErrorCode {
	ALREADY_EXISTS = "ALREADY_EXISTS",
	UNKNOWN_TYPE = "UNKNOWN_TYPE",
	INVALID_CONFIGURATION = "INVALID_CONFIGURATION"
}

export type AddIDPResponse = {
	__typename?: "AddIDPResponse",
	error?:AddIDPError,
	ok:boolean
}

export type AddMailTemplateError = {
	__typename?: "AddMailTemplateError",
	code:AddMailTemplateErrorCode,
	developerMessage:string,
	endUserMessage?:string
}

export enum AddMailTemplateErrorCode {
	MISSING_VARIABLE = "MISSING_VARIABLE",
	PROJECT_NOT_FOUND = "PROJECT_NOT_FOUND"
}

export type AddMailTemplateResponse = {
	__typename?: "AddMailTemplateResponse",
	ok:boolean,
	errors:AddMailTemplateError[],
	error?:AddMailTemplateError
}

export type AddProjectMemberError = {
	__typename?: "AddProjectMemberError",
	code:AddProjectMemberErrorCode,
	membershipValidation?:MembershipValidationError[],
	developerMessage:string,
	endUserMessage?:string
}

export enum AddProjectMemberErrorCode {
	PROJECT_NOT_FOUND = "PROJECT_NOT_FOUND",
	IDENTITY_NOT_FOUND = "IDENTITY_NOT_FOUND",
	ALREADY_MEMBER = "ALREADY_MEMBER",
	INVALID_MEMBERSHIP = "INVALID_MEMBERSHIP",
	ROLE_NOT_FOUND = "ROLE_NOT_FOUND",
	VARIABLE_EMPTY = "VARIABLE_EMPTY",
	VARIABLE_NOT_FOUND = "VARIABLE_NOT_FOUND"
}

export type AddProjectMemberResponse = {
	__typename?: "AddProjectMemberResponse",
	ok:boolean,
	errors:AddProjectMemberError[],
	error?:AddProjectMemberError
}

export type ApiKey = {
	__typename?: "ApiKey",
	id:string,
	identity:Identity
}

export type ApiKeyWithToken = {
	__typename?: "ApiKeyWithToken",
	id:string,
	token?:string,
	identity:Identity
}

export type ChangeMyPasswordError = {
	__typename?: "ChangeMyPasswordError",
	code:ChangeMyPasswordErrorCode,
	developerMessage:string
}

export enum ChangeMyPasswordErrorCode {
	TOO_WEAK = "TOO_WEAK",
	NOT_A_PERSON = "NOT_A_PERSON",
	INVALID_PASSWORD = "INVALID_PASSWORD",
	NO_PASSWORD_SET = "NO_PASSWORD_SET"
}

export type ChangeMyPasswordResponse = {
	__typename?: "ChangeMyPasswordResponse",
	ok:boolean,
	error?:ChangeMyPasswordError
}

export type ChangePasswordError = {
	__typename?: "ChangePasswordError",
	code:ChangePasswordErrorCode,
	developerMessage:string,
	endUserMessage?:string
}

export enum ChangePasswordErrorCode {
	PERSON_NOT_FOUND = "PERSON_NOT_FOUND",
	TOO_WEAK = "TOO_WEAK"
}

export type ChangePasswordResponse = {
	__typename?: "ChangePasswordResponse",
	ok:boolean,
	errors:ChangePasswordError[],
	error?:ChangePasswordError
}

export enum CheckResetPasswordTokenCode {
	REQUEST_NOT_FOUND = "REQUEST_NOT_FOUND",
	TOKEN_NOT_FOUND = "TOKEN_NOT_FOUND",
	TOKEN_USED = "TOKEN_USED",
	TOKEN_EXPIRED = "TOKEN_EXPIRED"
}

export type CheckResetPasswordTokenResult = {
	__typename?: "CheckResetPasswordTokenResult",
	code:CheckResetPasswordTokenCode
}

export type CommonSignInResult = {
	__interface:{
			token:string,
	person:Person
	};
	__resolve:{
		['...on CreateSessionTokenResult']: CreateSessionTokenResult;
		['...on SignInIDPResult']: SignInIDPResult;
		['...on SignInResult']: SignInResult;
	}
}

export type ConfirmOtpError = {
	__typename?: "ConfirmOtpError",
	code:ConfirmOtpErrorCode,
	developerMessage:string,
	endUserMessage?:string
}

export enum ConfirmOtpErrorCode {
	INVALID_OTP_TOKEN = "INVALID_OTP_TOKEN",
	NOT_PREPARED = "NOT_PREPARED"
}

export type ConfirmOtpResponse = {
	__typename?: "ConfirmOtpResponse",
	ok:boolean,
	errors:ConfirmOtpError[],
	error?:ConfirmOtpError
}

export type CreateApiKeyError = {
	__typename?: "CreateApiKeyError",
	code:CreateApiKeyErrorCode,
	developerMessage:string,
	membershipValidation?:MembershipValidationError[],
	endUserMessage?:string
}

export enum CreateApiKeyErrorCode {
	PROJECT_NOT_FOUND = "PROJECT_NOT_FOUND",
	INVALID_MEMBERSHIP = "INVALID_MEMBERSHIP",
	VARIABLE_NOT_FOUND = "VARIABLE_NOT_FOUND",
	ROLE_NOT_FOUND = "ROLE_NOT_FOUND",
	VARIABLE_EMPTY = "VARIABLE_EMPTY"
}

export type CreateApiKeyResponse = {
	__typename?: "CreateApiKeyResponse",
	ok:boolean,
	errors:CreateApiKeyError[],
	error?:CreateApiKeyError,
	result?:CreateApiKeyResult
}

export type CreateApiKeyResult = {
	__typename?: "CreateApiKeyResult",
	apiKey:ApiKeyWithToken
}

export type CreatePasswordResetRequestError = {
	__typename?: "CreatePasswordResetRequestError",
	code:CreatePasswordResetRequestErrorCode,
	developerMessage:string,
	endUserMessage?:string
}

export enum CreatePasswordResetRequestErrorCode {
	PERSON_NOT_FOUND = "PERSON_NOT_FOUND"
}

export type CreatePasswordResetRequestResponse = {
	__typename?: "CreatePasswordResetRequestResponse",
	ok:boolean,
	errors:CreatePasswordResetRequestError[],
	error?:CreatePasswordResetRequestError
}

export type CreateProjectOptions = {
		deployTokenHash?:string,
	noDeployToken?:boolean
}

export type CreateProjectResponse = {
	__typename?: "CreateProjectResponse",
	ok:boolean,
	error?:CreateProjectResponseError,
	result?:CreateProjectResult
}

export type CreateProjectResponseError = {
	__typename?: "CreateProjectResponseError",
	code:CreateProjectResponseErrorCode,
	developerMessage:string
}

export enum CreateProjectResponseErrorCode {
	ALREADY_EXISTS = "ALREADY_EXISTS",
	INIT_ERROR = "INIT_ERROR"
}

export type CreateProjectResult = {
	__typename?: "CreateProjectResult",
	deployerApiKey?:ApiKeyWithToken
}

export type CreateResetPasswordRequestOptions = {
		mailProject?:string,
	mailVariant?:string
}

export type CreateSessionTokenError = {
	__typename?: "CreateSessionTokenError",
	code:CreateSessionTokenErrorCode,
	developerMessage:string
}

export enum CreateSessionTokenErrorCode {
	UNKNOWN_EMAIL = "UNKNOWN_EMAIL"
}

export type CreateSessionTokenResponse = {
	__typename?: "CreateSessionTokenResponse",
	ok:boolean,
	error?:CreateSessionTokenError,
	result?:CreateSessionTokenResult
}

export type CreateSessionTokenResult = {
	__typename?: "CreateSessionTokenResult",
	token:string,
	person:Person
}

/** DateTime custom scalar type */
export type DateTime = any

export type DisableApiKeyError = {
	__typename?: "DisableApiKeyError",
	code:DisableApiKeyErrorCode,
	developerMessage:string,
	endUserMessage?:string
}

export enum DisableApiKeyErrorCode {
	KEY_NOT_FOUND = "KEY_NOT_FOUND"
}

export type DisableApiKeyResponse = {
	__typename?: "DisableApiKeyResponse",
	ok:boolean,
	errors:DisableApiKeyError[],
	error?:DisableApiKeyError
}

export type DisableIDPError = {
	__typename?: "DisableIDPError",
	code:DisableIDPErrorCode,
	developerMessage:string
}

export enum DisableIDPErrorCode {
	NOT_FOUND = "NOT_FOUND"
}

export type DisableIDPResponse = {
	__typename?: "DisableIDPResponse",
	error?:DisableIDPError,
	ok:boolean
}

export type DisableOtpError = {
	__typename?: "DisableOtpError",
	code:DisableOtpErrorCode,
	developerMessage:string,
	endUserMessage?:string
}

export enum DisableOtpErrorCode {
	OTP_NOT_ACTIVE = "OTP_NOT_ACTIVE"
}

export type DisableOtpResponse = {
	__typename?: "DisableOtpResponse",
	ok:boolean,
	errors:DisableOtpError[],
	error?:DisableOtpError
}

export type EnableIDPError = {
	__typename?: "EnableIDPError",
	code:EnableIDPErrorCode,
	developerMessage:string
}

export enum EnableIDPErrorCode {
	NOT_FOUND = "NOT_FOUND"
}

export type EnableIDPResponse = {
	__typename?: "EnableIDPResponse",
	error?:EnableIDPError,
	ok:boolean
}

export type Identity = {
	__typename?: "Identity",
	id:string,
	description?:string,
	person?:Person,
	apiKey?:ApiKey,
	projects:IdentityProjectRelation[],
	permissions?:IdentityGlobalPermissions,
	roles?:string[]
}

export type IdentityGlobalPermissions = {
	__typename?: "IdentityGlobalPermissions",
	canCreateProject:boolean
}

export type IdentityProjectRelation = {
	__typename?: "IdentityProjectRelation",
	project:Project,
	memberships:Membership[]
}

export type IdentityProvider = {
	__typename?: "IdentityProvider",
	slug:string,
	type:string,
	configuration:Json,
	disabledAt:DateTime,
	options:IDPOptionsOutput
}

export type IDPOptions = {
		autoSignUp?:boolean,
	exclusive?:boolean
}

export type IDPOptionsOutput = {
	__typename?: "IDPOptionsOutput",
	autoSignUp:boolean,
	exclusive:boolean
}

export type IDPResponseInput = {
		url:string
}

export type InitSignInIDPError = {
	__typename?: "InitSignInIDPError",
	code:InitSignInIDPErrorCode,
	developerMessage:string,
	endUserMessage?:string
}

export enum InitSignInIDPErrorCode {
	PROVIDER_NOT_FOUND = "PROVIDER_NOT_FOUND"
}

export type InitSignInIDPResponse = {
	__typename?: "InitSignInIDPResponse",
	ok:boolean,
	errors:InitSignInIDPError[],
	error?:InitSignInIDPError,
	result?:InitSignInIDPResult
}

export type InitSignInIDPResult = {
	__typename?: "InitSignInIDPResult",
	authUrl:string,
	sessionData:Json
}

export type InviteError = {
	__typename?: "InviteError",
	code:InviteErrorCode,
	developerMessage:string,
	membershipValidation?:MembershipValidationError[],
	endUserMessage?:string
}

export enum InviteErrorCode {
	PROJECT_NOT_FOUND = "PROJECT_NOT_FOUND",
	ALREADY_MEMBER = "ALREADY_MEMBER",
	INVALID_MEMBERSHIP = "INVALID_MEMBERSHIP",
	ROLE_NOT_FOUND = "ROLE_NOT_FOUND",
	VARIABLE_NOT_FOUND = "VARIABLE_NOT_FOUND",
	VARIABLE_EMPTY = "VARIABLE_EMPTY"
}

export enum InviteMethod {
	CREATE_PASSWORD = "CREATE_PASSWORD",
	RESET_PASSWORD = "RESET_PASSWORD"
}

export type InviteOptions = {
		method?:InviteMethod,
	mailVariant?:string
}

export type InviteResponse = {
	__typename?: "InviteResponse",
	ok:boolean,
	errors:InviteError[],
	error?:InviteError,
	result?:InviteResult
}

export type InviteResult = {
	__typename?: "InviteResult",
	person:Person,
	isNew:boolean
}

/** Json custom scalar type */
export type Json = any

export type MailTemplate = {
		projectSlug?:string,
	type:MailType,
	/** Custom mail variant identifier, e.g. a locale. */
	variant?:string,
	subject:string,
	content:string,
	useLayout?:boolean
}

export type MailTemplateIdentifier = {
		projectSlug?:string,
	type:MailType,
	variant?:string
}

export enum MailType {
	EXISTING_USER_INVITED = "EXISTING_USER_INVITED",
	NEW_USER_INVITED = "NEW_USER_INVITED",
	RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST"
}

export type Membership = {
	__typename?: "Membership",
	role:string,
	variables:VariableEntry[]
}

export type MembershipInput = {
		role:string,
	variables:VariableEntryInput[]
}

export type MembershipValidationError = {
	__typename?: "MembershipValidationError",
	code:MembershipValidationErrorCode,
	role:string,
	variable?:string
}

export enum MembershipValidationErrorCode {
	ROLE_NOT_FOUND = "ROLE_NOT_FOUND",
	VARIABLE_NOT_FOUND = "VARIABLE_NOT_FOUND",
	VARIABLE_EMPTY = "VARIABLE_EMPTY",
	VARIABLE_INVALID = "VARIABLE_INVALID"
}

export enum MemberType {
	API_KEY = "API_KEY",
	PERSON = "PERSON"
}

export type Mutation = {
	__typename?: "Mutation",
	signUp?:SignUpResponse,
	signIn?:SignInResponse,
	createSessionToken?:CreateSessionTokenResponse,
	signOut?:SignOutResponse,
	changePassword?:ChangePasswordResponse,
	changeMyPassword?:ChangeMyPasswordResponse,
	initSignInIDP?:InitSignInIDPResponse,
	signInIDP?:SignInIDPResponse,
	addIDP?:AddIDPResponse,
	updateIDP?:UpdateIDPResponse,
	disableIDP?:DisableIDPResponse,
	enableIDP?:EnableIDPResponse,
	prepareOtp?:PrepareOtpResponse,
	confirmOtp?:ConfirmOtpResponse,
	disableOtp?:DisableOtpResponse,
	createResetPasswordRequest?:CreatePasswordResetRequestResponse,
	resetPassword?:ResetPasswordResponse,
	invite?:InviteResponse,
	unmanagedInvite?:InviteResponse,
	addProjectMember?:AddProjectMemberResponse,
	removeProjectMember?:RemoveProjectMemberResponse,
	updateProjectMember?:UpdateProjectMemberResponse,
	createApiKey?:CreateApiKeyResponse,
	createGlobalApiKey?:CreateApiKeyResponse,
	disableApiKey?:DisableApiKeyResponse,
	addMailTemplate?:AddMailTemplateResponse,
	removeMailTemplate?:RemoveMailTemplateResponse,
	createProject?:CreateProjectResponse,
	setProjectSecret?:SetProjectSecretResponse,
	updateProject?:UpdateProjectResponse,
	addProjectMailTemplate?:AddMailTemplateResponse,
	removeProjectMailTemplate?:RemoveMailTemplateResponse
}

export type Person = {
	__typename?: "Person",
	id:string,
	email?:string,
	name?:string,
	otpEnabled:boolean,
	identity:Identity
}

export type PrepareOtpResponse = {
	__typename?: "PrepareOtpResponse",
	ok:boolean,
	result?:PrepareOtpResult
}

export type PrepareOtpResult = {
	__typename?: "PrepareOtpResult",
	otpUri:string,
	otpSecret:string
}

export type Project = {
	__typename?: "Project",
	id:string,
	name:string,
	slug:string,
	config:Json,
	roles:RoleDefinition[],
	members:ProjectIdentityRelation[]
}

export type ProjectIdentityRelation = {
	__typename?: "ProjectIdentityRelation",
	identity:Identity,
	memberships:Membership[]
}

export type ProjectSecret = {
		key:string,
	value:string
}

export type Query = {
	__typename?: "Query",
	me:Identity,
	projects:Project[],
	projectBySlug?:Project,
	projectMemberships:Membership[],
	checkResetPasswordToken:CheckResetPasswordTokenCode,
	identityProviders:IdentityProvider[]
}

export type RemoveMailTemplateError = {
	__typename?: "RemoveMailTemplateError",
	code:RemoveMailTemplateErrorCode,
	developerMessage:string,
	endUserMessage?:string
}

export enum RemoveMailTemplateErrorCode {
	PROJECT_NOT_FOUND = "PROJECT_NOT_FOUND",
	TEMPLATE_NOT_FOUND = "TEMPLATE_NOT_FOUND"
}

export type RemoveMailTemplateResponse = {
	__typename?: "RemoveMailTemplateResponse",
	ok:boolean,
	errors:RemoveMailTemplateError[],
	error?:RemoveMailTemplateError
}

export type RemoveProjectMemberError = {
	__typename?: "RemoveProjectMemberError",
	code:RemoveProjectMemberErrorCode,
	developerMessage:string,
	endUserMessage?:string
}

export enum RemoveProjectMemberErrorCode {
	NOT_MEMBER = "NOT_MEMBER",
	PROJECT_NOT_FOUND = "PROJECT_NOT_FOUND"
}

export type RemoveProjectMemberResponse = {
	__typename?: "RemoveProjectMemberResponse",
	ok:boolean,
	errors:RemoveProjectMemberError[],
	error?:RemoveProjectMemberError
}

export type ResetPasswordError = {
	__typename?: "ResetPasswordError",
	code:ResetPasswordErrorCode,
	developerMessage:string,
	endUserMessage?:string
}

export enum ResetPasswordErrorCode {
	TOKEN_NOT_FOUND = "TOKEN_NOT_FOUND",
	TOKEN_USED = "TOKEN_USED",
	TOKEN_EXPIRED = "TOKEN_EXPIRED",
	PASSWORD_TOO_WEAK = "PASSWORD_TOO_WEAK"
}

export type ResetPasswordResponse = {
	__typename?: "ResetPasswordResponse",
	ok:boolean,
	errors:ResetPasswordError[],
	error?:ResetPasswordError
}

export type RoleConditionVariableDefinition = {
	__typename?: "RoleConditionVariableDefinition",
	name:string
}

export type RoleDefinition = {
	__typename?: "RoleDefinition",
	name:string,
	variables:RoleVariableDefinition[]
}

export type RoleEntityVariableDefinition = {
	__typename?: "RoleEntityVariableDefinition",
	name:string,
	entityName:string
}

export type RolePredefinedVariableDefinition = {
	__typename?: "RolePredefinedVariableDefinition",
	name:string,
	value:string
}

export type RoleVariableDefinition = {
	__interface:{
			name:string
	};
	__resolve:{
		['...on RoleConditionVariableDefinition']: RoleConditionVariableDefinition;
		['...on RoleEntityVariableDefinition']: RoleEntityVariableDefinition;
		['...on RolePredefinedVariableDefinition']: RolePredefinedVariableDefinition;
	}
}

export type SetProjectSecretResponse = {
	__typename?: "SetProjectSecretResponse",
	ok:boolean
}

export type SignInError = {
	__typename?: "SignInError",
	code:SignInErrorCode,
	developerMessage:string,
	endUserMessage?:string
}

export enum SignInErrorCode {
	UNKNOWN_EMAIL = "UNKNOWN_EMAIL",
	INVALID_PASSWORD = "INVALID_PASSWORD",
	NO_PASSWORD_SET = "NO_PASSWORD_SET",
	OTP_REQUIRED = "OTP_REQUIRED",
	INVALID_OTP_TOKEN = "INVALID_OTP_TOKEN"
}

export type SignInIDPError = {
	__typename?: "SignInIDPError",
	code:SignInIDPErrorCode,
	developerMessage:string,
	endUserMessage?:string
}

export enum SignInIDPErrorCode {
	INVALID_IDP_RESPONSE = "INVALID_IDP_RESPONSE",
	IDP_VALIDATION_FAILED = "IDP_VALIDATION_FAILED",
	PERSON_NOT_FOUND = "PERSON_NOT_FOUND",
	PERSON_ALREADY_EXISTS = "PERSON_ALREADY_EXISTS"
}

export type SignInIDPResponse = {
	__typename?: "SignInIDPResponse",
	ok:boolean,
	errors:SignInIDPError[],
	error?:SignInIDPError,
	result?:SignInIDPResult
}

export type SignInIDPResult = {
	__typename?: "SignInIDPResult",
	token:string,
	person:Person
}

export type SignInResponse = {
	__typename?: "SignInResponse",
	ok:boolean,
	errors:SignInError[],
	error?:SignInError,
	result?:SignInResult
}

export type SignInResult = {
	__typename?: "SignInResult",
	token:string,
	person:Person
}

export type SignOutError = {
	__typename?: "SignOutError",
	code:SignOutErrorCode,
	developerMessage:string,
	endUserMessage?:string
}

export enum SignOutErrorCode {
	NOT_A_PERSON = "NOT_A_PERSON"
}

export type SignOutResponse = {
	__typename?: "SignOutResponse",
	ok:boolean,
	errors:SignOutError[],
	error?:SignOutError
}

export type SignUpError = {
	__typename?: "SignUpError",
	code:SignUpErrorCode,
	developerMessage:string,
	endPersonMessage?:string
}

export enum SignUpErrorCode {
	EMAIL_ALREADY_EXISTS = "EMAIL_ALREADY_EXISTS",
	TOO_WEAK = "TOO_WEAK"
}

export type SignUpResponse = {
	__typename?: "SignUpResponse",
	ok:boolean,
	errors:SignUpError[],
	error?:SignUpError,
	result?:SignUpResult
}

export type SignUpResult = {
	__typename?: "SignUpResult",
	person:Person
}

export type UnmanagedInviteOptions = {
		password?:string,
	resetTokenHash?:string
}

export type UpdateIDPError = {
	__typename?: "UpdateIDPError",
	code:UpdateIDPErrorCode,
	developerMessage:string
}

export enum UpdateIDPErrorCode {
	NOT_FOUND = "NOT_FOUND",
	INVALID_CONFIGURATION = "INVALID_CONFIGURATION"
}

export type UpdateIDPResponse = {
	__typename?: "UpdateIDPResponse",
	error?:UpdateIDPError,
	ok:boolean
}

export type UpdateProjectMemberError = {
	__typename?: "UpdateProjectMemberError",
	code:UpdateProjectMemberErrorCode,
	developerMessage:string,
	membershipValidation?:MembershipValidationError[],
	endUserMessage?:string
}

export enum UpdateProjectMemberErrorCode {
	PROJECT_NOT_FOUND = "PROJECT_NOT_FOUND",
	NOT_MEMBER = "NOT_MEMBER",
	INVALID_MEMBERSHIP = "INVALID_MEMBERSHIP",
	ROLE_NOT_FOUND = "ROLE_NOT_FOUND",
	VARIABLE_EMPTY = "VARIABLE_EMPTY",
	VARIABLE_NOT_FOUND = "VARIABLE_NOT_FOUND"
}

export type UpdateProjectMemberResponse = {
	__typename?: "UpdateProjectMemberResponse",
	ok:boolean,
	errors:UpdateProjectMemberError[],
	error?:UpdateProjectMemberError
}

export type UpdateProjectResponse = {
	__typename?: "UpdateProjectResponse",
	ok:boolean
}

export type VariableEntry = {
	__typename?: "VariableEntry",
	name:string,
	values:string[]
}

export type VariableEntryInput = {
		name:string,
	values:string[]
}

export const AllTypesProps: Record<string,any> = {
	AddIDPErrorCode: "enum",
	AddMailTemplateErrorCode: "enum",
	AddProjectMemberErrorCode: "enum",
	ChangeMyPasswordErrorCode: "enum",
	ChangePasswordErrorCode: "enum",
	CheckResetPasswordTokenCode: "enum",
	ConfirmOtpErrorCode: "enum",
	CreateApiKeyErrorCode: "enum",
	CreatePasswordResetRequestErrorCode: "enum",
	CreateProjectOptions:{
		deployTokenHash:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		noDeployToken:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CreateProjectResponseErrorCode: "enum",
	CreateResetPasswordRequestOptions:{
		mailProject:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mailVariant:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CreateSessionTokenErrorCode: "enum",
	DateTime: "String",
	DisableApiKeyErrorCode: "enum",
	DisableIDPErrorCode: "enum",
	DisableOtpErrorCode: "enum",
	EnableIDPErrorCode: "enum",
	IDPOptions:{
		autoSignUp:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		exclusive:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IDPResponseInput:{
		url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	InitSignInIDPErrorCode: "enum",
	InviteErrorCode: "enum",
	InviteMethod: "enum",
	InviteOptions:{
		method:{
			type:"InviteMethod",
			array:false,
			arrayRequired:false,
			required:false
		},
		mailVariant:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Json: "String",
	MailTemplate:{
		projectSlug:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"MailType",
			array:false,
			arrayRequired:false,
			required:true
		},
		variant:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		subject:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		useLayout:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MailTemplateIdentifier:{
		projectSlug:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"MailType",
			array:false,
			arrayRequired:false,
			required:true
		},
		variant:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MailType: "enum",
	MembershipInput:{
		role:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		variables:{
			type:"VariableEntryInput",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	MembershipValidationErrorCode: "enum",
	MemberType: "enum",
	Mutation:{
		signUp:{
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			password:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			passwordHash:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			roles:{
				type:"String",
				array:true,
				arrayRequired:false,
				required:true
			},
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		signIn:{
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			password:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			expiration:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			otpToken:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		createSessionToken:{
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			expiration:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		signOut:{
			all:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		changePassword:{
			personId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			password:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		changeMyPassword:{
			currentPassword:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			newPassword:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		initSignInIDP:{
			identityProvider:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			redirectUrl:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		signInIDP:{
			identityProvider:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			idpResponse:{
				type:"IDPResponseInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			redirectUrl:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			sessionData:{
				type:"Json",
				array:false,
				arrayRequired:false,
				required:true
			},
			expiration:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		addIDP:{
			identityProvider:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			type:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			configuration:{
				type:"Json",
				array:false,
				arrayRequired:false,
				required:true
			},
			options:{
				type:"IDPOptions",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIDP:{
			identityProvider:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			configuration:{
				type:"Json",
				array:false,
				arrayRequired:false,
				required:false
			},
			options:{
				type:"IDPOptions",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		disableIDP:{
			identityProvider:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		enableIDP:{
			identityProvider:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		prepareOtp:{
			label:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		confirmOtp:{
			otpToken:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createResetPasswordRequest:{
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			options:{
				type:"CreateResetPasswordRequestOptions",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		resetPassword:{
			token:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			password:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		invite:{
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			projectSlug:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			memberships:{
				type:"MembershipInput",
				array:true,
				arrayRequired:true,
				required:true
			},
			options:{
				type:"InviteOptions",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		unmanagedInvite:{
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			projectSlug:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			memberships:{
				type:"MembershipInput",
				array:true,
				arrayRequired:true,
				required:true
			},
			options:{
				type:"UnmanagedInviteOptions",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		addProjectMember:{
			projectSlug:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			identityId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			memberships:{
				type:"MembershipInput",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		removeProjectMember:{
			projectSlug:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			identityId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateProjectMember:{
			projectSlug:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			identityId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			memberships:{
				type:"MembershipInput",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		createApiKey:{
			projectSlug:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			memberships:{
				type:"MembershipInput",
				array:true,
				arrayRequired:true,
				required:true
			},
			description:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			tokenHash:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		createGlobalApiKey:{
			description:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			roles:{
				type:"String",
				array:true,
				arrayRequired:false,
				required:true
			},
			tokenHash:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		disableApiKey:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		addMailTemplate:{
			template:{
				type:"MailTemplate",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		removeMailTemplate:{
			templateIdentifier:{
				type:"MailTemplateIdentifier",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createProject:{
			projectSlug:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			config:{
				type:"Json",
				array:false,
				arrayRequired:false,
				required:false
			},
			secrets:{
				type:"ProjectSecret",
				array:true,
				arrayRequired:false,
				required:true
			},
			options:{
				type:"CreateProjectOptions",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		setProjectSecret:{
			projectSlug:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			key:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			value:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateProject:{
			projectSlug:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			config:{
				type:"Json",
				array:false,
				arrayRequired:false,
				required:false
			},
			mergeConfig:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		addProjectMailTemplate:{
			template:{
				type:"MailTemplate",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		removeProjectMailTemplate:{
			templateIdentifier:{
				type:"MailTemplateIdentifier",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	Project:{
		members:{
			memberType:{
				type:"MemberType",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ProjectSecret:{
		key:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Query:{
		projectBySlug:{
			slug:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		projectMemberships:{
			projectSlug:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			identityId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		checkResetPasswordToken:{
			requestId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			token:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	RemoveMailTemplateErrorCode: "enum",
	RemoveProjectMemberErrorCode: "enum",
	ResetPasswordErrorCode: "enum",
	SignInErrorCode: "enum",
	SignInIDPErrorCode: "enum",
	SignOutErrorCode: "enum",
	SignUpErrorCode: "enum",
	UnmanagedInviteOptions:{
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		resetTokenHash:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	UpdateIDPErrorCode: "enum",
	UpdateProjectMemberErrorCode: "enum",
	VariableEntryInput:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		values:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		}
	}
}

export const ReturnTypes: Record<string,any> = {
	AddIDPError:{
		code:"AddIDPErrorCode",
		developerMessage:"String"
	},
	AddIDPResponse:{
		error:"AddIDPError",
		ok:"Boolean"
	},
	AddMailTemplateError:{
		code:"AddMailTemplateErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	AddMailTemplateResponse:{
		ok:"Boolean",
		errors:"AddMailTemplateError",
		error:"AddMailTemplateError"
	},
	AddProjectMemberError:{
		code:"AddProjectMemberErrorCode",
		membershipValidation:"MembershipValidationError",
		developerMessage:"String",
		endUserMessage:"String"
	},
	AddProjectMemberResponse:{
		ok:"Boolean",
		errors:"AddProjectMemberError",
		error:"AddProjectMemberError"
	},
	ApiKey:{
		id:"String",
		identity:"Identity"
	},
	ApiKeyWithToken:{
		id:"String",
		token:"String",
		identity:"Identity"
	},
	ChangeMyPasswordError:{
		code:"ChangeMyPasswordErrorCode",
		developerMessage:"String"
	},
	ChangeMyPasswordResponse:{
		ok:"Boolean",
		error:"ChangeMyPasswordError"
	},
	ChangePasswordError:{
		code:"ChangePasswordErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	ChangePasswordResponse:{
		ok:"Boolean",
		errors:"ChangePasswordError",
		error:"ChangePasswordError"
	},
	CheckResetPasswordTokenResult:{
		code:"CheckResetPasswordTokenCode"
	},
	CommonSignInResult:{
		"...on CreateSessionTokenResult": "CreateSessionTokenResult",
		"...on SignInIDPResult": "SignInIDPResult",
		"...on SignInResult": "SignInResult",
		token:"String",
		person:"Person"
	},
	ConfirmOtpError:{
		code:"ConfirmOtpErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	ConfirmOtpResponse:{
		ok:"Boolean",
		errors:"ConfirmOtpError",
		error:"ConfirmOtpError"
	},
	CreateApiKeyError:{
		code:"CreateApiKeyErrorCode",
		developerMessage:"String",
		membershipValidation:"MembershipValidationError",
		endUserMessage:"String"
	},
	CreateApiKeyResponse:{
		ok:"Boolean",
		errors:"CreateApiKeyError",
		error:"CreateApiKeyError",
		result:"CreateApiKeyResult"
	},
	CreateApiKeyResult:{
		apiKey:"ApiKeyWithToken"
	},
	CreatePasswordResetRequestError:{
		code:"CreatePasswordResetRequestErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	CreatePasswordResetRequestResponse:{
		ok:"Boolean",
		errors:"CreatePasswordResetRequestError",
		error:"CreatePasswordResetRequestError"
	},
	CreateProjectResponse:{
		ok:"Boolean",
		error:"CreateProjectResponseError",
		result:"CreateProjectResult"
	},
	CreateProjectResponseError:{
		code:"CreateProjectResponseErrorCode",
		developerMessage:"String"
	},
	CreateProjectResult:{
		deployerApiKey:"ApiKeyWithToken"
	},
	CreateSessionTokenError:{
		code:"CreateSessionTokenErrorCode",
		developerMessage:"String"
	},
	CreateSessionTokenResponse:{
		ok:"Boolean",
		error:"CreateSessionTokenError",
		result:"CreateSessionTokenResult"
	},
	CreateSessionTokenResult:{
		token:"String",
		person:"Person"
	},
	DisableApiKeyError:{
		code:"DisableApiKeyErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	DisableApiKeyResponse:{
		ok:"Boolean",
		errors:"DisableApiKeyError",
		error:"DisableApiKeyError"
	},
	DisableIDPError:{
		code:"DisableIDPErrorCode",
		developerMessage:"String"
	},
	DisableIDPResponse:{
		error:"DisableIDPError",
		ok:"Boolean"
	},
	DisableOtpError:{
		code:"DisableOtpErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	DisableOtpResponse:{
		ok:"Boolean",
		errors:"DisableOtpError",
		error:"DisableOtpError"
	},
	EnableIDPError:{
		code:"EnableIDPErrorCode",
		developerMessage:"String"
	},
	EnableIDPResponse:{
		error:"EnableIDPError",
		ok:"Boolean"
	},
	Identity:{
		id:"String",
		description:"String",
		person:"Person",
		apiKey:"ApiKey",
		projects:"IdentityProjectRelation",
		permissions:"IdentityGlobalPermissions",
		roles:"String"
	},
	IdentityGlobalPermissions:{
		canCreateProject:"Boolean"
	},
	IdentityProjectRelation:{
		project:"Project",
		memberships:"Membership"
	},
	IdentityProvider:{
		slug:"String",
		type:"String",
		configuration:"Json",
		disabledAt:"DateTime",
		options:"IDPOptionsOutput"
	},
	IDPOptionsOutput:{
		autoSignUp:"Boolean",
		exclusive:"Boolean"
	},
	InitSignInIDPError:{
		code:"InitSignInIDPErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	InitSignInIDPResponse:{
		ok:"Boolean",
		errors:"InitSignInIDPError",
		error:"InitSignInIDPError",
		result:"InitSignInIDPResult"
	},
	InitSignInIDPResult:{
		authUrl:"String",
		sessionData:"Json"
	},
	InviteError:{
		code:"InviteErrorCode",
		developerMessage:"String",
		membershipValidation:"MembershipValidationError",
		endUserMessage:"String"
	},
	InviteResponse:{
		ok:"Boolean",
		errors:"InviteError",
		error:"InviteError",
		result:"InviteResult"
	},
	InviteResult:{
		person:"Person",
		isNew:"Boolean"
	},
	Membership:{
		role:"String",
		variables:"VariableEntry"
	},
	MembershipValidationError:{
		code:"MembershipValidationErrorCode",
		role:"String",
		variable:"String"
	},
	Mutation:{
		signUp:"SignUpResponse",
		signIn:"SignInResponse",
		createSessionToken:"CreateSessionTokenResponse",
		signOut:"SignOutResponse",
		changePassword:"ChangePasswordResponse",
		changeMyPassword:"ChangeMyPasswordResponse",
		initSignInIDP:"InitSignInIDPResponse",
		signInIDP:"SignInIDPResponse",
		addIDP:"AddIDPResponse",
		updateIDP:"UpdateIDPResponse",
		disableIDP:"DisableIDPResponse",
		enableIDP:"EnableIDPResponse",
		prepareOtp:"PrepareOtpResponse",
		confirmOtp:"ConfirmOtpResponse",
		disableOtp:"DisableOtpResponse",
		createResetPasswordRequest:"CreatePasswordResetRequestResponse",
		resetPassword:"ResetPasswordResponse",
		invite:"InviteResponse",
		unmanagedInvite:"InviteResponse",
		addProjectMember:"AddProjectMemberResponse",
		removeProjectMember:"RemoveProjectMemberResponse",
		updateProjectMember:"UpdateProjectMemberResponse",
		createApiKey:"CreateApiKeyResponse",
		createGlobalApiKey:"CreateApiKeyResponse",
		disableApiKey:"DisableApiKeyResponse",
		addMailTemplate:"AddMailTemplateResponse",
		removeMailTemplate:"RemoveMailTemplateResponse",
		createProject:"CreateProjectResponse",
		setProjectSecret:"SetProjectSecretResponse",
		updateProject:"UpdateProjectResponse",
		addProjectMailTemplate:"AddMailTemplateResponse",
		removeProjectMailTemplate:"RemoveMailTemplateResponse"
	},
	Person:{
		id:"String",
		email:"String",
		name:"String",
		otpEnabled:"Boolean",
		identity:"Identity"
	},
	PrepareOtpResponse:{
		ok:"Boolean",
		result:"PrepareOtpResult"
	},
	PrepareOtpResult:{
		otpUri:"String",
		otpSecret:"String"
	},
	Project:{
		id:"String",
		name:"String",
		slug:"String",
		config:"Json",
		roles:"RoleDefinition",
		members:"ProjectIdentityRelation"
	},
	ProjectIdentityRelation:{
		identity:"Identity",
		memberships:"Membership"
	},
	Query:{
		me:"Identity",
		projects:"Project",
		projectBySlug:"Project",
		projectMemberships:"Membership",
		checkResetPasswordToken:"CheckResetPasswordTokenCode",
		identityProviders:"IdentityProvider"
	},
	RemoveMailTemplateError:{
		code:"RemoveMailTemplateErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	RemoveMailTemplateResponse:{
		ok:"Boolean",
		errors:"RemoveMailTemplateError",
		error:"RemoveMailTemplateError"
	},
	RemoveProjectMemberError:{
		code:"RemoveProjectMemberErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	RemoveProjectMemberResponse:{
		ok:"Boolean",
		errors:"RemoveProjectMemberError",
		error:"RemoveProjectMemberError"
	},
	ResetPasswordError:{
		code:"ResetPasswordErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	ResetPasswordResponse:{
		ok:"Boolean",
		errors:"ResetPasswordError",
		error:"ResetPasswordError"
	},
	RoleConditionVariableDefinition:{
		name:"String"
	},
	RoleDefinition:{
		name:"String",
		variables:"RoleVariableDefinition"
	},
	RoleEntityVariableDefinition:{
		name:"String",
		entityName:"String"
	},
	RolePredefinedVariableDefinition:{
		name:"String",
		value:"String"
	},
	RoleVariableDefinition:{
		"...on RoleConditionVariableDefinition": "RoleConditionVariableDefinition",
		"...on RoleEntityVariableDefinition": "RoleEntityVariableDefinition",
		"...on RolePredefinedVariableDefinition": "RolePredefinedVariableDefinition",
		name:"String"
	},
	SetProjectSecretResponse:{
		ok:"Boolean"
	},
	SignInError:{
		code:"SignInErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	SignInIDPError:{
		code:"SignInIDPErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	SignInIDPResponse:{
		ok:"Boolean",
		errors:"SignInIDPError",
		error:"SignInIDPError",
		result:"SignInIDPResult"
	},
	SignInIDPResult:{
		token:"String",
		person:"Person"
	},
	SignInResponse:{
		ok:"Boolean",
		errors:"SignInError",
		error:"SignInError",
		result:"SignInResult"
	},
	SignInResult:{
		token:"String",
		person:"Person"
	},
	SignOutError:{
		code:"SignOutErrorCode",
		developerMessage:"String",
		endUserMessage:"String"
	},
	SignOutResponse:{
		ok:"Boolean",
		errors:"SignOutError",
		error:"SignOutError"
	},
	SignUpError:{
		code:"SignUpErrorCode",
		developerMessage:"String",
		endPersonMessage:"String"
	},
	SignUpResponse:{
		ok:"Boolean",
		errors:"SignUpError",
		error:"SignUpError",
		result:"SignUpResult"
	},
	SignUpResult:{
		person:"Person"
	},
	UpdateIDPError:{
		code:"UpdateIDPErrorCode",
		developerMessage:"String"
	},
	UpdateIDPResponse:{
		error:"UpdateIDPError",
		ok:"Boolean"
	},
	UpdateProjectMemberError:{
		code:"UpdateProjectMemberErrorCode",
		developerMessage:"String",
		membershipValidation:"MembershipValidationError",
		endUserMessage:"String"
	},
	UpdateProjectMemberResponse:{
		ok:"Boolean",
		errors:"UpdateProjectMemberError",
		error:"UpdateProjectMemberError"
	},
	UpdateProjectResponse:{
		ok:"Boolean"
	},
	VariableEntry:{
		name:"String",
		values:"String"
	}
}

export class GraphQLError extends Error {
    constructor(public response: GraphQLResponse) {
      super("");
      console.error(response);
    }
    toString() {
      return "GraphQL Response Error";
    }
  }



export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<
  UnwrapPromise<ReturnType<T>>
>;
export type ZeusHook<
  T extends (
    ...args: any[]
  ) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

type Func<P extends any[], R> = (...args: P) => R;
type AnyFunc = Func<any, any>;

type WithTypeNameValue<T> = T & {
  __typename?: true;
};

type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};

type NotUndefined<T> = T extends undefined ? never : T;

export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type ArgsType<F extends AnyFunc> = F extends Func<infer P, any> ? P : never;

interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}

export type ValuesOf<T> = T[keyof T];

export type MapResolve<SRC, DST> = SRC extends {
    __interface: infer INTERFACE;
    __resolve: Record<string, { __typename?: string }> & infer IMPLEMENTORS;
  }
  ?
  ValuesOf<{
    [k in (keyof SRC['__resolve'] & keyof DST)]: ({
      [rk in (keyof SRC['__resolve'][k] & keyof DST[k])]: LastMapTypeSRCResolver<SRC['__resolve'][k][rk], DST[k][rk]>
    } & {
      __typename: SRC['__resolve'][k]['__typename']
    })
  }>
  :
  never;

export type MapInterface<SRC, DST> = SRC extends {
    __interface: infer INTERFACE;
    __resolve: Record<string, { __typename?: string }> & infer IMPLEMENTORS;
  }
  ?
  (MapResolve<SRC, DST> extends never ? {} : MapResolve<SRC, DST>) & {
  [k in (keyof SRC['__interface'] & keyof DST)]: LastMapTypeSRCResolver<SRC['__interface'][k], DST[k]>
} : never;

export type ValueToUnion<T> = T extends {
  __typename: infer R;
}
  ? {
      [P in keyof Omit<T, '__typename'>]: T[P] & {
        __typename: R;
      };
    }
  : T;

export type ObjectToUnion<T> = {
  [P in keyof T]: T[P];
}[keyof T];

type Anify<T> = { [P in keyof T]?: any };


type LastMapTypeSRCResolver<SRC, DST> = SRC extends undefined
  ? undefined
  : SRC extends Array<infer AR>
  ? LastMapTypeSRCResolver<AR, DST>[]
  : SRC extends { __interface: any; __resolve: any }
  ? MapInterface<SRC, DST>
  : SRC extends { __union: any; __resolve: infer RESOLVE }
  ? ObjectToUnion<MapType<RESOLVE, ValueToUnion<DST>>>
  : DST extends boolean
  ? SRC
  : MapType<SRC, DST>;

export type MapType<SRC extends Anify<DST>, DST> = DST extends boolean
  ? SRC
  : DST extends {
      __alias: any;
    }
  ? {
      [A in keyof DST["__alias"]]: Required<SRC> extends Anify<
        DST["__alias"][A]
      >
        ? MapType<Required<SRC>, DST["__alias"][A]>
        : never;
    } &
      {
        [Key in keyof Omit<DST, "__alias">]: DST[Key] extends [
          any,
          infer PAYLOAD
        ]
          ? LastMapTypeSRCResolver<SRC[Key], PAYLOAD>
          : LastMapTypeSRCResolver<SRC[Key], DST[Key]>;
      }
  : {
      [Key in keyof DST]: DST[Key] extends [any, infer PAYLOAD]
        ? LastMapTypeSRCResolver<SRC[Key], PAYLOAD>
        : LastMapTypeSRCResolver<SRC[Key], DST[Key]>;
    };

type OperationToGraphQL<V, T> = <Z extends V>(o: Z | V, variables?: Record<string, any>) => Promise<MapType<T, Z>>;

type CastToGraphQL<V, T> = (
  resultOfYourQuery: any
) => <Z extends V>(o: Z | V) => MapType<T, Z>;

type fetchOptions = ArgsType<typeof fetch>;

export type SelectionFunction<V> = <T>(t: T | V) => T;
type FetchFunction = (query: string, variables?: Record<string, any>) => Promise<any>;



export const ZeusSelect = <T>() => ((t: any) => t) as SelectionFunction<T>;

export const ScalarResolver = (scalar: string, value: any) => {
  switch (scalar) {
    case 'String':
      return  `${JSON.stringify(value)}`;
    case 'Int':
      return `${value}`;
    case 'Float':
      return `${value}`;
    case 'Boolean':
      return `${value}`;
    case 'ID':
      return `"${value}"`;
    case 'enum':
      return `${value}`;
    case 'scalar':
      return `${value}`;
    default:
      return false;
  }
};


export const TypesPropsResolver = ({
    value,
    type,
    name,
    key,
    blockArrays
}: {
    value: any;
    type: string;
    name: string;
    key?: string;
    blockArrays?: boolean;
}): string => {
    if (value === null) {
        return `null`;
    }
    let resolvedValue = AllTypesProps[type][name];
    if (key) {
        resolvedValue = resolvedValue[key];
    }
    if (!resolvedValue) {
        throw new Error(`Cannot resolve ${type} ${name}${key ? ` ${key}` : ''}`)
    }
    const typeResolved = resolvedValue.type;
    const isArray = resolvedValue.array;
    const isArrayRequired = resolvedValue.arrayRequired;
    if (typeof value === 'string' && value.startsWith(`ZEUS_VAR$`)) {
        const isRequired = resolvedValue.required ? '!' : '';
        let t = `${typeResolved}`;
        if (isArray) {
          if (isRequired) {
              t = `${t}!`;
          }
          t = `[${t}]`;
          if(isArrayRequired){
            t = `${t}!`;
          }
        }else{
          if (isRequired) {
                t = `${t}!`;
          }
        }
        return `\$${value.split(`ZEUS_VAR$`)[1]}__ZEUS_VAR__${t}`;
    }
    if (isArray && !blockArrays) {
        return `[${value
        .map((v: any) => TypesPropsResolver({ value: v, type, name, key, blockArrays: true }))
        .join(',')}]`;
    }
    const reslovedScalar = ScalarResolver(typeResolved, value);
    if (!reslovedScalar) {
        const resolvedType = AllTypesProps[typeResolved];
        if (typeof resolvedType === 'object') {
        const argsKeys = Object.keys(resolvedType);
        return `{${argsKeys
            .filter((ak) => value[ak] !== undefined)
            .map(
            (ak) => `${ak}:${TypesPropsResolver({ value: value[ak], type: typeResolved, name: ak })}`
            )}}`;
        }
        return ScalarResolver(AllTypesProps[typeResolved], value) as string;
    }
    return reslovedScalar;
};


const isArrayFunction = (
  parent: string[],
  a: any[]
) => {
  const [values, r] = a;
  const [mainKey, key, ...keys] = parent;
  const keyValues = Object.keys(values).filter((k) => typeof values[k] !== 'undefined');

  if (!keys.length) {
      return keyValues.length > 0
        ? `(${keyValues
            .map(
              (v) =>
                `${v}:${TypesPropsResolver({
                  value: values[v],
                  type: mainKey,
                  name: key,
                  key: v
                })}`
            )
            .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
        : traverseToSeekArrays(parent, r);
    }

  const [typeResolverKey] = keys.splice(keys.length - 1, 1);
  let valueToResolve = ReturnTypes[mainKey][key];
  for (const k of keys) {
    valueToResolve = ReturnTypes[valueToResolve][k];
  }

  const argumentString =
    keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: valueToResolve,
                name: typeResolverKey,
                key: v
              })}`
          )
          .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
      : traverseToSeekArrays(parent, r);
  return argumentString;
};


const resolveKV = (k: string, v: boolean | string | { [x: string]: boolean | string }) =>
  typeof v === 'boolean' ? k : typeof v === 'object' ? `${k}{${objectToTree(v)}}` : `${k}${v}`;


const objectToTree = (o: { [x: string]: boolean | string }): string =>
  `{${Object.keys(o).map((k) => `${resolveKV(k, o[k])}`).join(' ')}}`;


const traverseToSeekArrays = (parent: string[], a?: any): string => {
  if (!a) return '';
  if (Object.keys(a).length === 0) {
    return '';
  }
  let b: Record<string, any> = {};
  if (Array.isArray(a)) {
    return isArrayFunction([...parent], a);
  } else {
    if (typeof a === 'object') {
      Object.keys(a)
        .filter((k) => typeof a[k] !== 'undefined')
        .map((k) => {
        if (k === '__alias') {
          Object.keys(a[k]).map((aliasKey) => {
            const aliasOperations = a[k][aliasKey];
            const aliasOperationName = Object.keys(aliasOperations)[0];
            const aliasOperation = aliasOperations[aliasOperationName];
            b[
              `${aliasOperationName}__alias__${aliasKey}: ${aliasOperationName}`
            ] = traverseToSeekArrays([...parent, aliasOperationName], aliasOperation);
          });
        } else {
          b[k] = traverseToSeekArrays([...parent, k], a[k]);
        }
      });
    } else {
      return '';
    }
  }
  return objectToTree(b);
};  


const buildQuery = (type: string, a?: Record<any, any>) => 
  traverseToSeekArrays([type], a);


const inspectVariables = (query: string) => {
  const regex = /\$\b\w*__ZEUS_VAR__\[?[^!^\]^\s^,^\)^\}]*[!]?[\]]?[!]?/g;
  let result;
  const AllVariables: string[] = [];
  while ((result = regex.exec(query))) {
    if (AllVariables.includes(result[0])) {
      continue;
    }
    AllVariables.push(result[0]);
  }
  if (!AllVariables.length) {
    return query;
  }
  let filteredQuery = query;
  AllVariables.forEach((variable) => {
    while (filteredQuery.includes(variable)) {
      filteredQuery = filteredQuery.replace(variable, variable.split('__ZEUS_VAR__')[0]);
    }
  });
  return `(${AllVariables.map((a) => a.split('__ZEUS_VAR__'))
    .map(([variableName, variableType]) => `${variableName}:${variableType}`)
    .join(', ')})${filteredQuery}`;
};


const queryConstruct = (t: 'query' | 'mutation' | 'subscription', tName: string) => (o: Record<any, any>) =>
  `${t.toLowerCase()}${inspectVariables(buildQuery(tName, o))}`;
  

const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription', tName: string) => (
  o: Record<any, any>,
  variables?: Record<string, any>,
) => fn(queryConstruct(t, tName)(o), variables).then((r:any) => { 
  seekForAliases(r)
  return r
});


const seekForAliases = (response: any) => {
  const traverseAlias = (value: any) => {
    if (Array.isArray(value)) {
      value.forEach(seekForAliases);
    } else {
      if (typeof value === 'object') {
        seekForAliases(value);
      }
    }
  };
  if (typeof response === 'object' && response) {
    const keys = Object.keys(response);
    if (keys.length < 1) {
      return;
    }
    keys.forEach((k) => {
      const value = response[k];
      if (k.indexOf('__alias__') !== -1) {
        const [operation, alias] = k.split('__alias__');
        response[alias] = {
          [operation]: value,
        };
        delete response[k];
      }
      traverseAlias(value);
    });
  }
};


export const $ = (t: TemplateStringsArray): any => `ZEUS_VAR$${t.join('')}`;


const handleFetchResponse = (
  response: Parameters<Extract<Parameters<ReturnType<typeof fetch>['then']>[0], Function>>[0]
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response.text().then(text => {
        try { reject(JSON.parse(text)); }
        catch (err) { reject(text); }
      }).catch(reject);
    });
  }
  return response.json();
};

const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, any> = {}) => {
    let fetchFunction = fetch;
    let queryString = query;
    let fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      queryString = encodeURIComponent(query);
      return fetchFunction(`${options[0]}?query=${queryString}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetchFunction(`${options[0]}`, {
      body: JSON.stringify({ query: queryString, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      ...fetchOptions
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };
  


export const Thunder = (fn: FetchFunction) => ({
  query: ((o: any, variables) =>
    fullChainConstruct(fn)('query', 'Query')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Query"],Query>,
mutation: ((o: any, variables) =>
    fullChainConstruct(fn)('mutation', 'Mutation')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Mutation"],Mutation>
});

export const Chain = (...options: fetchOptions) => ({
  query: ((o: any, variables) =>
    fullChainConstruct(apiFetch(options))('query', 'Query')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Query"],Query>,
mutation: ((o: any, variables) =>
    fullChainConstruct(apiFetch(options))('mutation', 'Mutation')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Mutation"],Mutation>
});
export const Zeus = {
  query: (o:ValueTypes["Query"]) => queryConstruct('query', 'Query')(o),
mutation: (o:ValueTypes["Mutation"]) => queryConstruct('mutation', 'Mutation')(o)
};
export const Cast = {
  query: ((o: any) => (_: any) => o) as CastToGraphQL<
  ValueTypes["Query"],
  Query
>,
mutation: ((o: any) => (_: any) => o) as CastToGraphQL<
  ValueTypes["Mutation"],
  Mutation
>
};
export const Selectors = {
  query: ZeusSelect<ValueTypes["Query"]>(),
mutation: ZeusSelect<ValueTypes["Mutation"]>()
};
  