import React from 'react'

function handleMousedown() {
	document.documentElement.classList.add('flag-usingMouse')
}

function handleKeydown(event: KeyboardEvent) {
	if (event.keyCode === 9) {
		document.documentElement.classList.remove('flag-usingMouse')
	}
}

let registered = 0
export function useUsingMouse() {
	return React.useEffect(() => {
		if (process.browser) {
			registered++
			if (registered === 1 && typeof document !== 'undefined' && document) {
				document.documentElement.addEventListener('mousedown', handleMousedown)
				document.documentElement.addEventListener('keydown', handleKeydown)
			}
			return () => {
				registered--
				if (registered === 0) {
					document.documentElement.removeEventListener('mousedown', handleMousedown)
					document.documentElement.removeEventListener('keydown', handleKeydown)
				}
			}
		}
	}, [])
}
