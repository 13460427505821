import { ContemberErrorType } from 'components/ErrorList'
import { $, MapType, SignInResult, ValueTypes, ZeusSelect, Selectors } from 'generated/tenant'
import { useLoginTenant } from 'hooks/useContember'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import React from 'react'
import { EVENT_SIGNIN_SUCCESS, Role } from 'src/constants'
import { AuthorizedAuthContextType } from 'src/contexts/authContext'
import { dispatchCustomEvent } from 'utils/dispatchCustomEvent'
import { getSlashUrl } from 'utils/getSlashUrl'

const SIGN_IN_RESULT_QUERY = ZeusSelect<ValueTypes['SignInResult']>()({
	token: true,
	person: {
		email: true,
		id: true,
		identity: {
			projects: {
				project: {
					id: true,
					name: true,
					slug: true,
				},
				memberships: {
					role: true,
				},
			},
		},
	},
})

const DURATION_1_DAY_IN_SECONDS = 1 * 24 * 60 * 60

const MUTATION = Selectors.mutation({
	signIn: [
		{ email: $`email`, password: $`password`, expiration: DURATION_1_DAY_IN_SECONDS },
		{
			ok: true,
			result: SIGN_IN_RESULT_QUERY,
			errors: {
				code: true,
				developerMessage: true,
				endUserMessage: true,
			},
		},
	],
})

export function normalizeSignInResult(raw: MapType<SignInResult, typeof SIGN_IN_RESULT_QUERY>) {
	const {
		publicRuntimeConfig: { CONTEMBER_PROJECT_SLUG },
	} = getConfig()

	const result: AuthorizedAuthContextType = {
		token: raw.token,
		identity: {
			id: raw.person.id,
			name: raw.person.email ?? '', // TODO: users name
			email: raw.person.email ?? '',
			roles: raw.person.identity.projects
				.filter((p) => p.project.slug === CONTEMBER_PROJECT_SLUG)
				.reduce((roles, project) => {
					const newRoles = project.memberships.map((m) => m.role as Role)
					return [...roles, ...newRoles]
				}, [] as Role[]),
		},
	}

	return result
}

export function useSignIn() {
	const tenant = useLoginTenant()

	const router = useRouter()
	const { backlink } = router.query

	const [working, setWorking] = React.useState(false)
	const [serverErrors, setErrors] = React.useState<null | ContemberErrorType[]>(null)

	const trigger = React.useCallback(
		(email: string, password: string) => {
			setWorking(true)

			tenant
				.mutation(MUTATION, { email, password })

				.then(({ signIn }) => {
					if (signIn) {
						setErrors(signIn.errors)
						if (signIn.ok && signIn.result) {
							dispatchCustomEvent(EVENT_SIGNIN_SUCCESS, {
								result: normalizeSignInResult(signIn.result),
								...(backlink ? { backlink: getSlashUrl(String(backlink)) } : {}),
							})
						}
					} else {
						setErrors([{ developerMessage: 'UNKNOWN_ERROR' }])
					}
					setWorking(false)
				})
		},
		[tenant, backlink]
	)

	return { trigger, working, serverErrors }
}
