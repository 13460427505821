import { Placement } from '@popperjs/core'
import React from 'react'
import useMergedRef from '@react-hook/merged-ref'
import { usePopper } from 'react-popper'
import { useCloseOnEscapeOrClickOutside } from 'hooks/useCloseOnEscapeOrClickOutside'

export function Dropdown(
	props: { label: React.ReactNode; placement?: Placement } & (
		| { content: React.ReactNode }
		| { renderContent: (helpers: { close: () => void }) => React.ReactNode }
	)
) {
	const { label, placement } = props

	const [open, setOpen] = React.useState(false)

	const toggle = React.useCallback(() => setOpen((val) => !val), [])
	const close = React.useCallback(() => setOpen(false), [])
	const helpers = React.useMemo(() => ({ close }), [close])

	const outside = useCloseOnEscapeOrClickOutside<HTMLButtonElement, HTMLDivElement>(open, close)

	const [referenceElement, setReferenceElement] = React.useState<HTMLButtonElement | null>(null)
	const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null)

	const { styles, attributes, forceUpdate } = usePopper(referenceElement, popperElement, {
		placement: placement || 'auto',
		strategy: 'fixed',
	})

	const buttonRefs = useMergedRef<HTMLButtonElement>(setReferenceElement, outside.buttonRef)
	const contentRefs = useMergedRef<HTMLDivElement>(setPopperElement, outside.contentRef)

	return (
		<>
			<button
				type="button"
				className="btn btn-unstyled btn-noFocusRing"
				ref={buttonRefs}
				onClick={toggle}>
				{label}
			</button>

			<div
				ref={contentRefs}
				style={{ ...styles.popper, ...(open ? {} : { display: 'none' }), zIndex: 10 }}
				{...attributes.popper}>
				{open && (
					<div ref={forceUpdate}>
						{'renderContent' in props ? props.renderContent(helpers) : props.content}
					</div>
				)}
			</div>
		</>
	)
}
