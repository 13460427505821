/* eslint-disable @typescript-eslint/no-explicit-any */
import cloneDeep from 'lodash/cloneDeep'

const getCircularReplacer = () => {
	const seen = new WeakSet()
	return (_: string | number, value: any) => {
		if (typeof value === 'object' && value !== null) {
			if (seen.has(value)) {
				return
			}
			seen.add(value)
		}
		return value
	}
}

export function dump(value: any) {
	return JSON.stringify(cloneDeep(value), getCircularReplacer(), 2)
}
