import { useQuery } from 'react-query'
import { useUserContent } from './useContember'

export function useSearch(q: string | null) {
	const content = useUserContent()

	return useQuery(['useSearch', q], async () => {
		return q
			? await content.query({
					listHousingUnit: [
						{ limit: 50, filter: { or: [{ name: { containsCI: q } }] } },
						{
							id: true,
							name: true,
							owner: [
								{},
								{
									id: true,
									name: true,
									email: true,
								},
							],
						},
					],
					listHousingUnitTemplate: [
						{ limit: 50, filter: { or: [{ name: { containsCI: q } }] } },
						{
							id: true,
							name: true,
						},
					],
					listUser: [
						{
							limit: 50,
							filter: {
								or: [{ name: { containsCI: q } }, { email: { containsCI: q } }],
							},
						},
						{
							id: true,
							name: true,
							email: true,
						},
					],
			  })
			: null
	})
}
