import { Icon } from 'components/Icon'
import { Button } from 'react-bootstrap'
import { EVENT_SIGNIN_SUCCESS } from 'src/constants'
import { useAuthContext } from 'src/contexts/authContext'
import { dispatchCustomEvent } from 'utils/dispatchCustomEvent'
import { useIsAdmin } from '../contexts/authContext'
import { normalizeSignInResult } from '../hooks/useSignIn'

export function SignInAsUser(props: { email: string }) {
	const admin = useIsAdmin()

	if (!admin) {
		return null
	}

	return <RealSignInAsUser email={props.email} />
}

export function RealSignInAsUser(props: { email: string }) {
	const auth = useAuthContext()

	const token = auth ? auth.token : null

	return (
		<Button
			type="button"
			size="sm"
			onClick={async (e) => {
				e.preventDefault()

				await fetch('/api/action1', {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						email: props.email,
					}),
				})
					.then((res) => res.json())
					.then((result) => {
						if (result.ok) {
							dispatchCustomEvent(EVENT_SIGNIN_SUCCESS, {
								result: {
									...normalizeSignInResult(result.result),
									prevToken: token,
								},
							})
						}
					})
			}}>
			Přihlásit se jako uživatel
		</Button>
	)
}

export function RestoreAuth() {
	const auth = useAuthContext()

	if (auth && 'prevToken' in auth && auth.prevToken) {
		return (
			<span
				onClick={async (e) => {
					e.preventDefault()

					await fetch('/api/action2', {
						method: 'POST',
						headers: {
							Authorization: `Bearer ${auth.prevToken}`,
						},
					})
						.then((res) => res.json())
						.then((result) => {
							if (result.ok) {
								dispatchCustomEvent(EVENT_SIGNIN_SUCCESS, {
									result: normalizeSignInResult(result.result),
								})
							}
						})
				}}>
				<span className="list-group-item list-group-item-action">
					<Icon name="signOut" /> Vrátit
				</span>
			</span>
		)
	}

	return null
}
