import { showToast } from 'components/Toaster'
import getConfig from 'next/config'
import React from 'react'
import { createContemberContentClient } from 'server/data/content'
import { createContemberGatewayClient } from 'server/data/gateway'
import { createContemberSystemClient } from 'server/data/system'
import { createContemberTenantClient } from 'server/data/tenant'
import { EVENT_SIGNOUT_REQUEST } from 'src/constants'
import { useAuthContext } from 'src/contexts/authContext'
import { dispatchCustomEvent } from 'utils/dispatchCustomEvent'
import { Sentry } from 'utils/sentry'

export type ContentZeus = ReturnType<typeof useContent>
export type UserContentZeus = ReturnType<typeof useUserContent>
export type UserGatewayZeus = ReturnType<typeof useUserGateway>

export function useContent() {
	const {
		publicRuntimeConfig: {
			CONTEMBER_CLIENTSIDE_CONTENT_API_TOKEN,
			CONTEMBER_CLIENTSIDE_CONTENT_API_URL,
		},
	} = getConfig()

	return React.useMemo(() => {
		const contember = createContemberContentClient(
			CONTEMBER_CLIENTSIDE_CONTENT_API_URL,
			CONTEMBER_CLIENTSIDE_CONTENT_API_TOKEN
		)

		return contember
	}, [CONTEMBER_CLIENTSIDE_CONTENT_API_TOKEN, CONTEMBER_CLIENTSIDE_CONTENT_API_URL])
}

export function useSystem() {
	const {
		publicRuntimeConfig: {
			CONTEMBER_CLIENTSIDE_SYSTEM_API_TOKEN,
			CONTEMBER_CLIENTSIDE_SYSTEM_API_URL,
		},
	} = getConfig()

	return React.useMemo(() => {
		const contember = createContemberSystemClient(
			CONTEMBER_CLIENTSIDE_SYSTEM_API_URL,
			CONTEMBER_CLIENTSIDE_SYSTEM_API_TOKEN
		)

		return contember
	}, [CONTEMBER_CLIENTSIDE_SYSTEM_API_TOKEN, CONTEMBER_CLIENTSIDE_SYSTEM_API_URL])
}

export function useUserContent(customToken?: string) {
	const auth = useAuthContext()

	const token = customToken ?? (auth ? auth.token : null)

	const {
		publicRuntimeConfig: { CONTEMBER_CLIENTSIDE_CONTENT_API_URL },
	} = getConfig()

	return React.useMemo(() => {
		const contember = createContemberContentClient(
			CONTEMBER_CLIENTSIDE_CONTENT_API_URL,
			token || '',
			(error) => {
				if ('errors' in error) {
					error.errors.forEach((error) => {
						if (error.code === 401) {
							dispatchCustomEvent(EVENT_SIGNOUT_REQUEST)
							return
						}
					})
					showToast({
						title: 'Chyba',
						raw: error.errors.map((e) => e.message).join(''),
						message: (
							<>
								{error.errors.map((e, i) => {
									Sentry.captureException(e.message)
									return (
										<div key={i}>
											<code>
												{e.code}: {e.message}
											</code>
										</div>
									)
								})}
							</>
						),
					})
				} else {
					Sentry.captureException(error.message)
					showToast({ title: 'Chyba', message: error.message, raw: error.message })
				}
				throw Error
			}
		)

		const mock = {
			query: () => {
				throw new Error('Unauthenticated contember content call mutation.')
			},
			mutation: () => {
				throw new Error('Unauthenticated contember content call mutation.')
			},
		} as typeof contember

		return token ? contember : mock
	}, [CONTEMBER_CLIENTSIDE_CONTENT_API_URL, token])
}

export function useUserSystem() {
	const auth = useAuthContext()

	const token = auth ? auth.token : null

	const {
		publicRuntimeConfig: { CONTEMBER_CLIENTSIDE_SYSTEM_API_URL },
	} = getConfig()

	return React.useMemo(() => {
		const contember = createContemberSystemClient(
			CONTEMBER_CLIENTSIDE_SYSTEM_API_URL,
			token || '',
			(error) => {
				if ('errors' in error) {
					error.errors.forEach((error) => {
						if (error.code === 401) {
							dispatchCustomEvent(EVENT_SIGNOUT_REQUEST)
							return
						}
					})
					showToast({
						title: 'Chyba',
						raw: error.errors.map((e) => e.message).join(''),
						message: (
							<>
								{error.errors.map((e, i) => {
									Sentry.captureException(e.message)
									return (
										<div key={i}>
											<code>
												{e.code}: {e.message}
											</code>
										</div>
									)
								})}
							</>
						),
					})
				} else {
					Sentry.captureException(error.message)
					showToast({ title: 'Chyba', message: <>{error.message}</>, raw: error.message })
				}
				throw Error
			}
		)

		const mock = {
			query: () => {
				throw new Error('Unauthenticated contember content call mutation.')
			},
			mutation: () => {
				throw new Error('Unauthenticated contember content call mutation.')
			},
		} as typeof contember

		return token ? contember : mock
	}, [token, CONTEMBER_CLIENTSIDE_SYSTEM_API_URL])
}

export function useUserGateway() {
	const auth = useAuthContext()

	const token = auth ? auth.token : null

	const {
		publicRuntimeConfig: { CONTEMBER_CLIENTSIDE_GATEWAY_API_URL },
	} = getConfig()

	return React.useMemo(() => {
		const contember = createContemberGatewayClient(
			CONTEMBER_CLIENTSIDE_GATEWAY_API_URL,
			token || '',
			(error) => {
				if ('errors' in error) {
					error.errors.forEach((error) => {
						if (error.code === 401) {
							dispatchCustomEvent(EVENT_SIGNOUT_REQUEST)
							return
						}
					})
					showToast({
						title: 'Chyba',
						raw: error.errors.map((e) => e.message).join(''),
						message: (
							<>
								{error.errors.map((e, i) => {
									Sentry.captureException(e.message)
									return (
										<div key={i}>
											<code>
												{e.code}: {e.message}
											</code>
										</div>
									)
								})}
							</>
						),
					})
				} else {
					Sentry.captureException(error.message)
					showToast({ title: 'Chyba', message: <>{error.message}</>, raw: error.message })
				}
				throw Error
			}
		)

		const mock = {
			query: () => {
				throw new Error('Unauthenticated contember content call mutation.')
			},
			mutation: () => {
				throw new Error('Unauthenticated contember content call mutation.')
			},
		} as typeof contember

		return token ? contember : mock
	}, [token, CONTEMBER_CLIENTSIDE_GATEWAY_API_URL])
}

export function useLoginTenant() {
	const {
		publicRuntimeConfig: {
			CONTEMBER_CLIENTSIDE_TENANT_API_LOGIN_TOKEN,
			CONTEMBER_CLIENTSIDE_TENANT_API_URL,
		},
	} = getConfig()

	return React.useMemo(() => {
		const contember = createContemberTenantClient(
			CONTEMBER_CLIENTSIDE_TENANT_API_URL,
			CONTEMBER_CLIENTSIDE_TENANT_API_LOGIN_TOKEN
		)

		return contember
	}, [CONTEMBER_CLIENTSIDE_TENANT_API_LOGIN_TOKEN, CONTEMBER_CLIENTSIDE_TENANT_API_URL])
}

export function useUserTenant() {
	const auth = useAuthContext()

	const token = auth ? auth.token : null

	const {
		publicRuntimeConfig: { CONTEMBER_CLIENTSIDE_TENANT_API_URL },
	} = getConfig()

	return React.useMemo(() => {
		const contember = createContemberTenantClient(
			CONTEMBER_CLIENTSIDE_TENANT_API_URL,
			token || '',
			(error) => {
				if ('errors' in error) {
					error.errors.forEach((error) => {
						if (error.code === 401) {
							dispatchCustomEvent(EVENT_SIGNOUT_REQUEST)
							return
						}
					})
					showToast({
						title: 'Chyba',
						raw: error.errors.map((e) => e.message).join(''),
						message: (
							<>
								{error.errors.map((e, i) => (
									<div key={i}>
										<code>
											{e.code}: {e.message}
										</code>
									</div>
								))}
							</>
						),
					})
				} else {
					showToast({ title: 'Chyba', message: <>{error.message}</>, raw: error.message })
				}
				throw Error
			}
		)

		const mock = {
			query: () => {
				throw new Error('Unauthenticated contember content call mutation.')
			},
			mutation: () => {
				throw new Error('Unauthenticated contember content call mutation.')
			},
		} as typeof contember

		return token ? contember : mock
	}, [token, CONTEMBER_CLIENTSIDE_TENANT_API_URL])
}
