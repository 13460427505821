import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import { pathToRegexp } from 'path-to-regexp'
import React from 'react'

type NavLinkProps = LinkProps & {
	exact?: boolean | RegExp
	active?: boolean
	activeClassName?: string
	children: React.ReactElement<HTMLAnchorElement>
}

export function NavLink({
	href,
	as,
	exact,
	active,
	activeClassName,
	children,
	...props
}: NavLinkProps) {
	const { asPath } = useRouter()
	const isActive =
		typeof active === 'boolean'
			? active
			: (typeof exact === 'object'
					? exact
					: pathToRegexp(String(as ?? href), [], { sensitive: true, end: !!exact })
			  ).test(asPath)

	activeClassName = activeClassName ?? 'active'

	const child = React.Children.only(children)
	const className = (
		(child.props.className || '') +
		' ' +
		(isActive ? activeClassName : '')
	).trim()

	return (
		<Link href={href} as={as} {...props}>
			{React.cloneElement(child, { className })}
		</Link>
	)
}

export type RouteLinkRoute = string | { href: string; as?: string }

export function RouteLink({
	route,
	...rest
}: { route: RouteLinkRoute } & Omit<NavLinkProps, 'href' | 'as'>) {
	if (typeof route === 'string') {
		route = { href: route }
	}
	return <NavLink {...rest} {...route} />
}
