import { useIsRouterLoading } from 'hooks/useIsRouterLoading'
import Link from 'next/link'
import React from 'react'
import { useIsFetching } from 'react-query'
import { Role, ROUTES } from 'src/constants'
import { useAuthContext, useIsAdmin } from 'src/contexts/authContext'
import { cn } from 'utils/cn'
import { ButtonGroup, ToggleButton } from './Bootstrap'
import { Dropdown } from './Dropdown'
import s from './Header.module.sass'
import { Icon } from './Icon'
import { Logo } from './Logo'
import { NavLink } from './NavLink'
import { Search } from './Search'
import { Username } from './Username'
import { RestoreAuth } from 'components/SignInAsUser'

export const SWITCH_ROLE_KEY = 'designcoAsUser'

function SwitchAdmin() {
	const value = window.localStorage.getItem(SWITCH_ROLE_KEY) as Role | null

	const roles = [Role.ADMIN, Role.CONTRACTOR, Role.HOUSING_UNIT_OWNER]

	let currentRole: Role = roles[0]

	if (value && roles.indexOf(value) > -1) {
		currentRole = value
	}

	return (
		<ButtonGroup toggle size="sm">
			{roles.map((role) => (
				<ToggleButton
					key={role}
					variant="outline-light"
					type="radio"
					value={role}
					checked={currentRole === role}
					onClick={() => {
						if (process.browser) {
							if (role) {
								window.localStorage.setItem(SWITCH_ROLE_KEY, role)
							} else {
								window.localStorage.removeItem(SWITCH_ROLE_KEY)
							}
						}
						window.location.reload()
					}}>
					{role}
				</ToggleButton>
			))}
		</ButtonGroup>
	)
}

export const Header = React.memo(function Header() {
	const [collapse, setCollapse] = React.useState(true)
	const toggle = React.useCallback((e: React.MouseEvent) => {
		e.preventDefault()
		setCollapse((value) => !value)
	}, [])
	const auth = useAuthContext()
	const isAdmin = useIsAdmin()
	const isRouterLoading = useIsRouterLoading()
	const isFetching = useIsFetching()
	const loading = isFetching || isRouterLoading

	return (
		<div className={s.Header}>
			<nav className="navbar navbar-expand-lg navbar-light">
				<Link href={ROUTES.HOMEPAGE}>
					<a className="navbar-brand">
						<span className={s.Logo}>
							<Logo loading={!!loading} />
						</span>
					</a>
				</Link>

				<button
					className="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#navbar-toggle"
					aria-controls="navbar-toggle"
					aria-expanded="false"
					aria-label="Toggle navigation"
					onClick={toggle}>
					<span className="navbar-toggler-icon"></span>
				</button>

				<div className={cn(collapse && 'collapse', 'navbar-collapse')} id="navbar-toggle">
					{auth ? (
						<ul className="navbar-nav ml-auto mt-2 mt-lg-0">
							{isAdmin && (
								<>
									<li className="nav-item">
										<Search />
									</li>
								</>
							)}
							{isAdmin && (
								<li className="nav-item">
									<SwitchAdmin />
								</li>
							)}
							<li className="nav-item">
								<Dropdown
									label={<Username name={auth.identity.name} />}
									content={
										<div className="list-group">
											<NavLink exact href={ROUTES.MY_PROFILE}>
												<a className="list-group-item list-group-item-action">
													<Icon name="user" /> Můj profil
												</a>
											</NavLink>
											{false && (
												<NavLink exact href={ROUTES.MY_SETTINGS}>
													<a className="list-group-item list-group-item-action">
														<Icon name="email" /> Nastavení e-mailů
													</a>
												</NavLink>
											)}
											<NavLink exact href={ROUTES.SIGN_OUT}>
												<a className="list-group-item list-group-item-action">
													<Icon name="signOut" /> Odhlásit se
												</a>
											</NavLink>
											<RestoreAuth />
										</div>
									}
									placement="bottom-end"
								/>
							</li>
						</ul>
					) : (
						<ul className="navbar-nav ml-auto mt-2 mt-lg-0"></ul>
					)}
				</div>
			</nav>
		</div>
	)
})
