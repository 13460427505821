import React from 'react'
import { QueryResult, MutationResult } from 'react-query'
import { Dump } from './Dump'

export function QueryView<TResult>({
	result,
	success,
	error,
	loading,
	keepOlderData,
}: {
	result: QueryResult<TResult> | MutationResult<TResult>
	success?: (result: TResult) => React.ReactNode
	error?: (result: QueryResult<TResult> | MutationResult<TResult>) => React.ReactNode
	loading?: () => React.ReactNode
	keepOlderData?: boolean | number
}) {
	const data = result.data

	const oldResult = React.useRef<null | QueryResult<TResult> | MutationResult<TResult>>(result)

	React.useEffect(() => {
		if (data && keepOlderData) {
			oldResult.current = result
		}
		const timeout = setTimeout(
			() => {
				if (oldResult) {
					oldResult.current = null
				}
			},
			typeof keepOlderData === 'number' ? keepOlderData : 500
		)
		return () => clearTimeout(timeout)
	}, [data, result, keepOlderData])

	if (result.error) {
		return <>{error ? error(result) || null : <Dump data={result} />}</>
	}

	if (data) {
		return <>{success ? success(data) || null : <Dump data={data} />}</>
	}

	if (!data && oldResult.current?.data) {
		return (
			<>
				{success ? (
					success(oldResult.current?.data) || null
				) : (
					<Dump data={oldResult.current?.data} />
				)}
			</>
		)
	}

	return <>{loading ? loading() : null}</>
}
