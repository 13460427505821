import { RewriteFrames } from '@sentry/integrations'
import * as Sentry from '@sentry/node'
import { Toaster } from 'components/Toaster'
import useEvent from 'hooks/useEvent'
import { useUsingMouse } from 'hooks/useUsingMouse'
import { AppProps } from 'next/app'
import getConfig from 'next/config'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'
import { ReactQueryDevtools } from 'react-query-devtools'
import {
	EVENT_SIGNIN_SUCCESS,
	EVENT_SIGNOUT_REQUEST,
	LOCALSTORAGE_AUTH_KEY,
	ROUTES,
} from 'src/constants'
import { AuthContext, AuthContextType, AuthorizedAuthContextType } from 'src/contexts/authContext'
import { AuthorizedLayout } from 'src/layouts/AuthorizedLayout'
import 'src/styles/global.sass'
import { getSlashUrl } from 'utils/getSlashUrl'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
	const config = getConfig()
	const distDir = `${config.serverRuntimeConfig.rootDir}/.next`
	Sentry.init({
		enabled: process.env.NODE_ENV === 'production',
		integrations: [
			new RewriteFrames({
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				iteratee: (frame: any) => {
					frame.filename = frame.filename.replace(distDir, 'app:///_next')
					return frame
				},
			}),
		],
		dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
	})
}

export interface LayoutProps {
	children: React.ReactNode
}

export default function App({
	Component,
	pageProps,
	err,
}: AppProps & { err: unknown } & { Component: { Layout?: React.ComponentType<LayoutProps> } }) {
	const router = useRouter()
	const Layout: React.ComponentType<LayoutProps> = Component.Layout ?? AuthorizedLayout

	const [authValue, setAuthValue] = React.useState<AuthContextType>(null)

	React.useEffect(() => {
		if (process.browser) {
			const authValue = window.localStorage.getItem(LOCALSTORAGE_AUTH_KEY)
			setAuthValue(authValue ? JSON.parse(authValue) : false)
		}
	}, [])

	useEvent<{ result: { result: AuthorizedAuthContextType; backlink?: string } }>(
		process.browser ? window : null,
		EVENT_SIGNIN_SUCCESS,
		React.useCallback(
			(e) => {
				const result = e.result
				window.localStorage.setItem(LOCALSTORAGE_AUTH_KEY, JSON.stringify(result.result))
				setAuthValue(result.result)
				if (result.backlink) {
					const backlink = getSlashUrl(result.backlink)
					if (backlink) {
						router.push(backlink)
					}
				} else {
					router.push(ROUTES.HOMEPAGE)
				}
			},
			[router]
		)
	)

	useEvent(
		process.browser ? window : null,
		EVENT_SIGNOUT_REQUEST,
		React.useCallback(() => {
			window.localStorage.removeItem(LOCALSTORAGE_AUTH_KEY)
			setAuthValue(null)
			let redirect: string = ROUTES.SIGN_IN
			if (window.location.href.indexOf('sign/out') === -1) {
				redirect = ROUTES.SIGN_IN + '?backlink=' + encodeURIComponent(window.location.href)
			}
			window.location.href = redirect
		}, [])
	)

	useUsingMouse()

	return (
		<>
			<AuthContext.Provider value={authValue}>
				<Head>
					<title key="title">Moje DesignCo</title>
					<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
					<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
					<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
					<link rel="manifest" href="/site.webmanifest" />
					<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#d0011c" />
					<meta name="version" content="2" />
					<link
						href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&display=swap&subset=latin-ext"
						rel="stylesheet"
					/>
					<meta name="msapplication-TileColor" content="#d0011c" />
					<meta name="theme-color" content="#d0011c" />
				</Head>
				<Layout>
					<Component {...pageProps} err={err} />
				</Layout>
				<Toaster />
			</AuthContext.Provider>
			<ReactQueryDevtools />
		</>
	)
}
