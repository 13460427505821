/* tslint:disable */
/* eslint-disable */

export type ValueTypes = {
    ["Building"]: AliasType<{
	id?:true,
	name?:true,
	address?:true,
	project?:ValueTypes["Project"],
		__typename?: true
}>;
	["BuildingRef"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	["ConfiguratorAnswerDeep"]: AliasType<{
	id?:true,
	name?:true,
	description?:true,
	position?:true,
	images?:ValueTypes["Image"],
	documentRevision?:ValueTypes["ConfiguratorDocumentRevisionDraft"],
		__typename?: true
}>;
	["ConfiguratorBoilerPlateDeep"]: AliasType<{
	id?:true,
	name?:true,
	description?:true,
	feesPriceCents?:true,
	position?:true,
	coverImage?:ValueTypes["Image"],
	steps?:ValueTypes["ConfiguratorStepDeep"],
	documentRevisions?:ValueTypes["ConfiguratorDocumentRevisionDraft"],
		__typename?: true
}>;
	["ConfiguratorBoilerPlateRef"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	["ConfiguratorDocumentRevisionDraft"]: AliasType<{
	id?:true,
	priceCents?:true,
	createdAt?:true,
	fileUrl?:true,
	previous?:ValueTypes["DocumentRevisionRef"],
	initialApproved?:true,
	disablesGeneratingFinalization?:true,
		__typename?: true
}>;
	["ConfiguratorQuestionDeep"]: AliasType<{
	id?:true,
	name?:true,
	description?:true,
	position?:true,
	type?:true,
	images?:ValueTypes["Image"],
	questionInner?:ValueTypes["ConfiguratorQuestionInnerDeep"],
		__typename?: true
}>;
	["ConfiguratorQuestionInnerDeep"]: AliasType<{
	id?:true,
	options?:ValueTypes["ConfiguratorAnswerDeep"],
	position?:true,
		__typename?: true
}>;
	["ConfiguratorQuestionType"]:ConfiguratorQuestionType;
	["ConfiguratorStepDeep"]: AliasType<{
	id?:true,
	name?:true,
	description?:true,
	position?:true,
	questions?:ValueTypes["ConfiguratorQuestionDeep"],
		__typename?: true
}>;
	["CreateHousingUnitFromTemplateResult"]: AliasType<{
	ok?:true,
	errorDeveloperDescription?:true,
	housingUnit?:ValueTypes["HousingUnit"],
		__typename?: true
}>;
	["DateTime"]:unknown;
	["Document"]: AliasType<{
	id?:true,
	name?:true,
	folder?:ValueTypes["FolderRef"],
	firstRevision?:ValueTypes["DocumentRevisionRef"],
	revisions?:ValueTypes["DocumentRevision"],
	approvable?:true,
	housingUnitOwnerPermissions?:ValueTypes["DocumentPermissions"],
	userPermissions?:ValueTypes["DocumentUserPermissions"],
	isFinalization?:true,
	showInSidebarName?:true,
	showInSidebarForContractors?:true,
	showInSidebarForDevelopers?:true,
	showInSidebarForOwners?:true,
		__typename?: true
}>;
	["DocumentPermissions"]: AliasType<{
	id?:true,
	canRead?:true,
	canRename?:true,
	canCreateNewVersions?:true,
		__typename?: true
}>;
	["DocumentRef"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	["DocumentRevision"]: AliasType<{
	id?:true,
	approved?:true,
	priceCents?:true,
	createdAt?:true,
	visibleForHouseOwner?:true,
	note?:true,
	fileUrl?:true,
	previous?:ValueTypes["DocumentRevisionRef"],
	next?:ValueTypes["DocumentRevisionRef"],
		__typename?: true
}>;
	["DocumentRevisionApprovedResult"]: AliasType<{
	ok?:true,
	finalizationGenerated?:true,
		__typename?: true
}>;
	["DocumentRevisionRef"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	["DocumentUserPermissions"]: AliasType<{
	id?:true,
	user?:ValueTypes["User"],
	document?:ValueTypes["DocumentRef"],
	permissions?:ValueTypes["DocumentPermissions"],
		__typename?: true
}>;
	["FeeRef"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	["Folder"]: AliasType<{
	id?:true,
	name?:true,
	documents?:ValueTypes["Document"],
	housingUnitOwnerPermissions?:ValueTypes["FolderPermissions"],
	userPermissions?:ValueTypes["FolderUserPermissions"],
	parent?:ValueTypes["FolderRef"],
	children?:ValueTypes["FolderRef"],
	showInSidebarName?:true,
	showInSidebarForContractors?:true,
	showInSidebarForDevelopers?:true,
	showInSidebarForOwners?:true,
		__typename?: true
}>;
	["FolderPermissions"]: AliasType<{
	id?:true,
	canRead?:true,
	canRename?:true,
	canCreateSubfolders?:true,
	canDeleteSubfolders?:true,
	canCreateDocuments?:true,
	canDeleteDocuments?:true,
	canCreateNewVersions?:true,
		__typename?: true
}>;
	["FolderPrice"]: AliasType<{
	name?:true,
	value?:true,
		__typename?: true
}>;
	["FolderRef"]: AliasType<{
	id?:true,
	name?:true,
		__typename?: true
}>;
	["FolderUserPermissions"]: AliasType<{
	id?:true,
	user?:ValueTypes["UserRef"],
	folder?:ValueTypes["FolderRef"],
	permissions?:ValueTypes["FolderPermissions"],
		__typename?: true
}>;
	["GetHousingUnitPriceResult"]: AliasType<{
	ok?:true,
	vat?:true,
	priceCentsPerFolder?:ValueTypes["FolderPrice"],
	totalCentsWithoutVat?:true,
	totalCentsWithVat?:true,
	totalCentsVat?:true,
	centsRounded?:true,
	totalCentsRounded?:true,
		__typename?: true
}>;
	["HousingUnit"]: AliasType<{
	id?:true,
	name?:true,
	rootFolder?:ValueTypes["Folder"],
	building?:ValueTypes["Building"],
	possibleFees?:ValueTypes["FeeRef"],
	configuratorBoilerPlates?:ValueTypes["ConfiguratorBoilerPlateDeep"],
	selectedConfiguratorBoilerplate?:ValueTypes["ConfiguratorBoilerPlateRef"],
	waitingForConfigurator?:true,
	disabledGeneratingFinalization?:true,
	increasedVat?:true,
	owner?:ValueTypes["User"],
		__typename?: true
}>;
	["HousingUnitRef"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	["HousingUnitTemplate"]: AliasType<{
	id?:true,
	name?:true,
	templateHousingUnit?:ValueTypes["HousingUnit"],
		__typename?: true
}>;
	["HousingUnitTemplateCloneResult"]: AliasType<{
	ok?:true,
	errorDeveloperDescription?:true,
	housingUnitTemplate?:ValueTypes["HousingUnitTemplate"],
		__typename?: true
}>;
	["Image"]: AliasType<{
	id?:true,
	url?:true,
	heightPx?:true,
	widthPx?:true,
		__typename?: true
}>;
	["Mutation"]: AliasType<{
duplicateHousingUnitTemplate?: [{	housingUnitTemplateId:string,	targetName:string},ValueTypes["HousingUnitTemplateCloneResult"]],
createHousingUnitFromTemplate?: [{	newHousingUnitName:string,	housingUnitTemplateId:ValueTypes["Uuid"],	priceCents:number,	waitingForConfigurator?:boolean,	disabledGeneratingFinalization?:boolean,	increasedVat?:boolean,	newHousingUnitOwner?:ValueTypes["Uuid"],	buildingId?:ValueTypes["Uuid"]},ValueTypes["CreateHousingUnitFromTemplateResult"]],
submitHousingUnitCalculation?: [{	housingUnitId:ValueTypes["Uuid"]},ValueTypes["SubmitHousingUnitCalculationResult"]],
documentRevisionApproved?: [{	housingUnitId:ValueTypes["Uuid"]},ValueTypes["DocumentRevisionApprovedResult"]],
		__typename?: true
}>;
	["Project"]: AliasType<{
	id?:true,
	name?:true,
	buildings?:ValueTypes["BuildingRef"],
		__typename?: true
}>;
	["Query"]: AliasType<{
	apiVersion?:true,
	healthz?:true,
	unimplemented?:ValueTypes["Unimplemented"],
helloWorld?: [{	text?:string},ValueTypes["Unimplemented"]],
getHousingUnitPrice?: [{	housingUnitId:ValueTypes["Uuid"]},ValueTypes["GetHousingUnitPriceResult"]],
		__typename?: true
}>;
	["SubmitHousingUnitCalculationResult"]: AliasType<{
	ok?:true,
	housingUnit?:ValueTypes["HousingUnit"],
		__typename?: true
}>;
	["Unimplemented"]: AliasType<{
	unimplemented?:true,
		__typename?: true
}>;
	["User"]: AliasType<{
	id?:true,
	email?:true,
	name?:true,
	owns?:ValueTypes["HousingUnitRef"],
	phone?:true,
	companyName?:true,
	companyPosition?:true,
		__typename?: true
}>;
	["UserRef"]: AliasType<{
	id?:true,
		__typename?: true
}>;
	["Uuid"]:unknown
  }

export type PartialObjects = {
    ["Building"]: {
		__typename?: "Building";
			id?:PartialObjects["Uuid"],
			name?:string,
			address?:string,
			project?:PartialObjects["Project"]
	},
	["BuildingRef"]: {
		__typename?: "BuildingRef";
			id?:PartialObjects["Uuid"]
	},
	["ConfiguratorAnswerDeep"]: {
		__typename?: "ConfiguratorAnswerDeep";
			id?:PartialObjects["Uuid"],
			name?:string,
			description?:string,
			position?:number,
			images?:PartialObjects["Image"][],
			documentRevision?:PartialObjects["ConfiguratorDocumentRevisionDraft"][]
	},
	["ConfiguratorBoilerPlateDeep"]: {
		__typename?: "ConfiguratorBoilerPlateDeep";
			id?:PartialObjects["Uuid"],
			name?:string,
			description?:string,
			feesPriceCents?:number,
			position?:number,
			coverImage?:PartialObjects["Image"],
			steps?:PartialObjects["ConfiguratorStepDeep"][],
			documentRevisions?:PartialObjects["ConfiguratorDocumentRevisionDraft"][]
	},
	["ConfiguratorBoilerPlateRef"]: {
		__typename?: "ConfiguratorBoilerPlateRef";
			id?:PartialObjects["Uuid"]
	},
	["ConfiguratorDocumentRevisionDraft"]: {
		__typename?: "ConfiguratorDocumentRevisionDraft";
			id?:PartialObjects["Uuid"],
			priceCents?:number,
			createdAt?:PartialObjects["DateTime"],
			fileUrl?:string,
			previous?:PartialObjects["DocumentRevisionRef"],
			initialApproved?:boolean,
			disablesGeneratingFinalization?:boolean
	},
	["ConfiguratorQuestionDeep"]: {
		__typename?: "ConfiguratorQuestionDeep";
			id?:PartialObjects["Uuid"],
			name?:string,
			description?:string,
			position?:number,
			type?:PartialObjects["ConfiguratorQuestionType"],
			images?:PartialObjects["Image"][],
			questionInner?:PartialObjects["ConfiguratorQuestionInnerDeep"][]
	},
	["ConfiguratorQuestionInnerDeep"]: {
		__typename?: "ConfiguratorQuestionInnerDeep";
			id?:PartialObjects["Uuid"],
			options?:PartialObjects["ConfiguratorAnswerDeep"][],
			position?:number
	},
	["ConfiguratorQuestionType"]:ConfiguratorQuestionType,
	["ConfiguratorStepDeep"]: {
		__typename?: "ConfiguratorStepDeep";
			id?:PartialObjects["Uuid"],
			name?:string,
			description?:string,
			position?:number,
			questions?:PartialObjects["ConfiguratorQuestionDeep"][]
	},
	["CreateHousingUnitFromTemplateResult"]: {
		__typename?: "CreateHousingUnitFromTemplateResult";
			ok?:boolean,
			errorDeveloperDescription?:string[],
			housingUnit?:PartialObjects["HousingUnit"]
	},
	["DateTime"]:any,
	["Document"]: {
		__typename?: "Document";
			id?:PartialObjects["Uuid"],
			name?:string,
			folder?:PartialObjects["FolderRef"],
			firstRevision?:PartialObjects["DocumentRevisionRef"],
			revisions?:PartialObjects["DocumentRevision"][],
			approvable?:boolean,
			housingUnitOwnerPermissions?:PartialObjects["DocumentPermissions"],
			userPermissions?:PartialObjects["DocumentUserPermissions"][],
			isFinalization?:boolean,
			showInSidebarName?:string,
			showInSidebarForContractors?:boolean,
			showInSidebarForDevelopers?:boolean,
			showInSidebarForOwners?:boolean
	},
	["DocumentPermissions"]: {
		__typename?: "DocumentPermissions";
			id?:PartialObjects["Uuid"],
			canRead?:boolean,
			canRename?:boolean,
			canCreateNewVersions?:boolean
	},
	["DocumentRef"]: {
		__typename?: "DocumentRef";
			id?:PartialObjects["Uuid"]
	},
	["DocumentRevision"]: {
		__typename?: "DocumentRevision";
			id?:PartialObjects["Uuid"],
			approved?:boolean,
			priceCents?:number,
			createdAt?:PartialObjects["DateTime"],
			visibleForHouseOwner?:boolean,
			note?:string,
			fileUrl?:string,
			previous?:PartialObjects["DocumentRevisionRef"],
			next?:PartialObjects["DocumentRevisionRef"]
	},
	["DocumentRevisionApprovedResult"]: {
		__typename?: "DocumentRevisionApprovedResult";
			ok?:boolean,
			finalizationGenerated?:boolean
	},
	["DocumentRevisionRef"]: {
		__typename?: "DocumentRevisionRef";
			id?:PartialObjects["Uuid"]
	},
	["DocumentUserPermissions"]: {
		__typename?: "DocumentUserPermissions";
			id?:PartialObjects["Uuid"],
			user?:PartialObjects["User"],
			document?:PartialObjects["DocumentRef"],
			permissions?:PartialObjects["DocumentPermissions"]
	},
	["FeeRef"]: {
		__typename?: "FeeRef";
			id?:PartialObjects["Uuid"]
	},
	["Folder"]: {
		__typename?: "Folder";
			id?:PartialObjects["Uuid"],
			name?:string,
			documents?:PartialObjects["Document"][],
			housingUnitOwnerPermissions?:PartialObjects["FolderPermissions"],
			userPermissions?:PartialObjects["FolderUserPermissions"][],
			parent?:PartialObjects["FolderRef"],
			children?:PartialObjects["FolderRef"][],
			showInSidebarName?:string,
			showInSidebarForContractors?:boolean,
			showInSidebarForDevelopers?:boolean,
			showInSidebarForOwners?:boolean
	},
	["FolderPermissions"]: {
		__typename?: "FolderPermissions";
			id?:PartialObjects["Uuid"],
			canRead?:boolean,
			canRename?:boolean,
			canCreateSubfolders?:boolean,
			canDeleteSubfolders?:boolean,
			canCreateDocuments?:boolean,
			canDeleteDocuments?:boolean,
			canCreateNewVersions?:boolean
	},
	["FolderPrice"]: {
		__typename?: "FolderPrice";
			name?:string,
			value?:number
	},
	["FolderRef"]: {
		__typename?: "FolderRef";
			id?:PartialObjects["Uuid"],
			name?:string
	},
	["FolderUserPermissions"]: {
		__typename?: "FolderUserPermissions";
			id?:PartialObjects["Uuid"],
			user?:PartialObjects["UserRef"],
			folder?:PartialObjects["FolderRef"],
			permissions?:PartialObjects["FolderPermissions"]
	},
	["GetHousingUnitPriceResult"]: {
		__typename?: "GetHousingUnitPriceResult";
			ok?:boolean,
			vat?:number,
			priceCentsPerFolder?:PartialObjects["FolderPrice"][],
			totalCentsWithoutVat?:number,
			totalCentsWithVat?:number,
			totalCentsVat?:number,
			centsRounded?:number,
			totalCentsRounded?:number
	},
	["HousingUnit"]: {
		__typename?: "HousingUnit";
			id?:PartialObjects["Uuid"],
			name?:string,
			rootFolder?:PartialObjects["Folder"],
			building?:PartialObjects["Building"],
			possibleFees?:PartialObjects["FeeRef"][],
			configuratorBoilerPlates?:PartialObjects["ConfiguratorBoilerPlateDeep"][],
			selectedConfiguratorBoilerplate?:PartialObjects["ConfiguratorBoilerPlateRef"],
			waitingForConfigurator?:boolean,
			disabledGeneratingFinalization?:boolean,
			increasedVat?:boolean,
			owner?:PartialObjects["User"]
	},
	["HousingUnitRef"]: {
		__typename?: "HousingUnitRef";
			id?:PartialObjects["Uuid"]
	},
	["HousingUnitTemplate"]: {
		__typename?: "HousingUnitTemplate";
			id?:PartialObjects["Uuid"],
			name?:string,
			templateHousingUnit?:PartialObjects["HousingUnit"]
	},
	["HousingUnitTemplateCloneResult"]: {
		__typename?: "HousingUnitTemplateCloneResult";
			ok?:boolean,
			errorDeveloperDescription?:string[],
			housingUnitTemplate?:PartialObjects["HousingUnitTemplate"]
	},
	["Image"]: {
		__typename?: "Image";
			id?:PartialObjects["Uuid"],
			url?:string,
			heightPx?:number,
			widthPx?:number
	},
	["Mutation"]: {
		__typename?: "Mutation";
			duplicateHousingUnitTemplate?:PartialObjects["HousingUnitTemplateCloneResult"],
			createHousingUnitFromTemplate?:PartialObjects["CreateHousingUnitFromTemplateResult"],
			submitHousingUnitCalculation?:PartialObjects["SubmitHousingUnitCalculationResult"],
			documentRevisionApproved?:PartialObjects["DocumentRevisionApprovedResult"]
	},
	["Project"]: {
		__typename?: "Project";
			id?:PartialObjects["Uuid"],
			name?:string,
			buildings?:PartialObjects["BuildingRef"][]
	},
	["Query"]: {
		__typename?: "Query";
			apiVersion?:string,
			healthz?:string,
			unimplemented?:PartialObjects["Unimplemented"],
			helloWorld?:PartialObjects["Unimplemented"],
			getHousingUnitPrice?:PartialObjects["GetHousingUnitPriceResult"]
	},
	["SubmitHousingUnitCalculationResult"]: {
		__typename?: "SubmitHousingUnitCalculationResult";
			ok?:boolean,
			housingUnit?:PartialObjects["HousingUnit"]
	},
	["Unimplemented"]: {
		__typename?: "Unimplemented";
			unimplemented?:boolean
	},
	["User"]: {
		__typename?: "User";
			id?:PartialObjects["Uuid"],
			email?:string,
			name?:string,
			owns?:PartialObjects["HousingUnitRef"][],
			phone?:string,
			companyName?:string,
			companyPosition?:string
	},
	["UserRef"]: {
		__typename?: "UserRef";
			id?:PartialObjects["Uuid"]
	},
	["Uuid"]:any
  }

export type Building = {
	__typename?: "Building",
	id:Uuid,
	name:string,
	address?:string,
	project?:Project
}

export type BuildingRef = {
	__typename?: "BuildingRef",
	id:Uuid
}

export type ConfiguratorAnswerDeep = {
	__typename?: "ConfiguratorAnswerDeep",
	id:Uuid,
	name:string,
	description?:string,
	position?:number,
	images:Image[],
	documentRevision:ConfiguratorDocumentRevisionDraft[]
}

export type ConfiguratorBoilerPlateDeep = {
	__typename?: "ConfiguratorBoilerPlateDeep",
	id:Uuid,
	name:string,
	description?:string,
	feesPriceCents?:number,
	position?:number,
	coverImage?:Image,
	steps:ConfiguratorStepDeep[],
	documentRevisions:ConfiguratorDocumentRevisionDraft[]
}

export type ConfiguratorBoilerPlateRef = {
	__typename?: "ConfiguratorBoilerPlateRef",
	id:Uuid
}

export type ConfiguratorDocumentRevisionDraft = {
	__typename?: "ConfiguratorDocumentRevisionDraft",
	id:Uuid,
	priceCents:number,
	createdAt:DateTime,
	fileUrl:string,
	previous?:DocumentRevisionRef,
	initialApproved?:boolean,
	disablesGeneratingFinalization:boolean
}

export type ConfiguratorQuestionDeep = {
	__typename?: "ConfiguratorQuestionDeep",
	id:Uuid,
	name:string,
	description?:string,
	position?:number,
	type?:ConfiguratorQuestionType,
	images:Image[],
	questionInner:ConfiguratorQuestionInnerDeep[]
}

export type ConfiguratorQuestionInnerDeep = {
	__typename?: "ConfiguratorQuestionInnerDeep",
	id:Uuid,
	options:ConfiguratorAnswerDeep[],
	position?:number
}

export enum ConfiguratorQuestionType {
	SINGLE_ANSWER = "SINGLE_ANSWER",
	MULTI_ANSWER = "MULTI_ANSWER"
}

export type ConfiguratorStepDeep = {
	__typename?: "ConfiguratorStepDeep",
	id:Uuid,
	name:string,
	description?:string,
	position?:number,
	questions:ConfiguratorQuestionDeep[]
}

export type CreateHousingUnitFromTemplateResult = {
	__typename?: "CreateHousingUnitFromTemplateResult",
	ok:boolean,
	errorDeveloperDescription?:string[],
	housingUnit?:HousingUnit
}

export type DateTime = any

export type Document = {
	__typename?: "Document",
	id:Uuid,
	name:string,
	folder?:FolderRef,
	firstRevision?:DocumentRevisionRef,
	revisions:DocumentRevision[],
	approvable:boolean,
	housingUnitOwnerPermissions?:DocumentPermissions,
	userPermissions:DocumentUserPermissions[],
	isFinalization:boolean,
	showInSidebarName?:string,
	showInSidebarForContractors:boolean,
	showInSidebarForDevelopers:boolean,
	showInSidebarForOwners:boolean
}

export type DocumentPermissions = {
	__typename?: "DocumentPermissions",
	id:Uuid,
	canRead?:boolean,
	canRename?:boolean,
	canCreateNewVersions?:boolean
}

export type DocumentRef = {
	__typename?: "DocumentRef",
	id:Uuid
}

export type DocumentRevision = {
	__typename?: "DocumentRevision",
	id:Uuid,
	approved?:boolean,
	priceCents:number,
	createdAt:DateTime,
	visibleForHouseOwner:boolean,
	note?:string,
	fileUrl:string,
	previous?:DocumentRevisionRef,
	next?:DocumentRevisionRef
}

export type DocumentRevisionApprovedResult = {
	__typename?: "DocumentRevisionApprovedResult",
	ok:boolean,
	finalizationGenerated:boolean
}

export type DocumentRevisionRef = {
	__typename?: "DocumentRevisionRef",
	id:Uuid
}

export type DocumentUserPermissions = {
	__typename?: "DocumentUserPermissions",
	id:Uuid,
	user?:User,
	document?:DocumentRef,
	permissions?:DocumentPermissions
}

export type FeeRef = {
	__typename?: "FeeRef",
	id:Uuid
}

export type Folder = {
	__typename?: "Folder",
	id:Uuid,
	name:string,
	documents:Document[],
	housingUnitOwnerPermissions?:FolderPermissions,
	userPermissions:FolderUserPermissions[],
	parent?:FolderRef,
	children:FolderRef[],
	showInSidebarName?:string,
	showInSidebarForContractors:boolean,
	showInSidebarForDevelopers:boolean,
	showInSidebarForOwners:boolean
}

export type FolderPermissions = {
	__typename?: "FolderPermissions",
	id:Uuid,
	canRead?:boolean,
	canRename?:boolean,
	canCreateSubfolders?:boolean,
	canDeleteSubfolders?:boolean,
	canCreateDocuments?:boolean,
	canDeleteDocuments?:boolean,
	canCreateNewVersions?:boolean
}

export type FolderPrice = {
	__typename?: "FolderPrice",
	name:string,
	value:number
}

export type FolderRef = {
	__typename?: "FolderRef",
	id:Uuid,
	name:string
}

export type FolderUserPermissions = {
	__typename?: "FolderUserPermissions",
	id:Uuid,
	user?:UserRef,
	folder?:FolderRef,
	permissions?:FolderPermissions
}

export type GetHousingUnitPriceResult = {
	__typename?: "GetHousingUnitPriceResult",
	ok:boolean,
	vat:number,
	priceCentsPerFolder:FolderPrice[],
	totalCentsWithoutVat:number,
	totalCentsWithVat:number,
	totalCentsVat:number,
	centsRounded:number,
	totalCentsRounded:number
}

export type HousingUnit = {
	__typename?: "HousingUnit",
	id:Uuid,
	name:string,
	rootFolder?:Folder,
	building?:Building,
	possibleFees:FeeRef[],
	configuratorBoilerPlates:ConfiguratorBoilerPlateDeep[],
	selectedConfiguratorBoilerplate?:ConfiguratorBoilerPlateRef,
	waitingForConfigurator:boolean,
	disabledGeneratingFinalization:boolean,
	increasedVat:boolean,
	owner?:User
}

export type HousingUnitRef = {
	__typename?: "HousingUnitRef",
	id:Uuid
}

export type HousingUnitTemplate = {
	__typename?: "HousingUnitTemplate",
	id:Uuid,
	name?:string,
	templateHousingUnit?:HousingUnit
}

export type HousingUnitTemplateCloneResult = {
	__typename?: "HousingUnitTemplateCloneResult",
	ok:boolean,
	errorDeveloperDescription?:string[],
	housingUnitTemplate?:HousingUnitTemplate
}

export type Image = {
	__typename?: "Image",
	id:Uuid,
	url:string,
	heightPx?:number,
	widthPx?:number
}

export type Mutation = {
	__typename?: "Mutation",
	duplicateHousingUnitTemplate:HousingUnitTemplateCloneResult,
	createHousingUnitFromTemplate:CreateHousingUnitFromTemplateResult,
	submitHousingUnitCalculation:SubmitHousingUnitCalculationResult,
	documentRevisionApproved:DocumentRevisionApprovedResult
}

export type Project = {
	__typename?: "Project",
	id:Uuid,
	name:string,
	buildings:BuildingRef[]
}

export type Query = {
	__typename?: "Query",
	apiVersion:string,
	healthz:string,
	unimplemented:Unimplemented,
	helloWorld:Unimplemented,
	getHousingUnitPrice:GetHousingUnitPriceResult
}

export type SubmitHousingUnitCalculationResult = {
	__typename?: "SubmitHousingUnitCalculationResult",
	ok:boolean,
	housingUnit?:HousingUnit
}

export type Unimplemented = {
	__typename?: "Unimplemented",
	unimplemented:boolean
}

export type User = {
	__typename?: "User",
	id:Uuid,
	email:string,
	name:string,
	owns:HousingUnitRef[],
	phone?:string,
	companyName?:string,
	companyPosition?:string
}

export type UserRef = {
	__typename?: "UserRef",
	id:Uuid
}

export type Uuid = any

export const AllTypesProps: Record<string,any> = {
	ConfiguratorQuestionType: "enum",
	DateTime: "String",
	Mutation:{
		duplicateHousingUnitTemplate:{
			housingUnitTemplateId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			targetName:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createHousingUnitFromTemplate:{
			newHousingUnitName:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			housingUnitTemplateId:{
				type:"Uuid",
				array:false,
				arrayRequired:false,
				required:true
			},
			priceCents:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			waitingForConfigurator:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			},
			disabledGeneratingFinalization:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			},
			increasedVat:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			},
			newHousingUnitOwner:{
				type:"Uuid",
				array:false,
				arrayRequired:false,
				required:false
			},
			buildingId:{
				type:"Uuid",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		submitHousingUnitCalculation:{
			housingUnitId:{
				type:"Uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		documentRevisionApproved:{
			housingUnitId:{
				type:"Uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	Query:{
		helloWorld:{
			text:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		getHousingUnitPrice:{
			housingUnitId:{
				type:"Uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	Uuid: "String"
}

export const ReturnTypes: Record<string,any> = {
	Building:{
		id:"Uuid",
		name:"String",
		address:"String",
		project:"Project"
	},
	BuildingRef:{
		id:"Uuid"
	},
	ConfiguratorAnswerDeep:{
		id:"Uuid",
		name:"String",
		description:"String",
		position:"Int",
		images:"Image",
		documentRevision:"ConfiguratorDocumentRevisionDraft"
	},
	ConfiguratorBoilerPlateDeep:{
		id:"Uuid",
		name:"String",
		description:"String",
		feesPriceCents:"Int",
		position:"Int",
		coverImage:"Image",
		steps:"ConfiguratorStepDeep",
		documentRevisions:"ConfiguratorDocumentRevisionDraft"
	},
	ConfiguratorBoilerPlateRef:{
		id:"Uuid"
	},
	ConfiguratorDocumentRevisionDraft:{
		id:"Uuid",
		priceCents:"Int",
		createdAt:"DateTime",
		fileUrl:"String",
		previous:"DocumentRevisionRef",
		initialApproved:"Boolean",
		disablesGeneratingFinalization:"Boolean"
	},
	ConfiguratorQuestionDeep:{
		id:"Uuid",
		name:"String",
		description:"String",
		position:"Int",
		type:"ConfiguratorQuestionType",
		images:"Image",
		questionInner:"ConfiguratorQuestionInnerDeep"
	},
	ConfiguratorQuestionInnerDeep:{
		id:"Uuid",
		options:"ConfiguratorAnswerDeep",
		position:"Int"
	},
	ConfiguratorStepDeep:{
		id:"Uuid",
		name:"String",
		description:"String",
		position:"Int",
		questions:"ConfiguratorQuestionDeep"
	},
	CreateHousingUnitFromTemplateResult:{
		ok:"Boolean",
		errorDeveloperDescription:"String",
		housingUnit:"HousingUnit"
	},
	Document:{
		id:"Uuid",
		name:"String",
		folder:"FolderRef",
		firstRevision:"DocumentRevisionRef",
		revisions:"DocumentRevision",
		approvable:"Boolean",
		housingUnitOwnerPermissions:"DocumentPermissions",
		userPermissions:"DocumentUserPermissions",
		isFinalization:"Boolean",
		showInSidebarName:"String",
		showInSidebarForContractors:"Boolean",
		showInSidebarForDevelopers:"Boolean",
		showInSidebarForOwners:"Boolean"
	},
	DocumentPermissions:{
		id:"Uuid",
		canRead:"Boolean",
		canRename:"Boolean",
		canCreateNewVersions:"Boolean"
	},
	DocumentRef:{
		id:"Uuid"
	},
	DocumentRevision:{
		id:"Uuid",
		approved:"Boolean",
		priceCents:"Int",
		createdAt:"DateTime",
		visibleForHouseOwner:"Boolean",
		note:"String",
		fileUrl:"String",
		previous:"DocumentRevisionRef",
		next:"DocumentRevisionRef"
	},
	DocumentRevisionApprovedResult:{
		ok:"Boolean",
		finalizationGenerated:"Boolean"
	},
	DocumentRevisionRef:{
		id:"Uuid"
	},
	DocumentUserPermissions:{
		id:"Uuid",
		user:"User",
		document:"DocumentRef",
		permissions:"DocumentPermissions"
	},
	FeeRef:{
		id:"Uuid"
	},
	Folder:{
		id:"Uuid",
		name:"String",
		documents:"Document",
		housingUnitOwnerPermissions:"FolderPermissions",
		userPermissions:"FolderUserPermissions",
		parent:"FolderRef",
		children:"FolderRef",
		showInSidebarName:"String",
		showInSidebarForContractors:"Boolean",
		showInSidebarForDevelopers:"Boolean",
		showInSidebarForOwners:"Boolean"
	},
	FolderPermissions:{
		id:"Uuid",
		canRead:"Boolean",
		canRename:"Boolean",
		canCreateSubfolders:"Boolean",
		canDeleteSubfolders:"Boolean",
		canCreateDocuments:"Boolean",
		canDeleteDocuments:"Boolean",
		canCreateNewVersions:"Boolean"
	},
	FolderPrice:{
		name:"String",
		value:"Int"
	},
	FolderRef:{
		id:"Uuid",
		name:"String"
	},
	FolderUserPermissions:{
		id:"Uuid",
		user:"UserRef",
		folder:"FolderRef",
		permissions:"FolderPermissions"
	},
	GetHousingUnitPriceResult:{
		ok:"Boolean",
		vat:"Int",
		priceCentsPerFolder:"FolderPrice",
		totalCentsWithoutVat:"Int",
		totalCentsWithVat:"Int",
		totalCentsVat:"Int",
		centsRounded:"Int",
		totalCentsRounded:"Int"
	},
	HousingUnit:{
		id:"Uuid",
		name:"String",
		rootFolder:"Folder",
		building:"Building",
		possibleFees:"FeeRef",
		configuratorBoilerPlates:"ConfiguratorBoilerPlateDeep",
		selectedConfiguratorBoilerplate:"ConfiguratorBoilerPlateRef",
		waitingForConfigurator:"Boolean",
		disabledGeneratingFinalization:"Boolean",
		increasedVat:"Boolean",
		owner:"User"
	},
	HousingUnitRef:{
		id:"Uuid"
	},
	HousingUnitTemplate:{
		id:"Uuid",
		name:"String",
		templateHousingUnit:"HousingUnit"
	},
	HousingUnitTemplateCloneResult:{
		ok:"Boolean",
		errorDeveloperDescription:"String",
		housingUnitTemplate:"HousingUnitTemplate"
	},
	Image:{
		id:"Uuid",
		url:"String",
		heightPx:"Int",
		widthPx:"Int"
	},
	Mutation:{
		duplicateHousingUnitTemplate:"HousingUnitTemplateCloneResult",
		createHousingUnitFromTemplate:"CreateHousingUnitFromTemplateResult",
		submitHousingUnitCalculation:"SubmitHousingUnitCalculationResult",
		documentRevisionApproved:"DocumentRevisionApprovedResult"
	},
	Project:{
		id:"Uuid",
		name:"String",
		buildings:"BuildingRef"
	},
	Query:{
		apiVersion:"String",
		healthz:"String",
		unimplemented:"Unimplemented",
		helloWorld:"Unimplemented",
		getHousingUnitPrice:"GetHousingUnitPriceResult"
	},
	SubmitHousingUnitCalculationResult:{
		ok:"Boolean",
		housingUnit:"HousingUnit"
	},
	Unimplemented:{
		unimplemented:"Boolean"
	},
	User:{
		id:"Uuid",
		email:"String",
		name:"String",
		owns:"HousingUnitRef",
		phone:"String",
		companyName:"String",
		companyPosition:"String"
	},
	UserRef:{
		id:"Uuid"
	}
}

export class GraphQLError extends Error {
    constructor(public response: GraphQLResponse) {
      super("");
      console.error(response);
    }
    toString() {
      return "GraphQL Response Error";
    }
  }



export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<
  UnwrapPromise<ReturnType<T>>
>;
export type ZeusHook<
  T extends (
    ...args: any[]
  ) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

type Func<P extends any[], R> = (...args: P) => R;
type AnyFunc = Func<any, any>;

type WithTypeNameValue<T> = T & {
  __typename?: true;
};

type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};

type NotUndefined<T> = T extends undefined ? never : T;

export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type ArgsType<F extends AnyFunc> = F extends Func<infer P, any> ? P : never;

interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}

export type ValuesOf<T> = T[keyof T];

export type MapResolve<SRC, DST> = SRC extends {
    __interface: infer INTERFACE;
    __resolve: Record<string, { __typename?: string }> & infer IMPLEMENTORS;
  }
  ?
  ValuesOf<{
    [k in (keyof SRC['__resolve'] & keyof DST)]: ({
      [rk in (keyof SRC['__resolve'][k] & keyof DST[k])]: LastMapTypeSRCResolver<SRC['__resolve'][k][rk], DST[k][rk]>
    } & {
      __typename: SRC['__resolve'][k]['__typename']
    })
  }>
  :
  never;

export type MapInterface<SRC, DST> = SRC extends {
    __interface: infer INTERFACE;
    __resolve: Record<string, { __typename?: string }> & infer IMPLEMENTORS;
  }
  ?
  (MapResolve<SRC, DST> extends never ? {} : MapResolve<SRC, DST>) & {
  [k in (keyof SRC['__interface'] & keyof DST)]: LastMapTypeSRCResolver<SRC['__interface'][k], DST[k]>
} : never;

export type ValueToUnion<T> = T extends {
  __typename: infer R;
}
  ? {
      [P in keyof Omit<T, '__typename'>]: T[P] & {
        __typename: R;
      };
    }
  : T;

export type ObjectToUnion<T> = {
  [P in keyof T]: T[P];
}[keyof T];

type Anify<T> = { [P in keyof T]?: any };


type LastMapTypeSRCResolver<SRC, DST> = SRC extends undefined
  ? undefined
  : SRC extends Array<infer AR>
  ? LastMapTypeSRCResolver<AR, DST>[]
  : SRC extends { __interface: any; __resolve: any }
  ? MapInterface<SRC, DST>
  : SRC extends { __union: any; __resolve: infer RESOLVE }
  ? ObjectToUnion<MapType<RESOLVE, ValueToUnion<DST>>>
  : DST extends boolean
  ? SRC
  : MapType<SRC, DST>;

export type MapType<SRC extends Anify<DST>, DST> = DST extends boolean
  ? SRC
  : DST extends {
      __alias: any;
    }
  ? {
      [A in keyof DST["__alias"]]: Required<SRC> extends Anify<
        DST["__alias"][A]
      >
        ? MapType<Required<SRC>, DST["__alias"][A]>
        : never;
    } &
      {
        [Key in keyof Omit<DST, "__alias">]: DST[Key] extends [
          any,
          infer PAYLOAD
        ]
          ? LastMapTypeSRCResolver<SRC[Key], PAYLOAD>
          : LastMapTypeSRCResolver<SRC[Key], DST[Key]>;
      }
  : {
      [Key in keyof DST]: DST[Key] extends [any, infer PAYLOAD]
        ? LastMapTypeSRCResolver<SRC[Key], PAYLOAD>
        : LastMapTypeSRCResolver<SRC[Key], DST[Key]>;
    };

type OperationToGraphQL<V, T> = <Z extends V>(o: Z | V, variables?: Record<string, any>) => Promise<MapType<T, Z>>;

type CastToGraphQL<V, T> = (
  resultOfYourQuery: any
) => <Z extends V>(o: Z | V) => MapType<T, Z>;

type fetchOptions = ArgsType<typeof fetch>;

export type SelectionFunction<V> = <T>(t: T | V) => T;
type FetchFunction = (query: string, variables?: Record<string, any>) => Promise<any>;



export const ZeusSelect = <T>() => ((t: any) => t) as SelectionFunction<T>;

export const ScalarResolver = (scalar: string, value: any) => {
  switch (scalar) {
    case 'String':
      return  `${JSON.stringify(value)}`;
    case 'Int':
      return `${value}`;
    case 'Float':
      return `${value}`;
    case 'Boolean':
      return `${value}`;
    case 'ID':
      return `"${value}"`;
    case 'enum':
      return `${value}`;
    case 'scalar':
      return `${value}`;
    default:
      return false;
  }
};


export const TypesPropsResolver = ({
    value,
    type,
    name,
    key,
    blockArrays
}: {
    value: any;
    type: string;
    name: string;
    key?: string;
    blockArrays?: boolean;
}): string => {
    if (value === null) {
        return `null`;
    }
    let resolvedValue = AllTypesProps[type][name];
    if (key) {
        resolvedValue = resolvedValue[key];
    }
    if (!resolvedValue) {
        throw new Error(`Cannot resolve ${type} ${name}${key ? ` ${key}` : ''}`)
    }
    const typeResolved = resolvedValue.type;
    const isArray = resolvedValue.array;
    const isArrayRequired = resolvedValue.arrayRequired;
    if (typeof value === 'string' && value.startsWith(`ZEUS_VAR$`)) {
        const isRequired = resolvedValue.required ? '!' : '';
        let t = `${typeResolved}`;
        if (isArray) {
          if (isRequired) {
              t = `${t}!`;
          }
          t = `[${t}]`;
          if(isArrayRequired){
            t = `${t}!`;
          }
        }else{
          if (isRequired) {
                t = `${t}!`;
          }
        }
        return `\$${value.split(`ZEUS_VAR$`)[1]}__ZEUS_VAR__${t}`;
    }
    if (isArray && !blockArrays) {
        return `[${value
        .map((v: any) => TypesPropsResolver({ value: v, type, name, key, blockArrays: true }))
        .join(',')}]`;
    }
    const reslovedScalar = ScalarResolver(typeResolved, value);
    if (!reslovedScalar) {
        const resolvedType = AllTypesProps[typeResolved];
        if (typeof resolvedType === 'object') {
        const argsKeys = Object.keys(resolvedType);
        return `{${argsKeys
            .filter((ak) => value[ak] !== undefined)
            .map(
            (ak) => `${ak}:${TypesPropsResolver({ value: value[ak], type: typeResolved, name: ak })}`
            )}}`;
        }
        return ScalarResolver(AllTypesProps[typeResolved], value) as string;
    }
    return reslovedScalar;
};


const isArrayFunction = (
  parent: string[],
  a: any[]
) => {
  const [values, r] = a;
  const [mainKey, key, ...keys] = parent;
  const keyValues = Object.keys(values).filter((k) => typeof values[k] !== 'undefined');

  if (!keys.length) {
      return keyValues.length > 0
        ? `(${keyValues
            .map(
              (v) =>
                `${v}:${TypesPropsResolver({
                  value: values[v],
                  type: mainKey,
                  name: key,
                  key: v
                })}`
            )
            .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
        : traverseToSeekArrays(parent, r);
    }

  const [typeResolverKey] = keys.splice(keys.length - 1, 1);
  let valueToResolve = ReturnTypes[mainKey][key];
  for (const k of keys) {
    valueToResolve = ReturnTypes[valueToResolve][k];
  }

  const argumentString =
    keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: valueToResolve,
                name: typeResolverKey,
                key: v
              })}`
          )
          .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
      : traverseToSeekArrays(parent, r);
  return argumentString;
};


const resolveKV = (k: string, v: boolean | string | { [x: string]: boolean | string }) =>
  typeof v === 'boolean' ? k : typeof v === 'object' ? `${k}{${objectToTree(v)}}` : `${k}${v}`;


const objectToTree = (o: { [x: string]: boolean | string }): string =>
  `{${Object.keys(o).map((k) => `${resolveKV(k, o[k])}`).join(' ')}}`;


const traverseToSeekArrays = (parent: string[], a?: any): string => {
  if (!a) return '';
  if (Object.keys(a).length === 0) {
    return '';
  }
  let b: Record<string, any> = {};
  if (Array.isArray(a)) {
    return isArrayFunction([...parent], a);
  } else {
    if (typeof a === 'object') {
      Object.keys(a)
        .filter((k) => typeof a[k] !== 'undefined')
        .map((k) => {
        if (k === '__alias') {
          Object.keys(a[k]).map((aliasKey) => {
            const aliasOperations = a[k][aliasKey];
            const aliasOperationName = Object.keys(aliasOperations)[0];
            const aliasOperation = aliasOperations[aliasOperationName];
            b[
              `${aliasOperationName}__alias__${aliasKey}: ${aliasOperationName}`
            ] = traverseToSeekArrays([...parent, aliasOperationName], aliasOperation);
          });
        } else {
          b[k] = traverseToSeekArrays([...parent, k], a[k]);
        }
      });
    } else {
      return '';
    }
  }
  return objectToTree(b);
};  


const buildQuery = (type: string, a?: Record<any, any>) => 
  traverseToSeekArrays([type], a);


const inspectVariables = (query: string) => {
  const regex = /\$\b\w*__ZEUS_VAR__\[?[^!^\]^\s^,^\)^\}]*[!]?[\]]?[!]?/g;
  let result;
  const AllVariables: string[] = [];
  while ((result = regex.exec(query))) {
    if (AllVariables.includes(result[0])) {
      continue;
    }
    AllVariables.push(result[0]);
  }
  if (!AllVariables.length) {
    return query;
  }
  let filteredQuery = query;
  AllVariables.forEach((variable) => {
    while (filteredQuery.includes(variable)) {
      filteredQuery = filteredQuery.replace(variable, variable.split('__ZEUS_VAR__')[0]);
    }
  });
  return `(${AllVariables.map((a) => a.split('__ZEUS_VAR__'))
    .map(([variableName, variableType]) => `${variableName}:${variableType}`)
    .join(', ')})${filteredQuery}`;
};


const queryConstruct = (t: 'query' | 'mutation' | 'subscription', tName: string) => (o: Record<any, any>) =>
  `${t.toLowerCase()}${inspectVariables(buildQuery(tName, o))}`;
  

const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription', tName: string) => (
  o: Record<any, any>,
  variables?: Record<string, any>,
) => fn(queryConstruct(t, tName)(o), variables).then((r:any) => { 
  seekForAliases(r)
  return r
});


const seekForAliases = (response: any) => {
  const traverseAlias = (value: any) => {
    if (Array.isArray(value)) {
      value.forEach(seekForAliases);
    } else {
      if (typeof value === 'object') {
        seekForAliases(value);
      }
    }
  };
  if (typeof response === 'object' && response) {
    const keys = Object.keys(response);
    if (keys.length < 1) {
      return;
    }
    keys.forEach((k) => {
      const value = response[k];
      if (k.indexOf('__alias__') !== -1) {
        const [operation, alias] = k.split('__alias__');
        response[alias] = {
          [operation]: value,
        };
        delete response[k];
      }
      traverseAlias(value);
    });
  }
};


export const $ = (t: TemplateStringsArray): any => `ZEUS_VAR$${t.join('')}`;


const handleFetchResponse = (
  response: Parameters<Extract<Parameters<ReturnType<typeof fetch>['then']>[0], Function>>[0]
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response.text().then(text => {
        try { reject(JSON.parse(text)); }
        catch (err) { reject(text); }
      }).catch(reject);
    });
  }
  return response.json();
};

const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, any> = {}) => {
    let fetchFunction = fetch;
    let queryString = query;
    let fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      queryString = encodeURIComponent(query);
      return fetchFunction(`${options[0]}?query=${queryString}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetchFunction(`${options[0]}`, {
      body: JSON.stringify({ query: queryString, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      ...fetchOptions
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };
  


export const Thunder = (fn: FetchFunction) => ({
  query: ((o: any, variables) =>
    fullChainConstruct(fn)('query', 'Query')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Query"],Query>,
mutation: ((o: any, variables) =>
    fullChainConstruct(fn)('mutation', 'Mutation')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Mutation"],Mutation>
});

export const Chain = (...options: fetchOptions) => ({
  query: ((o: any, variables) =>
    fullChainConstruct(apiFetch(options))('query', 'Query')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Query"],Query>,
mutation: ((o: any, variables) =>
    fullChainConstruct(apiFetch(options))('mutation', 'Mutation')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Mutation"],Mutation>
});
export const Zeus = {
  query: (o:ValueTypes["Query"]) => queryConstruct('query', 'Query')(o),
mutation: (o:ValueTypes["Mutation"]) => queryConstruct('mutation', 'Mutation')(o)
};
export const Cast = {
  query: ((o: any) => (_: any) => o) as CastToGraphQL<
  ValueTypes["Query"],
  Query
>,
mutation: ((o: any) => (_: any) => o) as CastToGraphQL<
  ValueTypes["Mutation"],
  Mutation
>
};
export const Selectors = {
  query: ZeusSelect<ValueTypes["Query"]>(),
mutation: ZeusSelect<ValueTypes["Mutation"]>()
};
  