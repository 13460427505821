import gqlPrettier from 'graphql-prettier'
import { fetchGraphql, GraphqlError } from 'server/utils/fetchGraphql'
import { Thunder } from '../../../generated/content'

export function createContemberContentClient(
	url: string,
	token: string | null = null,
	onError?: (err: GraphqlError | Error) => void
) {
	const service = Thunder(async (query, variables) => {
		let promise = fetchGraphql(url, token, query, variables)
		if (onError) {
			promise = promise.catch(onError)
		}
		console.log('<graphql>')
		try {
			console.log(gqlPrettier(query))
		} catch (e) {
			console.log(query)
		}
		console.log(variables)
		console.log('</graphql>')
		const result = await promise
		return result.data
	})

	return service
}
