import { useThrottle } from '@react-hook/throttle'
import { useSearch } from 'hooks/useSearch'
import React from 'react'
import { CardDeck } from 'react-bootstrap'
import { ROUTES } from 'src/constants'
import { useIsDeveloper } from 'src/contexts/authContext'
import { Card, Form, ListGroup, Modal } from './Bootstrap'
import { Icon } from './Icon'
import { RouteLink } from './NavLink'
import { QueryView } from './QueryView'
import { SignInAsUser } from './SignInAsUser'

export function Search() {
	const [q, setQ] = React.useState('')
	const [show, setShow] = React.useState(false)

	const [qq, setQq] = useThrottle(q, 10)

	const isDeveloper = useIsDeveloper()

	React.useEffect(() => {
		setQq(q)
	}, [setQq, q])

	const r = useSearch(qq)

	const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setQ(e.target.value)
	}, [])

	const handleClose = React.useCallback(() => {
		setShow(false)
	}, [])

	const handleShow = React.useCallback(() => {
		setShow(true)
	}, [])

	const handleFocus = React.useCallback((target: null | HTMLInputElement) => {
		setTimeout(() => {
			if (target) {
				target.focus()
			}
		})
	}, [])

	if (isDeveloper) {
		return null
	}

	return (
		<div>
			<span className="nav-link btn btn-bordered" onClick={handleShow}>
				<Icon name="search" /> Hledat…
			</span>
			<Modal show={show} onHide={handleClose} size="xl">
				<Modal.Header>
					<Form>
						<Form.Control
							size="lg"
							ref={handleFocus}
							placeholder="Hledat…"
							value={q}
							onChange={handleChange}
						/>
					</Form>
				</Modal.Header>
				<Modal.Body>
					<QueryView
						result={r}
						keepOlderData={1000}
						success={(data) => (
							<CardDeck>
								<Card>
									<ListGroup variant="flush">
										<ListGroup.Item>
											<strong>Byty</strong>
										</ListGroup.Item>
										{data?.listHousingUnit.map((item) => (
											<ListGroup.Item key={item.id}>
												<RouteLink route={ROUTES.HOUSING_UNIT(item.id)}>
													<a onClick={handleClose}>
														<div>
															<strong>{item.name}</strong>
														</div>
														{item.owner && (
															<div>
																<small>{item.owner.name}</small>
															</div>
														)}
													</a>
												</RouteLink>
											</ListGroup.Item>
										))}
									</ListGroup>
								</Card>
								<Card>
									<ListGroup variant="flush">
										<ListGroup.Item>
											<strong>Lidé</strong>
										</ListGroup.Item>
										{data?.listUser.map((item) => (
											<ListGroup.Item key={item.id}>
												<RouteLink route={ROUTES.USER(item.id)}>
													<a onClick={handleClose}>
														<div>
															<strong>{item.name}</strong>
														</div>
														<div>
															<small>{item.email}</small>
														</div>
													</a>
												</RouteLink>
												<SignInAsUser email={item.email} />
											</ListGroup.Item>
										))}
									</ListGroup>
								</Card>
								<Card>
									<ListGroup variant="flush">
										<ListGroup.Item>
											<strong>Šablony</strong>
										</ListGroup.Item>
										{data?.listHousingUnitTemplate.map((item) => (
											<ListGroup.Item key={item.id}>
												<RouteLink
													route={ROUTES.HOUSING_UNIT_TEMPLATE(item.id)}>
													<a onClick={handleClose}>
														<div>
															<strong>{item.name}</strong>
														</div>
													</a>
												</RouteLink>
											</ListGroup.Item>
										))}
									</ListGroup>
								</Card>
							</CardDeck>
						)}
					/>
				</Modal.Body>
			</Modal>
		</div>
	)
}
