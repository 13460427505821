import React from 'react'

export const FormCheck = React.forwardRef<
	HTMLInputElement,
	Omit<Partial<React.HTMLProps<HTMLInputElement>>, 'label'> & { label: React.ReactNode }
>(function FormCheck(props, ref) {
	const { label, ...input } = props
	return (
		<label className="form-check">
			<input {...input} ref={ref} type="checkbox" className="form-check-input" />
			<span className="form-check-label">{label}</span>
		</label>
	)
})
