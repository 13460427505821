import Router from 'next/router'
import React from 'react'

const DONE_DURATION = 250

let lastLoading: boolean | null = null
export function useIsRouterLoading() {
	const [loading, setLoading] = React.useState<boolean | null>(lastLoading)
	const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | number>(0)

	const onLoad = () => {
		lastLoading = true
		setLoading(true)
	}
	const onDone = () => {
		lastLoading = null
		setLoading(null)
		setTimeoutId(
			setTimeout(() => {
				setTimeoutId(0)
				lastLoading = null
				setLoading(null)
			}, DONE_DURATION)
		)
	}

	React.useEffect(() => {
		Router.events.on('routeChangeStart', onLoad)
		Router.events.on('routeChangeComplete', onDone)
		Router.events.on('routeChangeError', onDone)

		return () => {
			Router.events.off('routeChangeStart', onLoad)
			Router.events.off('routeChangeComplete', onDone)
			Router.events.off('routeChangeError', onDone)
		}
	})

	React.useEffect(
		() => () => {
			if (timeoutId) clearTimeout(timeoutId as NodeJS.Timeout)
		},
		[timeoutId]
	)

	return loading
}
